import AuthPage from 'contents/auth/AuthPage'
import MyFavoritesPage from 'contents/my/MyFavoritesPage'
import MyOrdersPage from 'contents/my/MyOrders'
import { Route, Switch } from 'react-router-dom'
import Error404Page from 'contents/Error404Page.js'

import { useSelector } from 'react-redux'
import { signState } from 'redux/SignStateSlice'

export default function MyPages() {
  const signed = useSelector(signState)

  return (
    <>
    {!signed && <AuthPage />}
    
    {signed && 
      <Switch>
        <Route exact path="/my/favourites"><MyFavoritesPage /></Route>
        <Route exact path="/my/orders"><MyOrdersPage /></Route>
        <Route><Error404Page /></Route>
      </Switch>
    }
    </>
  )
}