import Alert from '@material-ui/lab/Alert';
import { useEffect, useState } from 'react';
import { RSGet } from 'utils/RSUtils';

export default function Notice() {

  const [notice, setNotice] = useState()

  useEffect(()=>{
    RSGet('checkout/notice', (data) => {
      if(data) setNotice(data)
    })
  }, [])

  return (
    <>
    { notice && 
      <Alert severity="warning">
        <div style={{ whiteSpace: 'pre-line'}}>{notice}</div>
      </Alert>
    }
    </>
  )
}