//Components
import { RSLog, RSPost, RSText } from "utils/RSUtils"
import { Dialog, DialogContent, Divider, List, ListItem, ListItemText } from "@material-ui/core"
import { Check } from '@material-ui/icons'
import DialogTitleBar from "contents/common/DialogTitleBar"
import useStyles from "utils/RSStyles"

import { useSelector, useDispatch } from 'react-redux'
import { CheckoutDialogContent, getAddresses, hideAddresses,} from 'redux/CheckoutDialogSlice'

export default function AddressListDialog() {
  const text = RSText()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { addressesShow, addresses } = useSelector(CheckoutDialogContent)
  const open = addressesShow
  const addressList = addresses

  const handleClick = (selectedAddress) => {
    RSPost('address/active', {data:selectedAddress}, data=>{
      RSLog('切换地址')
      dispatch(getAddresses()) 
    })
  }

  const primary = (item) => {
    var str = item.forename+' '+item.surname+', '+item.address_1+', '
    if(item.address_2) str = str+item.address_2+', '
    if(item.address_3) str = str+item.address_3+', '
    str += item.city
    if(item.county) str = str+', '+item.county
    str += ', '+item.postcode
    return str
  }

  const handleClose = () => {
    dispatch(hideAddresses())
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitleBar title={text.addressList} setClose={handleClose} />
      <DialogContent className={classes.addressListContent} dividers={true}>
        
        <List>
        { addressList && addressList.map((item, index) => 
        <div key={item.id}>
          {index!==0 && <Divider />}

          {
            item.active && 
            <ListItem className={classes.addressActiveBg}>
              <ListItemText primary={primary(item)} />
              <Check />
            </ListItem>
          }

          {
            !item.active && 
            <ListItem 
              button
              onClick={()=>handleClick(item)} 
            >
              <ListItemText primary={primary(item)} />
            </ListItem>
          }

        </div>
        )}
        </List>
      </DialogContent>
    </Dialog>
  )
}