import { Dialog, Button, makeStyles } from "@material-ui/core"
import { RSText } from "utils/RSUtils"
import RSEmoji from "contents/common/RSEmoji"
import { Alert } from "@material-ui/lab"

import { useSelector, useDispatch } from 'react-redux'
import { closeAlertDialog, alertInfoContent } from 'redux/AlertDialogSlice'

const useStyle = makeStyles((theme)=>({
  root: {padding: theme.spacing(2)},
  mb: {marginBottom: theme.spacing(1)}
}))

export default function AlertDialog(props) {
  const text = RSText()
  const classes = useStyle()

  const alertInfo = useSelector(alertInfoContent)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(closeAlertDialog())
  }

  return (
    <Dialog 
      open={alertInfo.show} 
      onClose={handleClose}
      maxWidth="xs"
    >
      <div className={classes.root}>
        <RSEmoji name={alertInfo.emoji} width="100" className={classes.mb} />
        <Alert className={classes.mb} severity={alertInfo.severity}>{alertInfo.msg}</Alert>

      <Button className={classes.mb} onClick={handleClose} variant="outlined" fullWidth>
          {text.closeBtn}
      </Button>
      </div>
    </Dialog>
  )
}