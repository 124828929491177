import { RSText } from 'utils/RSUtils'
import ProductGridViewPage from 'contents/product/ProductGridViewPage'
import { useParams } from 'react-router-dom'
import { RSURIDecode } from 'utils/RSUtils'

export  function ProductShelfPage() {
  const {tag} = useParams()
  return (
    <ProductGridViewPage
      title={RSURIDecode(tag)}
      tag={tag}
      api={'product/tag/'+encodeURIComponent(RSURIDecode(tag))}
    />
  )
}


// 新品
export function ProductNewArrivalsPage() {
  const text = RSText()
  return (
    <ProductGridViewPage
      title={text.new}
      tag={text.new}
      api={'product/news'}
    />
  )
}

// 优惠打折
export function ProductOnSalePage() {
  const text = RSText()
  return (
    <ProductGridViewPage
      title={text.sales}
      tag={text.sales}
      api={'product/sales'}
    />
  )
}

// 近期热销
export function ProductPopularPage() {
  const text = RSText()
  return (
    <ProductGridViewPage
      title={text.popular}
      tag={text.popular}
      api={'product/popular'}
    />
  )
}