import { useState } from 'react'
import { makeStyles, Hidden, Menu, Button, MenuItem } from '@material-ui/core'
import GTranslateOutlinedIcon from '@material-ui/icons/GTranslateOutlined';
import { setLanguage } from 'utils/RSUtils'

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
    textTransform: 'none'
  }
}))

export default function LanguageBtn() {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lang) => {  
    setLanguage(lang)
    handleClose()
    window.location.reload()
  }

  return (
    <Hidden mdDown>
      <Button 
        className={classes.button}
        aria-controls="language-menu" 
        aria-haspopup="true" 
        onClick={handleClick}
        startIcon={<GTranslateOutlinedIcon />}
      >
        Language
      </Button>

      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem disabled><small>Language</small></MenuItem>
        <MenuItem onClick={()=>changeLanguage('en')}>English</MenuItem>
        <MenuItem onClick={()=>changeLanguage('cn')}>简体中文</MenuItem>
      </Menu>

    </Hidden>
  ) 
}
