import { Link } from "react-router-dom"
import { makeStyles, List, ListItem, ListItemText } from '@material-ui/core'
import { RSURIEncode } from 'utils/RSUtils'

import { useSelector, useDispatch } from 'react-redux'
import { closeCategoryDrawer, categoryDrawerContent } from 'redux/CategoryDrawerSlice'

const useStyles = makeStyles((theme) => ({
  categoryList: {
    padding: 0
  },
  categoryListItem: {
    marginTop:1,
    marginBottom:1,
    paddingTop: 0,
    paddingBottom: 0
  }
}))

export default function Category(props) {
  const classes = useStyles()
  const { tag } = props
  const dispatch = useDispatch()
  const categoryDrawer = useSelector(categoryDrawerContent)

  const handleClose = () => {
    dispatch(closeCategoryDrawer())
  }

  return (
    <List className={classes.categoryList}>
      {
        categoryDrawer.tags.map((m, index) => 
          <div key={index}>
            <ListItem 
              button
              className={classes.categoryListItem}
              selected={tag === RSURIEncode(m.menu)}
              component={Link}
              to={`/shelf/${RSURIEncode(m.menu)}`}
              onClick={handleClose}
            >
              <ListItemText primary={m.menu} className={classes.categoryListItem} />
            </ListItem>
            {
              m.submenu.map((sm, index2) => 
                <ListItem 
                  key={index2}
                  button
                  className={classes.categoryListItem}
                  selected={tag === RSURIEncode(sm)}
                  component={Link}
                  to={`/shelf/${RSURIEncode(sm)}`}
                  onClick={handleClose}
                >
                  <ListItemText secondary={"- "+sm} className={classes.categoryListItem} />
                </ListItem>
              )
            }
          </div>
        )
      }
    </List>
  )
}