import { createSlice } from '@reduxjs/toolkit'
// 产品类别
export const slice = createSlice({
  name: 'CategoryDrawer',
  initialState: {
    show: false,
    tags: []
  },
  reducers: {
    openCategoryDrawer: (state) => {
      state.show = true
    },
    closeCategoryDrawer: (state) => {
      state.show = false
    },
    setCategoryTags: (state, action) => {
      state.tags = action.payload.tags
    }
  },
})

export const { openCategoryDrawer, closeCategoryDrawer, setCategoryTags } = slice.actions
export const categoryDrawerContent = state => state.categoryDrawer
export default slice.reducer