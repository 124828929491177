import { useState, useEffect } from 'react'
import { Favorite, FavoriteBorder } from '@material-ui/icons'
import { IconButton, Button } from '@material-ui/core'
import { RSGet, RSText } from 'utils/RSUtils'
import { signState } from 'redux/SignStateSlice'
import { useDispatch, useSelector } from 'react-redux'
import { openAuthDialog } from 'redux/AuthDialogSlice'

export default function FavoriteBtn(props) {
  const { product } = props
  const [highlight, setHighlight] = useState(false)
  const dispatch = useDispatch()
  const signed = useSelector(signState)

  useEffect(()=> {
    setHighlight(checkFavorite(product.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleFavorite = () => {
    if(signed) {
      const api = highlight?'customer/removeFavorite':'customer/addFavorite'
      setHighlight(!highlight)
      RSGet(`${api}/${product.id}`, data => {
        setFavorites(data)
      })
    } else {
      dispatch(openAuthDialog())
    }
  }

  return (
    <IconButton color="secondary" onClick={toggleFavorite} size="small">
      {highlight?<Favorite />:<FavoriteBorder />}
    </IconButton>
  )
   
}

export function FavoriteBtnWithStr(props) {
  const { product } = props
  const [highlight, setHighlight] = useState(false)
  const text = RSText()

  const dispatch = useDispatch()
  const signed = useSelector(signState)

  useEffect(()=> {
    setHighlight(checkFavorite(product.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleFavorite = () => {
    if(signed) {
      const api = highlight?'customer/removeFavorite':'customer/addFavorite'
      setHighlight(!highlight)
      RSGet(`${api}/${product.id}`, data => {
        setFavorites(data)
      })
    } else {
      dispatch(openAuthDialog())
    }
  }


  return (
    <Button 
      color={highlight?"secondary":"default"}
      onClick={toggleFavorite} 
      size="small"
      startIcon={highlight?<Favorite />:<FavoriteBorder />}
    >
      {text.addFavorites}
    </Button>
  )
}


// 保持本地收藏夹
export function setFavorites(data) {
  localStorage.setItem('favorites', JSON.stringify(data))
}

// 检查这个产品是否在收藏夹里
function checkFavorite(productID) {
  var ticked = false
  const favorites = JSON.parse(localStorage.getItem('favorites'))
  if(favorites) {
    favorites.map((favorite) => {
      if(favorite.product_id === productID) {
        ticked = true
      } 
      return ''
    })
  }
  return ticked
}