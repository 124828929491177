import { useState, useEffect } from 'react'
import { useLocation, Link, useParams } from 'react-router-dom'
import { Container, Grid, Divider, List, ListItem, ListItemText, Hidden } from '@material-ui/core'
import PageTitle from 'contents/common/PageTitle'
import { getLanguage, GetMD, RSLink, RSText } from 'utils/RSUtils'
import 'github-markdown-css'

import PrivacyPolicy from './PrivacyPolicy'

export default function InfoPages() {
  const {path} = useParams()

  return (
    <Container maxWidth="md">
      <Grid container spacing={3}>

        <Hidden mdDown>
        <Grid item md={3}>
          <InfoMenu />
        </Grid>
        </Hidden>

        <Grid item md={9}>
          {(path === "about-us") && <About />}
          {(path === "contact-us") && <Contact />}
          {(path === "faq") && <Faq />}
          {(path === "privacy-policy") && <PrivacyPolicy />}
          {(path === "terms-and-conditions") && <Terms />}
          {(path === "join-us") && <Join />}
          {(path === "cooperation") && <Cooperation />}
        </Grid>
      </Grid>
    </Container> 
  )
}

function InfoMenu() {
  const location = useLocation()
  const [selected, setSelected] = useState(null);
  const uri = location.pathname
  const text = RSText()
  
  useEffect(()=>{
    setSelected(uri)
  }, [uri])

  const ListBtn = (props) => {
    const {text, path} = props
    return (
      <>
        <ListItem
          button
          selected={selected === path}
          component={Link} 
          to={path}
        >
          <ListItemText primary={text} />
        </ListItem>
        <Divider />
      </>
    )
  }
  
  return(
    <List>
      <ListBtn text={text.aboutUs} path={`/info/about-us`} />
      <ListBtn text={text.contactUs} path={`/info/contact-us`} />
      <ListBtn text={text.joinUs} path={`/info/join-us`} />
      <ListBtn text={text.faq} path={`/info/faq`} />
      <ListBtn text={text.privacyPolicy} path={`/info/privacy-policy`} />
      <ListBtn text={text.termsAndConditions} path={`/info/terms-and-conditions`} />
      <ListBtn text={text.cooperation} path={`/info/cooperation`} />
    </List>
  )
}

function About() {
  const lang = getLanguage()
  const text = RSText()

  return (
    <Grid container spacing={5}>
      <PageTitle title={text.aboutUs} />
      <Grid item md={12}>
        <div className='markdown-body'>
          <h2>{lang==='cn'?text.aboutUs:'ABOUT US'}</h2>
        </div>
      </Grid>

      <Grid item md={3}>
        <p align="center"><img alt="about" src="/images/about-1.jpg" width="80%" /></p>
      </Grid>
      <Grid item md={9}>
        {GetMD(`/mds/${lang}/about-1.md`)}
      </Grid>

      <Grid item md={12}><Divider /></Grid>

      <Grid item md={3}>
        <p align="center"><img alt="about" src="/images/about-2.jpg" width="80%" /></p>
      </Grid>
      <Grid item md={9}>
        {GetMD(`/mds/${lang}/about-2.md`)}
      </Grid>
      

      <Grid item md={12}><Divider /></Grid>

      <Grid item md={3}>
        <p align="center"><img alt="about" src="/images/about-3.jpg" width="80%" /></p>
      </Grid>
      <Grid item md={9}>
        {GetMD(`/mds/${lang}/about-3.md`)}
      </Grid>

      <Grid item md={12}><Divider /></Grid>

      <Grid item md={3}>
        <p align="center"><img alt="about" src="/images/about-4.jpg" width="80%" /></p>
      </Grid>
      <Grid item md={9}>
        {GetMD(`/mds/${lang}/about-4.md`)}
      </Grid>
      
    </Grid>
  )
}

function Contact() {
  const text = RSText()
  return (
    <>
    <PageTitle title={text.contactUs} />
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {GetMD(`/mds/${RSLink('contact-us.md')}`)}
      </Grid>
      <Grid item xs={12}>
        <iframe title="google map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9945.591891329208!2d0.1725651!3d51.4508492!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x93c376b37999da64!2zUmVkIFNxdWlycmVsIFNob3Ag57qi5p2-6byg6LaF5biC!5e0!3m2!1sen!2suk!4v1609862562686!5m2!1sen!2suk" width="100%" height="450" style={{border:0}} aria-hidden="false"></iframe>
      </Grid>
    </Grid>
    </>
  )
}

function Faq() {
  const text = RSText()
  return (
    <>
    <PageTitle title={text.faq} />
    { GetMD(`/mds${RSLink('faq.md')}`) }
    </>
  )
}

function Terms() {
  const text = RSText()
  return (
    <>
    <PageTitle title={text.termsAndConditions} />
    { GetMD(`/mds${RSLink('terms-and-conditions.md')}`) }
    </>
  )
}

function Join() {
  const text = RSText()
  return (
    <>
    <PageTitle title={text.joinUs} />
    { GetMD(`/mds${RSLink('join-us.md')}`) }
    </>
  )
}

function Cooperation() {
  const text = RSText()
  return (
    <>
    <PageTitle title={text.cooperation} />
    { GetMD(`/mds${RSLink('cooperation.md')}`) }
    </>
  )
}

