import { RSText } from 'utils/RSUtils'
import { 
  Divider, Typography, 
  Dialog, DialogActions,
  List, ListItem, ListItemAvatar, ListItemText
} from '@material-ui/core'
import DialogTitleBar from 'contents/common/DialogTitleBar'
import Img from 'contents/common/Img'
import useStyles from 'utils/RSStyles'

import { useSelector, useDispatch } from 'react-redux'
import { closeSoldoutDialog, SoldoutDialogContent } from 'redux/SoldoutDialogSlice'

/* Dialog 下单时提醒 已经移出购物车的已售完产品 */
export default function SoldoutDialog() {
  const classes = useStyles()
  const text = RSText()
  
  const dispatch = useDispatch()
  const soldoutDialog = useSelector(SoldoutDialogContent)


  const handleClose = () => {
    dispatch(closeSoldoutDialog())
  }

  return (
    <Dialog open={soldoutDialog.show} onClose={handleClose}>
      <DialogTitleBar title={text.soldOutAutoRemove} setClose={handleClose} />
      <List>
        {
          soldoutDialog.products.map((product, index)=> 
          <div key={index}>
          <ListItem>
            <ListItemAvatar className={classes.mr}>
              <Img src={product.img} oss="50x50"  />
            </ListItemAvatar>
            <ListItemText 
              primary={product.name} 
              secondary={<Typography color="secondary" variant="body2">{text.soldOutRemoveFromCart} {product.minus}</Typography>}
            />
          </ListItem>
          <Divider />
          </div>
          )
        }
      </List>
      <DialogActions>

      </DialogActions>
    </Dialog>
  )
}

