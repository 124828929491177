import { createSlice } from '@reduxjs/toolkit'

// 搜索栏状态
export const slice = createSlice({
  name: 'SearchDrawer',
  initialState: {
    show: false,
    keywords: '',
    products: []
  },
  reducers: {
    openSearchDrawer: (state) => {
      state.show = true
    },
    closeSearchDrawer: (state) => {
      state.show = false
    },
    setSearchKeywords: (state, action) => {
      state.keywords = action.payload.keywords
    },
    setSearchResult: (state, action) => {
      state.products = action.payload.products
    }
  },
})

export const { openSearchDrawer, closeSearchDrawer, setSearchKeywords, setSearchResult } = slice.actions
export const SearchDrawerContent = state => state.SearchDrawer
export default slice.reducer