import { useState, useEffect } from 'react'
import { Grid, Container, Hidden } from '@material-ui/core'
import { Get } from 'utils/RSUtils'
import Img from 'contents/common/Img'
import useStyles from 'utils/RSStyles'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'

export default function SmallBanners() {
  const classes = useStyles()
  const [banners , setBanners] = useState([])

  useEffect(()=>{
    Get('homepage/smallBanners', data => {
      setBanners(data)
    })  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container maxWidth="xl" className={classes.section} >
      <Hidden mdDown>
      <Grid container spacing={1}>
        {
          banners.map((banner) => 
            <Grid item xs={12} md={6} lg={3} key={banner.id}>
              <a href={banner.url}><Img src={banner.img} aspectRatio={300/140} /></a>
            </Grid>
          )
        }
      </Grid>
      </Hidden>

      <Hidden lgUp>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          pagination={{ clickable: true }}
          autoplay
        >
          {
            banners.map((banner) =>  
              <SwiperSlide key={banner.id} >
                <a href={banner.url}><Img src={banner.img} aspectRatio={300/140} /></a>
              </SwiperSlide>
            )
          }
        </Swiper>
      </Hidden>

    </Container>
  )
}

