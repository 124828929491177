export default function LangCN() {
  return {
    brand: '红松鼠超市',
    slogan: '留学生的专属超市',
    homepage: '首页',
    shopping: '产品分类',
    new: '新品推荐',
    sales: '特价专区',
    language: '简体中文',
    search: '搜索',
    emptyCart: '购物车是空的哦~',
    popular: '近期热销',
    free: '赠品',
    freeRule1: '消费满',
    freeRule2: '镑可获得此赠品',




    /*********************************************************/
    // Common Btns

    signInBtn: '登录网站',
    signUpBtn: '注册新账号',
    forgetPasswordBtn: '忘记密码',
    customerServiceBtn: '在线客服',
    backToHomeBtn: '返回首页',
    recoveryBtn: '发送确认邮件',
    closeBtn: '关闭',
    resetPasswordBtn: '设置新密码',

    /*********************************************************/
    // Common Inputs Label

    signInLabel: '登录',
    signUpLabel: '注册',
    emailLabel: '邮箱',
    passwordLabel: '密码',
    confirmPasswordLabel: '确认密码',
    signInWithLabel: '第三方账号登录',
    recoveryLabel: '找回密码',
    recoverySent: '邮件已发送，请检查您的邮箱',
    signInSuccess: '登录成功',
    
    /*********************************************************/
    // Homepage

    feature1: '全英配送',
    feature2: '运费减免',
    feature3: '支付便捷',

    featureText1: '下一个工作日送达',
    featureText2: '消费60镑免运费',
    featureText3: '微信,支付宝,银行卡',


    /*********************************************************/
    // Auth

    loginFailed: '登录失败! 请再试一次',
    loginSuccess: '登录成功!',
    passwordResetSuccess: '密码修改成功！',



    // Dialog
    close: '关闭',
    tracking: '订单追踪',
    noTracking: '暂无信息 请稍后再试',

    // Homepage Icons
    menu1: '每日膳食',
    menu2: '大厨必备',
    menu3: '生鲜果蔬',
    menu4: '零食饮料',
    menu5: '方便速食',
    menu6: '火锅系列',
    menu7: '生活用品',
    menu8: '美妆护肤',
    menu8url: '美妆',
    menu9: '新品推荐',
    menu10: '特价专区',
    menu11: '近期热销',
    menu12: '我的最爱',

    // Auth
    authTitle: '用户登录',
    signInWithFacebook: 'Facebook账号登录',
    signInWithGoogle: 'Gmail账号登录',
    signInWithWechat: '微信账号登录',


    // Account Menu
    account: '账号',
    myOrders: '我的订单',
    myFavorites: '我的收藏',
    membership: '年度会员',
    
    signIn: '登入',
    signOut: '登出',
    searchBarPlaceHolder: '查找产品',

    // Footer
    paymentMethods: '支付方式',
    downloadAPP: 'APP下载',

    // Footer FAQ
    faq: '常见问题',
    deliveryArea: '配送范围',
    deliveryFee: '运费计算',
    deliveryTime: '配送时间',
    refundPolicy: '退款方法',

    // Footer Help Center
    help: '客服中心',
    trackingOrder: '订单追踪',
    customerService: '在线客服',
    privacyPolicy: '隐私策略',
    termsAndConditions: '服务条款',

    // Footer Corporation
    corporation: '公司信息',
    aboutUs: '关于我们',
    contactUs: '联系我们',
    joinUs: '加入我们',
    cooperation: '商务合作',

    // ****************************************************
    // Product
    acUnit: '冷藏/冷冻',
    madeIn: '产地: ',
    category: '分类',
    productBrand: '品牌',
    series: '系列',
    addFavorites: '加入收藏',
    productView: '产品介绍',
    weight: '重量',
    video: '视频介绍',
    productNotFound: '未找到相关产品',

    // Order Status
    preparing: '准备中',
    delivering: '已寄出',
    cancelled: '已取消',
    delivered: '已送达',
    onPacking: '打包中',

    // Order
    frozen: '冷冻',
    chilled: "冷藏",
    shipping: '运费',
    packing: '冷藏保鲜包装费',
    vip_discount: 'VIP优惠',
    voucher_discount: '代金券/优惠券',
    gift: '赠品',

    // Warning
    error404: "抱歉！我没有找到此页面。",
    backToHome: '返回首页',

    
    // Cart drawer 
    total: '总计',
    inCart1: '购物车里有',
    inCart2: '件产品',
    checkout: '下单',


    nextStep: '下一步',

    soldOutAutoRemove: '已售完',
    soldOutRemoveFromCart: '移出购物车',

    // Checkout Step1 Address
    shippingAddress: '配送地址',
    addNewAddress: '新建地址',
    updateAddress: '编辑地址',
    deleteAddress: '删除',
    addressList: '切换地址',
    addressFormTitleAdd: '新建地址(请用英文填写)',
    addressFormTitleUpdate: '更新地址(请用英文填写)',
    addressSurname: 'Surname 姓',
    addressForename: 'Forename 名',
    addressMobile: 'Mobile 英国11位手机号 07xxxxxxxxx',
    addressFind: '搜索地址',

    // Form error
    
    delConfirm: '确定删除?',
    formRequired: '此项必填',
    formFormat: '格式有误',
    formPasswordNotMatch: '密码和确认密码不一致',

    // Buttons
    saveBtn: '保存',
    delBtn: '删除',
    backBtn: '返回',

    // ****************************************************
    // Checkout

    // Step 2
    step2: '订单确认',
    cartFee: '购物车消费',
    shippingFee: '运费',
    packingFee: '冷冻/冷藏包装费',
    vipDiscount: 'VIP折扣',
    voucherDiscount: '优惠码',
    walletSpend: '电子钱包',
    totalAmount: '总计消费',
    redeemBtn: '点击使用',
    freeGift: '赠品',
    orderNote: '订单备注',

    // Step 3
    step3: '支付方式',
    cardPay: '银行卡支付',
    wechatPay: '微信支付',
    alipay: '支付宝支付',

    // ****************************************************
    // Payment
    stripeTips: '提醒：邮编(Postcode)是银行卡绑定的地址邮编',
    confirmingPayments: '请稍等，正在确认支付状态。',
    paymentSuccessed: '支付成功',
    paymentFailed: '支付失败!',
    wechatH5PayWarning: '手机浏览器内无法使用微信支付~ 请在桌面端或微信APP内使用。',

    // ****************************************************
    // Cookie Alert
    cookieBtn: '同意',
    cookieAlert: '当您访问我们的网站时，我们将使用cookie确保您在我们的网站上获得最佳体验。',
    cookieMore: '了解更多',


    // ****************************************************
    // My
    trackingBtn: '追踪',
    viewBtn: '查看',
    noOrders: '还没有订单哦~',

    /*********************************************************/
    // Dialog 到货提醒
    reminderTitle: '到货提醒',
    reminderSave: '提醒我',
  }
}