import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router"
import { RSGet, RSLog, RSText, useInterval } from "utils/RSUtils";

import { CircularProgress, Typography } from "@material-ui/core";

import { useDispatch } from 'react-redux'
import { initCart } from 'redux/CartSlice'

import useStyles from 'utils/RSStyles'


// 支付完成后的回调页面，检查订单是否已经完成
export default function Callback() {
  const [count, setCount] = useState(0)
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const text = RSText()
  const { orderID } = useParams()
  const delay = 2000 // 每2秒检查一次
  const max = 30 // 最多检查30次，也就是一分钟

  // 检查支付状态，如果已经支付就跳到成功页面
  const check = () => {
    RSGet('payment/check/'+orderID, data => {
      if(data === 'paid') {
        if(orderID.search("SO") !== -1) {
          dispatch(initCart([]))
        }
        history.push('/payment/success')
      }
    })
    setCount(count+1)
  }

  useInterval(check, delay)

  // 检查了1分钟没有反映，证明支付异常，跳转到支付出错页面
  useEffect(()=>{
    RSLog(count)
    if(count > max) {
      history.push('/payment/fail')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  return (
    <div align='center'>
      <CircularProgress color="secondary" className={classes.mb2} />
      <Typography>{text.confirmingPayments}</Typography>
    </div>
  )
}


