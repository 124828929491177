import { createSlice } from '@reduxjs/toolkit'

// 登录状态
export const slice = createSlice({
  name: 'productDialog',
  initialState: {
    id: 0,
    show: false
  },
  reducers: {
    openProductDialog: (state, action) => {
      state.show = true
      state.id = action.payload
    },
    closeProductDialog: (state) => {
      state.show = false
      state.id = 0
    }
  },
})

export const { openProductDialog, closeProductDialog } = slice.actions
export const productDialogContent = state => state.productDialog
export default slice.reducer