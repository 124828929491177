import {makeStyles} from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
  oPrice: {
    color: '#aaa',
    marginRight: theme.spacing(1)
  }
}))

export default function Price(props) {
  const {product} = props
  const classes = useStyles()

  return (
    <>
    {product.o_price && <del className={classes.oPrice}>{'£'+product.o_price}</del>}
    £{product.price}
    </>
  )
}