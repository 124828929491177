import { createSlice } from '@reduxjs/toolkit'
import { inWechat, inAlipay } from "utils/RSBrowser"
import { Get, RSLog } from 'utils/RSUtils'

// 登录状态
export const slice = createSlice({
  name: 'authDialog',
  initialState: {
    value: {
      show: false
    },
  },
  reducers: {
    openAuthDialog: (state) => {
      if(inWechat()) {
        RSLog("微信APP端内，直接登录")
        Get('auth/wechat_oa', data => {
          if(data) {
            window.location.href = data
          }
        })
      } else if(inAlipay()) {
        RSLog("支付宝APP端内，直接登录")
        Get('auth/alipay_mobile', data => {
          if(data) {
            window.location.href = data
          }
        })
      } else {
        state.value.show = true
      }
    },
    closeAuthDialog: (state) => {
      state.value.show = false
    }
  },
})

export const { openAuthDialog, closeAuthDialog } = slice.actions
export const authDialogContent = state => state.authDialog.value
export default slice.reducer