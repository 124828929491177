import { useRef, useState } from "react"
import firebase from "firebase/app"
import { Dialog,  DialogContent, Button } from "@material-ui/core"
import { RSText } from "utils/RSUtils"
import { EmailTextField } from 'contents/common/FormInputs'
import { Alert } from "@material-ui/lab"
import DialogTitleBar from "contents/common/DialogTitleBar"
import useStyles from "utils/RSStyles"

import { useSelector, useDispatch } from 'react-redux'
import { closeEmailForgetDialog, EmailForgetDialogContent } from "redux/EmailForgetDialogSlice"


export default function EmailForgetDialog() {
  const classes = useStyles()
  const [error, setError] = useState()
  const [email, setEmail] = useState('')
  const [emailSend, setEmailSend] = useState(false)
  const text = RSText()
  const dispatch = useDispatch()
  const { show } = useSelector(EmailForgetDialogContent)

  const emailRef = useRef()

  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const handleClose = () => {
    dispatch(closeEmailForgetDialog())
  }

  const handleRecovery = () => {
    if(!emailRef.current.validation()) return false
    firebase.auth().sendPasswordResetEmail(email).then(function() {
      setEmailSend(true)
    }).catch(function(error) {
      setError(error.message)
    });
  }

  return (
    <Dialog 
      open={show} 
      onClose={handleClose}
      maxWidth="xs"
    >
      <DialogTitleBar setClose={handleClose} title={text.recoveryLabel} />

      <DialogContent dividers={true}>

        {
          !emailSend && 

          <div className={classes.authForm}>
        
          <div>
            <EmailTextField 
              value={email}
              onChange={handleChange}
              ref={emailRef}
            />
          </div>

          {error && <div><Alert severity="error">{error}</Alert></div>}

          <div><Button variant="contained" color="secondary" fullWidth onClick={handleRecovery}>{text.recoveryBtn}</Button></div>
          
          </div>
        }
        
        { emailSend && <Alert severity="success">{text.recoverySent}</Alert> }
        
      </DialogContent>
    </Dialog>
  )
}