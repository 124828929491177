
import { useRef, useState } from "react"
import { RSLog, RSText, setToken, Post } from "utils/RSUtils"
import firebase from "firebase/app"
import { Button} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { ConfirmPasswordTextField, EmailTextField, PasswordTextField } from 'contents/common/FormInputs'
import useStyles from "utils/RSStyles"

import { useDispatch } from 'react-redux'
import { signIn } from 'redux/SignStateSlice'
import { openAlertDialog } from "redux/AlertDialogSlice"
import { closeAuthDialog } from "redux/AuthDialogSlice"

export default function SignUpForm() {

  const classes = useStyles()
  const text = RSText()
  const dispatch = useDispatch()
  
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [err, setErr] = useState()

  const emailRef = useRef()
  const passwordRef = useRef()
  const confirmPasswordRef = useRef()

  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
    setErr(null)
  }

  const handleChangePassword = (e) => {
    setPassword(e.target.value)
    setErr(null)
  }

  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value)
    setErr(null)
  }

  const handleSignUp = () => {
    if(!emailRef.current.validation()) return false
    if(!passwordRef.current.validation()) return false
    if(!confirmPasswordRef.current.validation()) return false
    if(!confirmPasswordRef.current.match(password)) return false

    firebase.auth().createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        
        
        firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
          Post('auth/exchangeToken', {token: idToken}, data => {
            if(data) {
              RSLog('Email 注册成功 直接登录')
              // RSLog('交换RSToken: '+data)
              setToken(data)
              dispatch(signIn())
              dispatch(openAlertDialog({msg: text.signInSuccess, emoji: 'cart-right'}))
              dispatch(closeAuthDialog())
            }
          })
        }).catch(function(error) {
          RSLog(error.message)
        });
      })
      .catch((error) => {
        setErr(error.message)
      });
  }

  return (
    <div className={classes.authForm}>
    
      <div>
        <EmailTextField 
          value={email}
          onChange={handleChangeEmail}
          ref={emailRef}
        />
      </div>
    
      <div>
        <PasswordTextField 
          value={password} 
          onChange={handleChangePassword} 
          ref={passwordRef}
        />
      </div>

      <div>
        <ConfirmPasswordTextField
          value={confirmPassword} 
          onChange={handleChangeConfirmPassword} 
          ref={confirmPasswordRef}
        />
      </div>

      {err && <div><Alert severity="error">{err}</Alert></div>}

      <div><Button variant="contained" color="secondary" fullWidth onClick={handleSignUp}>{text.signUpBtn}</Button></div>

    </div>
  )
}