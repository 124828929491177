import { useEffect } from 'react'
import { Helmet } from "react-helmet"
import { RSText } from 'utils/RSUtils'
//import ReactGA from 'react-ga'
import { Typography } from '@material-ui/core'
import useStyles from 'utils/RSStyles'

import firebase from "firebase/app"
import "firebase/analytics"

export default function PageTitle(props) {
  const text = RSText()
  const {title} = props

  useEffect(()=>{
    //ReactGA.set({title: title})
    //ReactGA.pageview(window.location.pathname);

    firebase.analytics().logEvent('screen_view', {
      firebase_screen: 'WEB '+title, 
      firebase_screen_class: title
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title])

  return (
    <Helmet>
      <title>{title && title+" | "}{text.brand}</title>
    </Helmet> 
  )
}

export function MainTitle(props) {
  const classes = useStyles()
  const {title} = props
  return <Typography variant="h5" className={`${classes.title} ${classes.mb2}`} >{title}</Typography>
}

export function SubTitle() {

}