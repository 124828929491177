import { createSlice } from '@reduxjs/toolkit'

// 登录状态
export const slice = createSlice({
  name: 'alertDialog',
  initialState: {
    value: {
      msg: '',
      emoji: 'cart-right',
      severity: 'success',
      show: false
    },
  },
  reducers: {
    openAlertDialog: (state, action) => {
      state.value.show = true
      state.value.msg = action.payload.msg
      state.value.emoji = action.payload.emoji
      state.value.severity = action.payload.severity
    },
    closeAlertDialog: (state) => {
      state.value.show = false
      state.value.msg = ''
      state.value.emoji = 'cart-right'
      state.value.severity = 'success'
    }
  },
})

export const { openAlertDialog, closeAlertDialog } = slice.actions
export const alertInfoContent = state => state.alertDialog.value
export default slice.reducer