import { Button, Container, Grid, Typography } from '@material-ui/core'
import { RSText } from 'utils/RSUtils'
import { Link } from 'react-router-dom'
import PageTitle from 'contents/common/PageTitle'
import useStyles from 'utils/RSStyles'
import RSEmoji from 'contents/common/RSEmoji'

export default function Success() {
  const text = RSText()
  const classes = useStyles()

  return (
    <Container maxWidth="md">
      <PageTitle title={text.paymentSuccessed} />
      <Grid container className={classes.content}>
        <Grid item xs={12}>
          <RSEmoji className={classes.mb2} width="200" name="happy" />
          <Typography align="center" variant="h5" className={classes.mb2}>{text.paymentSuccessed}</Typography>
          <Typography align="center" gutterBottom>
            <Button variant="contained" color="secondary" component={Link} to="/" className={classes.mr2}>{text.backToHome}</Button> 
            <Button variant="contained" component={Link} to="/my/orders">{text.myOrders}</Button> 
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}