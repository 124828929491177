import { RSText } from 'utils/RSUtils'
import { FooterSection, FooterLink } from 'footer/Footer'

export default function FAQSection() {
  const text = RSText()
  return(
    <>
    <FooterSection text={text.faq} />
    <FooterLink uri="info/faq" text={text.deliveryArea} />
    <FooterLink uri="info/faq" text={text.deliveryTime} />
    <FooterLink uri="info/faq" text={text.deliveryFee} />
    <FooterLink uri="info/faq" text={text.refundPolicy} />
    </>
  )
}