import { useState, useRef } from 'react'
import { Get, RSLog, RSText } from 'utils/RSUtils'
import { makeStyles, SwipeableDrawer, List, ListItem, ListItemText, ListItemAvatar, Typography, Link, LinearProgress, Paper, InputBase, Divider,
  Hidden, Button, IconButton} from '@material-ui/core'
import BuyBtns from 'contents/product/components/BuyBtns'
import Img from 'contents/common/Img'
import SearchIcon from '@material-ui/icons/Search';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'

import { FrozenTag } from 'contents/product/components/Tags'

import { useSelector, useDispatch } from 'react-redux'
import { SearchDrawerContent, openSearchDrawer, closeSearchDrawer, setSearchKeywords, setSearchResult } from 'redux/SearchDrawerSlice'
import { openProductDialog } from 'redux/ProductDialogSlice'

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 300
  },
  searchBarWrapper: {
    margin: theme.spacing(1)
  },
  searchBar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  icon: {
    padding: 10,
    color: '#BBB'
  },
  productName: {
    textDecoration: 'none',
    color: 'inherit',
    fontSize: 16,
    textAlign: 'left'
  },
  productImg: {
    marginRight: theme.spacing(1)
  },
  productPromotionTag: {
    color: '#f50057',
    marginRight: theme.spacing(1)
  },
  productFrozenTag: {
    marginRight: theme.spacing(1),
    fontSize: '0.9rem'
  },
  buyBtns: {
    marginRight: theme.spacing(2)
  },
  searchResultList: {
    marginBottom: theme.spacing(2)
  },
  oPrice: {
    marginRight: theme.spacing(1)
  },
  price: {
    color: '#000'
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  mr1: {
    marginRight: theme.spacing(1)
  },
}))

// 搜索功能 Drawer
export default function SearchDrawer() {
  const classes = useStyles()
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const dispatch = useDispatch()
  const searchDrawer = useSelector(SearchDrawerContent)

  const handleOpen = () => {
    dispatch(openSearchDrawer())
  }

  const handleClose = () => {
    dispatch(closeSearchDrawer())
  }
  
  return (
    <SwipeableDrawer 
      disableEnforceFocus
      anchor="right" 
      open={searchDrawer.show} 
      onClose={handleClose} 
      onOpen={handleOpen} 
      disableBackdropTransition={!iOS} 
      disableDiscovery={iOS}
    >
      <div className={classes.drawer}>
        <SearchBar />
        <SearchResult />
      </div>  
    </SwipeableDrawer>
  )
}

export function SearchBtn() {
  const text = RSText()
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleOpen = () => {
    dispatch(openSearchDrawer())
  }

  return (
    <>
    <Hidden mdDown>
      <Button
        className={classes.mr1}
        startIcon={<SearchOutlinedIcon />}
        onClick={handleOpen}
      >
        {text.search}
      </Button>
    </Hidden>

    <Hidden lgUp>
      <IconButton 
        className={classes.mr1}
        onClick={handleOpen}
      >
        <SearchOutlinedIcon />
      </IconButton> 
    </Hidden>
    </>
  )

}

function SearchBar() {
  const classes = useStyles()
  const text = RSText()
  const dispatch = useDispatch()
  const searchDrawer = useSelector(SearchDrawerContent)

  const timerRef = useRef(null)
  const [loading, setLoading] = useState(false)

  // 输入
  const handleChange = (e) => {
    const keywords = e.target.value
    dispatch(setSearchKeywords({keywords: keywords}))
    setLoading(true)
    if(timerRef.current) {
      clearTimeout(timerRef.current)
    }
    
    timerRef.current = setTimeout(function(){  
      if(keywords && keywords.trim() !== '' && keywords.length > 0) {
        handleSearch(keywords)
      } else {
        RSLog('关键字为空')
      }
      setLoading(false)
    }, 2000); // 2秒后 反应实际 避免多次搜索
  }

  // 搜索
  const handleSearch = (keywords) => {
    RSLog('开始搜索关键字')
    Get('product/search/'+encodeURIComponent(keywords.trim()), data => {
      if(data) {
        dispatch(setSearchResult({products:data}))
      } else {
        dispatch(setSearchResult([]))
      }
    })
  }

  const handlePress = (e) => {
    if(e.key === 'Enter') {
      e.preventDefault()
    }
  }

  
  return (
    <div className={classes.searchBarWrapper}>
    <Paper component="form" className={classes.searchBar}>
      <InputBase
        className={classes.input}
        placeholder={text.searchBarPlaceHolder}
        value={searchDrawer.keywords}
        onChange={handleChange}
        onKeyPress={handlePress}
      />
      <SearchIcon className={classes.icon} />
    </Paper>
    { loading && <LinearProgress color="secondary" /> }
    </div>
  )
}

function SearchResult() {
  const classes = useStyles()
  const text = RSText()

  const dispatch = useDispatch()
  const searchDrawer = useSelector(SearchDrawerContent)

  const openDialog = (productID) => {
    dispatch(openProductDialog(productID))
  }

  return (
    <List className={classes.searchResultList}>
    {
      searchDrawer.products && searchDrawer.products.map((product) => 
      <div key={product.id} >
        <ListItem>
          <ListItemAvatar className={classes.productImg}>
            <Img src={product.img} oss="50x50" onClick={()=>openDialog(product.id)} />
          </ListItemAvatar>
          <ListItemText 
            primary={
              <>
              <Link component="button" className={classes.productName} onClick={()=>openDialog(product.id)}>
                <FrozenTag product={product} className={classes.productFrozenTag} />
                {product.name} 
              </Link>
              </>
            } 
            secondary={
              <>
                {product.promotion_tag && <span className={classes.productPromotionTag}>{product.promotion_tag}</span>}
                {product.o_price && <del className={classes.oPrice}>£{product.o_price}</del>}
                <span className={classes.price}>£{product.price}</span>
              </>
            } 
          />
        </ListItem>
        <Typography component={'div'} align='right' className={classes.buyBtns}><BuyBtns product={product} /></Typography> 
        <Divider className={classes.divider} />
      </div> 
      )
    }

    {
      searchDrawer.products.length === 0 && searchDrawer.keywords !== '' &&
      <Typography component={'div'} variant={'body2'} align="center">{text.productNotFound}</Typography>
    }
    </List>
  )
}