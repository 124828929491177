import { RSText } from "utils/RSUtils"
import { makeStyles, ListItem, Divider } from '@material-ui/core'
import InputBase from '@material-ui/core/InputBase';

import { useSelector, useDispatch } from 'react-redux'
import { 
  CheckoutDialogContent,
  setOrder
} from 'redux/CheckoutDialogSlice'

const useStyles = makeStyles((theme) => ({
  input: {
    flex: 1,
  }
}))

export default function VoucherListItem(props) {
  const classes = useStyles()
  const text = RSText()
  const dispatch = useDispatch()
  const { order } = useSelector(CheckoutDialogContent)

  const handleChange = (e) => {
    dispatch(
      setOrder({
        ...order,
        note: e.target.value
      })
    )
  }

  return (
    <>
      <ListItem>
        <InputBase
          className={classes.input}
          placeholder={text.orderNote}
          value={order.note?order.note:''}
          onChange={handleChange}
          inputProps={{maxLength: 255}}
        />
      </ListItem>
      <Divider />
    </>
  )
}