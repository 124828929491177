import { Dialog, DialogContent, CircularProgress } from "@material-ui/core"

import { useSelector, useDispatch } from "react-redux"
import { closeLoadingDialog, LoadingDialogShow } from "redux/LoadingDialogSlice"

export default function LoadingDialog() {
  const dispatch = useDispatch()
  const show = useSelector(LoadingDialogShow)

  const handleClose = () => {
    dispatch(closeLoadingDialog())
  }

  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogContent dividers={true}>
        <CircularProgress color="secondary" />
      </DialogContent>
    </Dialog>
  )
}