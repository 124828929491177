import { createSlice } from '@reduxjs/toolkit'

// 登录状态
export const slice = createSlice({
  name: 'cartDrawer',
  initialState: {
    value: {
      show: false
    },
  },
  reducers: {
    openCartDrawer: (state) => {
      state.value.show = true
    },
    closeCartDrawer: (state) => {
      state.value.show = false
    }
  },
})

export const { openCartDrawer, closeCartDrawer } = slice.actions
export const cartDrawerContent = state => state.cartDrawer.value
export default slice.reducer