// livechat.com
// https://github.com/livechat/chat-widget-adapters
// 客服组件

import Store from 'redux/Store'
import { useSelector } from 'react-redux'

import { LiveChatWidget } from '@livechat/widget-react'
import { hideChat, LivechatStates, openChat, showChat } from 'redux/LiveChatSlice'


export default function LiveChat() {
  const { license, visibility } = useSelector(LivechatStates)
  const onVisibilityChanged = (data) => {
    console.log(data.visibility)
    switch (data.visibility) {
      case "maximized":
        break;
      case "minimized":
        hideLiveChat()
        break;
      case "hidden":
        break;
    }
  }
  return <LiveChatWidget
            license={license}
            visibility={visibility}
            onVisibilityChanged={onVisibilityChanged}
         />
}

export function showLiveChat() {
  Store.dispatch(showChat())
}

export function openLiveChat() {
  Store.dispatch(openChat())
}

export function hideLiveChat() {
  Store.dispatch(hideChat())
}