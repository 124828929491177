import { useState, useEffect } from "react"
import { RSGet, RSText } from "utils/RSUtils"
import { loadStripe } from "@stripe/stripe-js"
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Dialog, Button, DialogContent} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCcVisa, faCcMastercard, faCcAmex } from '@fortawesome/free-brands-svg-icons'
import DialogTitleBar from 'contents/common/DialogTitleBar'
import Alert from '@material-ui/lab/Alert'
import { useHistory } from "react-router"
import useStyles from "utils/RSStyles"

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { openStripeDialog, closeStripeDialog, closePaymentDialog, PaymentDialogContent } from "redux/PaymentDialogSlice";

// Stripe payment documents
// https://stripe.com/docs/checkout/integration-builder
// const mode = 'test'
const mode = 'live'
var key = ''
if(mode === 'test') {
  key = "pk_test_9ee7oN5XfsaiqjuIfkKdwFYT"
} else {
  key = "pk_live_4Aczi8UXkt564FZ90EYntjtW"
}
const stripePromise = loadStripe(key);

export default function StripeBtn() {
  const dispatch = useDispatch()

  const text = RSText()
  const handleClick = () => {
    dispatch(openStripeDialog())
  }

  return (
    <>
    <Button onClick={handleClick}>
      <CardIcons size="2x" />
      {text.cardPay}
    </Button>
    <StripeDialog />
    </>
  )
}

const CardIcons = (props) => {
  const {size} = props
  return (
    <>
      <FontAwesomeIcon icon={faCcVisa} size={size} fixedWidth style={{color: '#255596', marginRight: 5}}/>
      <FontAwesomeIcon icon={faCcMastercard} size={size} fixedWidth style={{color: '#eb8517', marginRight: 5}}/>
      <FontAwesomeIcon icon={faCcAmex} size={size} fixedWidth style={{color: '#31a0cf', marginRight: 5}}/>
    </>
  )
}


const StripeDialog = () => {
  const text = RSText()
  const dispatch = useDispatch()
  const { stripe } = useSelector(PaymentDialogContent)

  const handleClose = () => {
    dispatch(closeStripeDialog())
  }

  return (
    <Dialog open={stripe} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitleBar title={<>{text.cardPay} <CardIcons /></>} setClose={handleClose} />
      <DialogContent dividers={true}>
        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      </DialogContent>
    </Dialog>
  )
}

const CheckoutForm = () => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { orderID } = useSelector(PaymentDialogContent)

  const api = 'payment/stripePay/'+orderID+'/'+mode

  useEffect(() => {
    RSGet(api, data => {
      if(data) setClientSecret(data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`)
      setProcessing(false)
    } else {
      setError(null)
      setProcessing(false)
      setSucceeded(true)
      dispatch(closeStripeDialog())
      dispatch(closePaymentDialog())
      history.push('/payment/callback/'+orderID)
    }
  };

  return (
    <>
    <div id="stripe-form">
      { clientSecret && 
      <form id="payment-form" onSubmit={handleSubmit}>
        <CardElement id="card-element"  onChange={handleChange} />
        
        <button
          disabled={processing || disabled || succeeded}
          id="submit"
        >
          <span id="button-text">
            {processing ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              "Pay now"
            )}
          </span>
        </button>
        
      </form>
      }
    </div>

    {/* Show any error that happens when processing the payment */}
    {error && <Alert severity="error" className={classes.mt}>{error}</Alert>}
    </>
  );
}

