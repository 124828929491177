import { useState, useEffect } from "react"
import { RSGet, RSLog, RSText } from "utils/RSUtils"
import { loadStripe } from "@stripe/stripe-js"
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Dialog, ListItem, ListItemIcon, ListItemText, Divider, DialogContent} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCcVisa, faCcMastercard, faCcAmex } from '@fortawesome/free-brands-svg-icons'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import DialogTitleBar from 'contents/common/DialogTitleBar'
import Alert from '@material-ui/lab/Alert'
import { useHistory } from "react-router"
import useStyles from "utils/RSStyles"

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { CheckoutDialogContent, closeCheckoutDialog } from "redux/CheckoutDialogSlice";
import { StripeDialogContent, openStripeDialog, closeStripeDialog } from "redux/StripeDialogSlice";

// Stripe payment documents
// https://stripe.com/docs/checkout/integration-builder

// 自动根据域名切换环境
const stripePromise = loadStripe("pk_live_4Aczi8UXkt564FZ90EYntjtW");

/*
function StripeKey() {
  if(window.location.hostname === "redsquirrel.shop") {
    return "pk_live_4Aczi8UXkt564FZ90EYntjtW"
  } else {
    RSLog('Stripe test enviroment')
    return "pk_test_9ee7oN5XfsaiqjuIfkKdwFYT"
  }
}*/

export default function StripePay() {
  const text = RSText()
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(openStripeDialog())
  }

  return (
    <>
    <StripeDialog />
    <ListItem button onClick={handleClick}>
      <ListItemIcon>
        <FontAwesomeIcon icon={faCcVisa} size="2x" fixedWidth style={{color: '#255596'}}/>
      </ListItemIcon>
      <ListItemIcon>
        <FontAwesomeIcon icon={faCcMastercard} size="2x" fixedWidth style={{color: '#eb8517'}}/>
      </ListItemIcon>
      <ListItemIcon>
        <FontAwesomeIcon icon={faCcAmex} size="2x" fixedWidth style={{color: '#31a0cf'}}/>
      </ListItemIcon>
      <ListItemText primary={text.cardPay} />
      <KeyboardArrowRightIcon />
    </ListItem>
    <Divider />
    </>
  )
}

const StripeDialog = () => {
  const text = RSText()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { show } = useSelector(StripeDialogContent)

  const handleClose = () => {
    dispatch(closeStripeDialog())
  }

  return (
    <Dialog open={show} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitleBar title={text.cardPay} setClose={handleClose} />
      <DialogContent dividers={true}>
        <Alert severity="info" className={classes.mb}>{text.stripeTips}</Alert>
        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      </DialogContent>
    </Dialog>
  )
}

const CheckoutForm = () => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { order } = useSelector(CheckoutDialogContent)

  const api = 'payment/stripePay/'+order.order_id

  useEffect(() => {
    RSLog(order)
    RSGet(api, data => {
      if(data) setClientSecret(data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`)
      setProcessing(false)
    } else {
      setError(null)
      setProcessing(false)
      setSucceeded(true)
      dispatch(closeStripeDialog())
      dispatch(closeCheckoutDialog())
      history.push('/payment/callback/'+order.order_id)
    }
  };

  return (
    <>
    <div id="stripe-form">
      { clientSecret && 
      <form id="payment-form" onSubmit={handleSubmit}>
        <CardElement id="card-element"  onChange={handleChange} />
        
        <button
          disabled={processing || disabled || succeeded}
          id="submit"
        >
          <span id="button-text">
            {processing ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              "Pay now"
            )}
          </span>
        </button>
        
      </form>
      }
    </div>

    {/* Show any error that happens when processing the payment */}
    {error && <Alert severity="error" className={classes.mt}>{error}</Alert>}
    </>
  );
}

