// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCVQ7n8vIUSGhjKPLSGsqduQF2pOVlAqkc",
  authDomain: "red-squirrel-shop.firebaseapp.com",
  databaseURL: "https://red-squirrel-shop.firebaseio.com",
  projectId: "red-squirrel-shop",
  storageBucket: "red-squirrel-shop.appspot.com",
  messagingSenderId: "889687314123",
  appId: "1:889687314123:web:b740030f5e7fdc4ba4554e",
  measurementId: "G-135LE6ZTZW"
}