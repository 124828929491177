import { useHistory } from "react-router-dom"
import AcUnitOutlinedIcon from '@material-ui/icons/AcUnitOutlined'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import {  RSText, RSURIEncode } from 'utils/RSUtils'
import {Tooltip, Typography, Link, Chip} from '@material-ui/core'

import {makeStyles} from '@material-ui/core'

import { useDispatch } from 'react-redux'
import { closeProductDialog } from 'redux/ProductDialogSlice'

const useStyles = makeStyles((theme) => ({
  mr2: {
    marginRight: theme.spacing(2),
  },
  tags: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  category: {
    '& a': {
      color: '#f50057',
    },
  }
}))

export function PromotionTag(props) {
  const {product, className} = props
  return (
    <>
    {
      product.promotion_tag && 
      <Chip
        className={className}
        size="small"
        label={product.promotion_tag}
        color="secondary"
      />
    }
    </>
  )
}

export function FrozenTag(props) {
  const {product} = props
  const text = RSText()
  
  return (
    <>
    {
      product.frozen && product.frozen === '1' && 
      <Chip color="primary" label={text.frozen} size="small" />
    }
    </>
  )
}


export function ChilledTag(props) {
  const { product } = props
  const text = RSText()
  return (
    <>
    {
      product.chilled && product.chilled === '1' && 
      <Chip color="primary" label={text.chilled} size="small" />
    }
    </>
  )
}

export function YouTubeTag(props) {
  const {product} = props
  const text = RSText()
  return (
    <>
    {
      product.video && 
      <Tooltip title={text.video} placement="top" arrow>
      <PlayCircleOutlineIcon
        fontSize="small"
        color="secondary"
      />
      </Tooltip>
    }
    </>
  )
}

export function CategoryTags(props) {
  const {product} = props
  const classes = useStyles()
  const history = useHistory()
  const text = RSText()
  const dispatch = useDispatch()

  const handleClick = (event, tag) => {
    event.preventDefault()
    history.push(`/shelf/${RSURIEncode(tag.name)}`)
    dispatch(closeProductDialog())
  }

  return (
    <div className={classes.category}>
    { // 类别
      product.tags && product.tags.map((tag, index)=> {
        if(tag.name && tag.type === "3") {
          return (
            <Typography variant="body2" key={index} component="span" className={classes.mr2} >
              <span className={classes.mr1}>
                {text.category}: <Link href='#' onClick={(even)=>handleClick(even, tag)}>{tag.name}</Link>
              </span>
            </Typography>
          )
        } else return ''
      })
    }

    { // 品牌
      product.tags && product.tags.map((tag, index)=> {
        if(tag.name && tag.type === "0") {
          return (
            <Typography variant="body2" key={index} component="span" >
              <span className={classes.mr1}>
                {text.productBrand}: <Link href='#' onClick={(even)=>handleClick(even, tag)}>{tag.name}</Link>
              </span>
            </Typography>
          )
        } else return ''
      })
    }

    <br/>

    { // 系列
      product.tags && product.tags.map((tag, index)=> {
        if(tag.name && tag.type === "1") {
          return (
            <Typography variant="body2" key={index} component="span"  className={classes.mr2} >
              <span className={classes.mr1}>
                {text.series}: <Link href='#' onClick={(even)=>handleClick(even, tag)}>{tag.name}</Link>
              </span>
            </Typography>
          )
        } else return ''
      })
    }

    { // 产地
      product.tags && product.tags.map((tag, index)=> {
        if(tag.name && tag.type === "9") {
          return (
            <Typography variant="body2" key={index} component="span" >
              <span className={classes.mr1}>
                {text.madeIn}<Link href='#' onClick={(even)=>handleClick(even, tag)}>{tag.name}</Link>
              </span>
            </Typography>
          )
        } else return ''
      })
    }

    </div>
  )
}
