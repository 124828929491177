import { useEffect } from "react"
import { RSGet, RSLog, RSText } from "utils/RSUtils"
import { Typography, Divider, Button, Card, CardHeader, CardActions, List, ListItem, ListItemText } from '@material-ui/core'
import { NextStepBtn } from 'dialogs/CheckoutDialog'
import ReactGA from 'react-ga'

import { useSelector, useDispatch } from 'react-redux'
import { 
  CheckoutDialogContent, 
  showAddressForm, 
  showAddresses, 
  setOrder,
  setAddressFormData,
  getAddresses, 
} from 'redux/CheckoutDialogSlice'


// Step 1 确认地址
export default function StepOne() {

  const text = RSText()
  const dispatch = useDispatch()
  const checkoutDialog = useSelector(CheckoutDialogContent)
  const { address, addresses } = checkoutDialog

  useEffect(()=>{
    ReactGA.set({title: text.shippingAddress})
    ReactGA.modalview('/checkout/step-1')
    RSLog('确认配送地址')
    dispatch(getAddresses()) 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const handleAdd = () => {
    RSLog('新建地址')
    dispatch(setAddressFormData(null))
    dispatch(showAddressForm())
  }

  // 编辑地址
  const handleUpdate = () => {
    dispatch(setAddressFormData(address))
    dispatch(showAddressForm())
  }

  // 下一步
  const handleNext = () => {
    // 新建订单
    RSLog('第三步: 生成新的订单')
    RSGet('checkout/newOrder', data => {
      if(data) {
        RSLog(data)
        dispatch(setOrder(data))
      }
    })
  }

  const showAddressListDialog = () => {
    dispatch(showAddresses())
  }

  return (
    <Card>
      <CardHeader 
        title={text.shippingAddress} 
        action={addresses.length > 1 && <ChangeBtn onClick={showAddressListDialog} />}
      />

      { address && 
      <List>
        <AddressListItem primary={address.forename + ' ' + address.surname} secondary="Name" />
        <AddressListItem primary={address.tel} secondary="Mobile" />
        <AddressListItem primary={address.email} secondary="Email" />
        <AddressListItem primary={address.address_1} secondary="Addresss L1" />
        <AddressListItem primary={address.address_2} secondary="Addresss L2" />
        <AddressListItem primary={address.address_3} secondary="Addresss L3" />
        <AddressListItem primary={address.city} secondary="City/Town" />
        <AddressListItem primary={address.county} secondary="County" />
        <AddressListItem primary={address.postcode} secondary="Postcode" />
        <AddressListItem primary={address.country} secondary="Country" />
        <AddressListItem primary={address.instructions} secondary="Instructions" />
      </List>
      }

      <CardActions>
        <AddNewBtn onClick={handleAdd} />

        {address && 
        <>
          <UpdateBtn onClick={handleUpdate} />
          <NextStepBtn onClick={handleNext} />
        </>
        }
      </CardActions>
    </Card>
  )
}

function AddNewBtn(props) {
  const {onClick} = props
  const text = RSText()
  return (
    <Button color="default" variant="outlined" size="small" onClick={onClick}>
      {text.addNewAddress}
    </Button>
  )
}

function UpdateBtn(props) {
  const {onClick} = props
  const text = RSText()
  return (
    <Button color="default" variant="outlined" size="small" onClick={onClick}>
      {text.updateAddress}
    </Button>
  )
}

function ChangeBtn(props) {
  const {onClick} = props
  const text = RSText()
  return (
    <Button variant="outlined" size="small" onClick={onClick}>
      {text.addressList}
    </Button>
  )
}

function AddressListItem(props) {
  const { primary, secondary } = props
  return (
    <>
    { primary && 
    <>
      <ListItem>
        <ListItemText primary={primary} />
        <Typography variant="caption">{secondary}</Typography>
      </ListItem>
      <Divider />
    </>
    }
    </>
  )
}