import { forwardRef, useEffect } from 'react'
import { RSGet, RSLog, RSText } from 'utils/RSUtils'
import StepOne from 'dialogs/checkout/step1/StepOne'
import StepTwo from 'dialogs/checkout/step2/StepTwo'
import StepThree from 'dialogs/checkout/step3/StepThree'
import { Container, DialogContent, Stepper, Step, StepLabel, Dialog, Slide, Button } from '@material-ui/core'
import DialogTitleBar from 'contents/common/DialogTitleBar'
import useStyles from 'utils/RSStyles'

import { useSelector, useDispatch } from 'react-redux'
import { closeCheckoutDialog, CheckoutDialogContent, nextStep, prevStep } from 'redux/CheckoutDialogSlice'
import { openSoldoutDialog } from 'redux/SoldoutDialogSlice'
import { initCart } from 'redux/CartSlice'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CheckoutDialog() {
  const text = RSText()
  const classes = useStyles();
  const { show, step } = useSelector(CheckoutDialogContent)
  const dispatch = useDispatch()

  useEffect(()=>{
    if(show) {
      RSLog('检查超卖产品')
      RSGet('checkout/checkSoldOut', data => {
        RSLog(data)
        if(data) {
          if(data.soldout) {
            RSLog('有产品移出购物车')
            dispatch(openSoldoutDialog(data.soldout))
          }
          if(data.cart) {
            RSLog('更新本地购物车')
            dispatch(initCart(Object.values(data.cart)))
          }  
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])


  const handleClose = () => {
    dispatch(closeCheckoutDialog())
  }

  return (
    <Dialog fullScreen open={show} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitleBar title={text.checkout} setClose={handleClose} />

      <DialogContent dividers={true}>
        <Container maxWidth='sm'>
          <Steps step={step} />
          <div className={`${classes.mb4} ${classes.shadow}`}>
            {step === 0 && <StepOne />}
            {step === 1 && <StepTwo />}
            {step === 2 && <StepThree />}
          </div>
        </Container>
      </DialogContent>
    </Dialog>
  );
}

function Steps(props) {
  const {step} = props
  return (
    <Stepper activeStep={step}>
      <Step>
        <StepLabel></StepLabel>
      </Step>
      <Step>
        <StepLabel></StepLabel>
      </Step>
      <Step>
        <StepLabel></StepLabel>
      </Step>
    </Stepper>
  )
}

// 上一步 按钮
export function BackStepBtn() {
  const text = RSText()
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(prevStep())
  }

  return (
    <Button variant="outlined" size="small" onClick={handleClick}>
      {text.backBtn}
    </Button>
  )
}

// 下一步 按钮
export function NextStepBtn(props) {
  const { onClick } = props
  const text = RSText()
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(nextStep())
    if(onClick) onClick()
  }
  
  return (
    <Button color="secondary" className={classes.checkOutNextStepBtn} size="small" variant="contained" onClick={handleClick}>
      {text.nextStep}
    </Button>
  )
}