import { useEffect, useState } from "react"

// components
import { Grid, Typography, Divider } from "@material-ui/core"
import InfiniteScroll from 'react-infinite-scroll-component'

import GridItem from 'contents/product/components/GridItem'

// Utils
import { RSGet, RSText, RSURIDecode } from 'utils/RSUtils'
import RSEmoji from "contents/common/RSEmoji"
import useStyles from "utils/RSStyles"

export default function GridList(props) {
  const {tag, api} = props
  const [products, setProducts] = useState([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const [loading, setLoading] = useState(false)
  const pagesize = 12
  const text = RSText()
  const classes = useStyles()

  useEffect(()=>{
    setPage(1)
    setHasMore(true)
    setLoading(true)
    setProducts([])
    RSGet(api+'/'+1+'/'+pagesize, data => {
      if(data.length === 0 || data.length < pagesize) {
        setHasMore(false)
      }
      setProducts(data)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag])

  const fetchNextPage = () => {
    if(!loading) {
      setLoading(true)
      RSGet(api+'/'+(page+1)+'/'+pagesize, data => {
        if(data.length === 0 || data.length < pagesize) {
          setHasMore(false)
        }
        setProducts(products.concat(data))
        setLoading(false)
      })
      setPage(page+1)
    }
  }

  return(
    <>
   
    <InfiniteScroll
      dataLength={products.length}
      next={fetchNextPage}
      hasMore={hasMore}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5">{RSURIDecode(tag)}</Typography>
          <Divider />
        </Grid>
      {
        products && products.map((product) => 
          <Grid item xl={2} lg={2} md={3} sm={3} xs={6} key={product.id} >
            <GridItem product={product} />
          </Grid>
        )
      }
      {
        !products && 
        <Grid item xs={12}>
          <RSEmoji name="sorry" className={classes.mt} />
          <Typography align="center" variant="h5" className={classes.mt}>{text.error404}</Typography>
        </Grid>
        
      }
      </Grid>
    </InfiniteScroll>
    </>

  )
}