import { 
  Dialog, DialogContent, 
  List, ListItem, ListItemText, Divider } from "@material-ui/core"
import DialogTitleBar from "contents/common/DialogTitleBar"
import { RSText } from 'utils/RSUtils'

import { useSelector, useDispatch } from "react-redux"
import { closeTrackingDialog, TrackingDialogContent } from "redux/TrackingDialogSlice"

import moment from "moment"
require('moment/locale/en-gb')

export default function TrackingDialog() {
  const text = RSText()
  const dispatch = useDispatch()
  const { show, trackingNumber, trackings } = useSelector(TrackingDialogContent)

  const handleClose = () => {
    dispatch(closeTrackingDialog())
  }

  return (
    <Dialog open={show} onClose={handleClose}>
      { trackingNumber && 
      <>
      <DialogTitleBar title={text.tracking} setClose={handleClose} />
      <DialogContent dividers={true}>
        <List>

          { trackings && trackings.trackingevent.length && 
            trackings.trackingevent.map((record, index)=>(
            <div key={index}>
            <ListItem>
              <ListItemText primary={record.description} secondary={moment(record.date).format('L')+' '+moment(record.date).format('LT')} />
            </ListItem>
            <Divider />
            </div>
          ))}

          { trackings && !trackings.trackingevent.length && 
            <div>
            <ListItem>
              <ListItemText primary={trackings.trackingevent.description} secondary={moment(trackings.trackingevent.date).format('L')+' '+moment(trackings.trackingevent.date).format('LT')} />
            </ListItem>
            <Divider />
            </div>
          }

          { trackings === null && <ListItem><ListItemText primary={'Loading'} /></ListItem>}
        </List>
      </DialogContent>
      </>
      }
    </Dialog>
  )
}