import { List, SwipeableDrawer } from '@material-ui/core'
import ProductCategory from 'contents/product/components/Category'
import useStyles from 'utils/RSStyles'

import { useSelector, useDispatch } from 'react-redux'
import { closeCategoryDrawer, categoryDrawerContent, openCategoryDrawer } from 'redux/CategoryDrawerSlice'

export default function ProductCategoryDrawer(props) {

  const classes = useStyles()
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

  const dispatch = useDispatch()
  const categoryDrawer = useSelector(categoryDrawerContent)

  const handleOpen = () => {
    dispatch(openCategoryDrawer())
  }

  const handleClose = () => {
    dispatch(closeCategoryDrawer())
  }

  return (
    <SwipeableDrawer 
      anchor="left" 
      open={categoryDrawer.show} 
      onClose={handleClose} 
      onOpen={handleOpen} 
      disableBackdropTransition={!iOS} 
      disableDiscovery={iOS}
    >
      <List className={`${classes.drawer} ${classes.mb2}`}>
        <ProductCategory />
      </List>
    </SwipeableDrawer>
  )
}
