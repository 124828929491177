import { RSLog, useQuery } from "utils/RSUtils"

export default function Test() {
  const {en} = useQuery()

  RSLog(en)

  return (
    '1'
  )
}
