import CookieConsent from "react-cookie-consent"
import {Link} from 'react-router-dom'
import { RSText } from "utils/RSUtils"

export default function CookieAlert() {
  const text = RSText()
  return (
  <CookieConsent buttonText={text.cookieBtn}>
    {text.cookieAlert}
    <Link to="/info/privacy-policy" style={{color:'yellow'}}>{text.cookieMore}</Link>
  </CookieConsent>
  )
}
