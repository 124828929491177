import { useState, useEffect, useRef } from 'react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import axios from 'axios'
import Qs from 'qs'

import LangCN from 'utils/LangCN'
import LangEN from 'utils/LangEN'
import firebase from "firebase/app"
import store from 'redux/Store'
import { signOut } from 'redux/SignStateSlice'

const api = "https://api.redsquirrel.shop/web2/"
axios.defaults.withCredentials = true


export function Get(uri, callback) {
  const url = api+uri
  const config = { 
    params: {
      lang: getLanguage()
    },
  }

  axios.get(url, config)
    .then(res => {
      //RSLog(url)
      //RSLog(res.data)
      if(res.data.data) {
        callback(res.data.data)
      } else {
        callback(false)
      }
    })
    .catch(function (error) {
      //RSLog(error)
      RSLogError(error)
      callback(false)
    })
}

export function Post(uri, postData, callback) {
  const url = api+uri
  const data = { 
    lang: getLanguage(),
    ...postData
  }
  RSLog('Post: '+ url)
  // RSLog(data)
  const headers = {'Content-Type': 'application/json'}

  axios.post(url, Qs.stringify(data), headers)
  .then(res => {
    RSLog(res.data.data)

    if(res.data.error) {
      const error = res.data.error

      // 未登录 刷新页面退出登录状态
      if(error === 401) {
        RSLog(res.data.message)
        signout()
      }
      callback(false)
    }

    if(res.data.token) {
      setToken(res.data.token) // 刷新缓存的 access token
    }

    if(res.data.data) {
      callback(res.data.data)
    } 
  })
  .catch(function (error) {
    //RSLog(error)
    RSLogError(error)
  })
}

// Request Get
export function RSGet(uri, callback) {
  RSPost(uri, {}, callback)
}

// Request Post
export function RSPost(uri, postData, callback) {
  const data = {
    token: getToken(),
    ...postData
  }
  Post(uri, data, callback)
}

function RSLogError(error) {
  RSLog('出错')
  if(error.response) {
    RSLog(error.response.data)
  } else if (error.request) {
    RSLog(error.request);
  } else {
    RSLog(error.message);
  }
  //RSLog(error.config);
}

// 覆盖console.log 生产模式下不log
export function RSLog(msg) {
  if (window.location.hostname === 'redsquirrel.shop') return;
  console.log(msg);
}

// 空格 变 - 变小写 用于URL显示
export function RSURIEncode(uri) {
  if(uri) {
    uri = uri.replace(/ /g, "-")
    uri = uri.toLowerCase()
    return uri
  }
}

// 返回原有样式
export function RSURIDecode(uri) {
  uri = uri.replace(/-/g, " ")
  uri = uri.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
  return uri
}


export function GetMD(path) {
  const [source, setSource] = useState()

  useEffect(()=>{
    fetch(path)
    .then(res=> res.text())
    .then(text => setSource(text))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='markdown-body'>
      <ReactMarkdown plugins={[gfm]} children={source} />
    </div>
  )
}

// 版本控制
export function checkVersion(version) {
  var localVersion = localStorage.getItem('version')
  if(!localVersion) {
    // 第一次浏览 本地没有version信息 保存version
    localStorage.setItem('version', version)
  } else if (localVersion !== version) {
    // 刷新本地版本
    localStorage.setItem('version', version)
    window.location.reload()
  }
}

/* 网站语言 全局设置 */
// 默认为中文
export function getLanguage() {
  var language = localStorage.getItem('language')
  if(!language) {
    // 第一次浏览首页 未记录语言类型
    language = 'cn'
    setLanguage(language)
  } 
  return language
}

export function setLanguage(language) {
  if(language !== 'cn' && language !== 'en') language = 'cn'
  RSLog('网站语言切换至：'+language)
  localStorage.setItem('language', language)
}

export function RSText() {
  const lang = getLanguage();
  if(lang === 'cn') return LangCN()
  else return LangEN()
}

// 站内调整 加上语音前置segment
export function RSLink(uri) {
  const lang = getLanguage();
  return `/${lang}/${uri}`
}

export function useQuery() {
  const urlSearchParams = new URLSearchParams(window.location.search)
  return Object.fromEntries(urlSearchParams.entries())
}

export function useInterval(callback, delay) {   
  const savedCallback = useRef(); 
  
  useEffect(() => {     
    savedCallback.current = callback;   
  });   
  
  useEffect(() => {     
    function tick() {       
      savedCallback.current();     
    }     
    
    let id = setInterval(tick, delay);     
    return () => clearInterval(id);   
  }, [delay]); 
}


// Access Token Set and Get
export function getToken() {
  return window.localStorage.getItem('rsToken')
}

export function setToken($token) {
  window.localStorage.setItem('rsToken', $token)
}

export function removeToken() {
  window.localStorage.removeItem('rsToken')
}


// 登出网站
export function signout() {
  store.dispatch(signOut()) // Redux 登录状态修改为 false
  removeToken() // 缓存 access token 清空
  Get('auth/signout', data=>{}) // refresh token cookie 清空
  firebase.auth().signOut() // firebase 登出
  window.location.href = "https://redsquirrel.shop"
}