import { createSlice } from '@reduxjs/toolkit'
// 支付方式 窗口
export const slice = createSlice({
  name: 'PaymentDialog',
  initialState: {
    open: false,
    orderID: null, // 临时订单ID
    stripe: false,
  },
  reducers: {
    setPaymentDialogOrderID: (state, action) => {
      state.orderID = action.payload
    },
    openPaymentDialog: (state) => {
      state.open = true
    },
    closePaymentDialog: (state) => {
      state.open = false
    },
    openStripeDialog: (state) => {
      state.stripe = true
    },
    closeStripeDialog: (state) => {
      state.stripe = false
    }
  },
})

export const { 
  setPaymentDialogOrderID, 
  openPaymentDialog, 
  closePaymentDialog,
  openStripeDialog, 
  closeStripeDialog
} = slice.actions
export const PaymentDialogContent = state => state.PaymentDialog
export default slice.reducer