import { createSlice } from '@reduxjs/toolkit'

// 登录状态
export const slice = createSlice({
  name: 'sign',
  initialState: {
    value: false,
  },
  reducers: {
    signIn: (state) => {
      state.value = true
    },
    signOut: (state) => {
      state.value = false
    }
  },
})

export const { signIn, signOut } = slice.actions
export const signState = state => state.sign.value
export default slice.reducer