import { Hidden, Button, IconButton} from '@material-ui/core'
import { AccountCircleOutlined } from '@material-ui/icons'

import { RSText } from 'utils/RSUtils'

import { useDispatch } from 'react-redux'
import { openAuthDialog } from 'redux/AuthDialogSlice'

export default function AuthHeaderBtn() {
  const text = RSText()
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(openAuthDialog())
  }

  return (
    <>
    <Hidden mdDown>
      <Button
        startIcon={<AccountCircleOutlined />}
        onClick={handleClick}
      >
        {text.signIn}
      </Button>
    </Hidden>

    <Hidden lgUp>
      <IconButton onClick={handleClick}>
        <AccountCircleOutlined />
      </IconButton> 
    </Hidden>
    </>
  )
}