import { makeStyles } from "@material-ui/core";

function useStyles() {
  const styles = makeStyles((theme)=>({
    content:{
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    },
    section:{
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    drawer: {
      minWidth: 200
    },
    title: {
      paddingLeft: theme.spacing(2)
    },
    
    shadow: {
      boxShadow: "0px 0px 5px 1px rgb(0 0 0 / 20%)"
    },

    // 间距
    mtb: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    mlr: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    ml: {
      marginLeft: theme.spacing(1)
    },
    mt:{
      marginTop: theme.spacing(1)
    },

    // margin bottom
    mb:{
      marginBottom: theme.spacing(1),
    },
    mb2:{
      marginBottom: theme.spacing(2),
    },
    mb4:{
      marginBottom: theme.spacing(4),
    },


    mr: {
      marginRight: theme.spacing(1)
    },
    mr2:{
      marginRight: theme.spacing(2)
    },
    mt2: {
      marginTop: theme.spacing(2)
    },
    mr4: {
      marginRight: theme.spacing(4)
    },
    fr: {
      float: 'right'
    },

    // Page Layout
    pageFooter: {
      marginTop: theme.spacing(4),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      background: '#f0f0f0'
    },

    // Dialogs
    dialogContentBg: {
      background: '#f5f5f5 !important',
      padding: 16
    },

    // Auth dialog
    authIcon: {
      width: theme.spacing(4),
      height: theme.spacing(4)
    },
    authIconBtn: {
      textAlign: 'center'
    },
    qrFrame: {
      height: theme.spacing(50)
    },
    authForm: {
      '& > div': {
        marginBottom: theme.spacing(2),
      },
      marginTop: theme.spacing(1)
    },
    authBadge: {
      width: 5,
      height: 5,
      padding: 5,
      border: '1px solid currentColor',
      fontSize: 9,
      color: '#fff',
      backgroundColor: '#f50057',
    },

    // Check out dialog
    checkOutNextStepBtn: {
      marginLeft:'auto !important'
    },
    addressListContent: {
      padding: '5px'
    },
    addressActiveBg: {
      background:'#ffe5b2'
    }

  }))
  return styles()
}

export default useStyles