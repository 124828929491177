import { createSlice } from '@reduxjs/toolkit'

// Stripe窗口
export const slice = createSlice({
  name: 'StripeDialog',
  initialState: {
    show: false
  },
  reducers: {
    openStripeDialog: (state) => {
      state.show = true
    },
    closeStripeDialog: (state) => {
      state.show = false
    },
  },
})

export const { openStripeDialog, closeStripeDialog } = slice.actions
export const StripeDialogContent = state => state.StripeDialog
export default slice.reducer