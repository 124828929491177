import { Container, Grid, Typography } from '@material-ui/core'
import { RSText } from 'utils/RSUtils'
import PageTitle from 'contents/common/PageTitle'
import useStyles from 'utils/RSStyles'
import RSEmoji from 'contents/common/RSEmoji'
import CustomerServiceBtn from 'contents/common/CustomerServiceBtn'

export default function Fail() {
  const text = RSText()
  const classes = useStyles()
  return (
    <Container maxWidth="md">
      <PageTitle title={text.paymentFailed} />
      <Grid container className={classes.content}>
        <Grid item xs={12}>
          <RSEmoji className={classes.mb2} width="200" name="sorry" />
          <Typography align="center" variant="h5" className={classes.mb2}>{text.paymentFailed}</Typography>
          <Typography align="center" gutterBottom>
            <CustomerServiceBtn /> 
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}