import { makeStyles, Button } from '@material-ui/core'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'

import { useSelector, useDispatch } from 'react-redux'
import { signState } from 'redux/SignStateSlice'
import { openCartDrawer } from 'redux/CartDrawerSlice'
import { openAuthDialog } from 'redux/AuthDialogSlice'
import { cartAmount } from 'redux/CartSlice'

const useStyles = makeStyles((theme) => ({
  mr2: {
    marginRight: theme.spacing(2)
  }
}))

export default function CartBtn() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const signed = useSelector(signState) // 登录状态
  const amount = useSelector(cartAmount)

  const openDrawer = () => {
    if(signed) {
      dispatch(openCartDrawer())
    } else {
      dispatch(openAuthDialog())
    }
  }

  return (
    <div className={classes.mr2}>
    <Button 
      variant="contained"
      color="secondary"
      startIcon={<ShoppingCartOutlinedIcon />}
      onClick={()=>openDrawer()}
    >
      £{signed?amount:"0.00"}
    </Button>
    </div>
  )
}