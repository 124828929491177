import { useState, useEffect } from 'react'
import { RSGet, RSText } from 'utils/RSUtils'
import { Container, List, ListItem, ListItemText, Typography, Divider, Button } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import PageTitle from 'contents/common/PageTitle'
import useStyles from 'utils/RSStyles'
import DeliverTime from 'dialogs/checkout/step2/DeliveryTime'
import RSEmoji from "contents/common/RSEmoji"

import { useDispatch } from 'react-redux'
import { openOrderDialog } from 'redux/OrderDialogSlice'
import { openTrackingDialog } from 'redux/TrackingDialogSlice'

import moment from "moment"
require('moment/locale/en-gb')

export default function MyOrdersPage() {
  const text = RSText()
  const [orders, setOrders] = useState([])
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)

  const classes = useStyles()
  const handleChange = (event, value) => {
    setPage(value)
    window.scrollTo(0, 0);
  }

  const pagesize = 7

  useEffect(()=>{
    if(page) {
      RSGet('customer/orders/'+page+'/'+pagesize, data => {
        //RSLog(data)
        setOrders(data)
      })
    }
  }, [page])

  useEffect(()=>{
    RSGet('customer/ordersPages', data => {
      setPages(data)
    })
  }, [])

  return (
    <Container maxWidth="sm">
      <PageTitle title={text.myOrders} />
      <Typography variant="h5" className={`${classes.title} ${classes.mb2}`} >{text.myOrders}</Typography>
      <Divider />
      {
        orders.length > 0 && 
        <>
        <OrderList orders={orders} />
        {pages > 1 && <Pagination count={pages} color="secondary" page={page} onChange={handleChange} className={classes.mt} /> }
        </>
      }

      {
        orders.length === 0 &&
        <>
        <RSEmoji name='cart-right' className={`${classes.mb2} ${classes.mt2}`} />
        <Typography align="center" variant="h5" className={classes.mb4}>{text.noOrders}</Typography>
        </>
      }
      
    </Container>
  )
}

function OrderList(props) {
  const {orders} = props
  const classes = useStyles()
  const text = RSText()
  const dispatch = useDispatch()

  const openDialog = (orderID) => {
    dispatch(openOrderDialog(orderID))
  }

  const tracking = (trackingNumber) => {
    dispatch(openTrackingDialog(trackingNumber))
  }

  return (
    <List className={classes.mb2}>
      {orders.map((order)=> 
      <div key={order.order_id}>
        
        <ListItem>
          <ListItemText primary={'£'+order.amount} secondary={moment(order.datetime).format('L')+' '+moment(order.datetime).format('LT')} />

          <Status order={order} className={classes.mr} />

          { order.tracking && order.tracking_number && 
          <Button variant="outlined" size="small" className={classes.mr} onClick={()=>tracking(order.tracking_number)}>{text.trackingBtn}</Button>
          }

          <Button variant="outlined" size="small" onClick={()=>openDialog(order.order_id)}>{text.viewBtn}</Button>
        </ListItem>
        {(order.status === '0') && <DeliverTime postcode={order.postcode} datetime={order.datetime} />}
        <Divider />
      </div>
      )}
    </List>
  )
}

function Status(props) {
  const text = RSText()
  const {order, className} = props
  if(order.status === '0' && order.tracking_number === '') {
    return (<Typography className={className} variant="body2" color="primary">{text.onPacking}</Typography>)
  }
  else if(order.status === '1') {
    return (<Typography className={className} variant="body2" color="primary">{text.delivered}</Typography>)
  }
  else if(order.status === '2') {
    return (<Typography className={className} variant="body2" color="secondary">{text.cancelled}</Typography>)
  }
  
  return ''
}