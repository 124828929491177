import { RSText } from "utils/RSUtils"
import { Button } from '@material-ui/core'

import { useDispatch } from 'react-redux'
import { openAuthDialog } from 'redux/AuthDialogSlice'

export default function AuthBtn() {
  const text = RSText()
  const dispatch = useDispatch()
  
  const handleClick = () => {
    dispatch(openAuthDialog())
  }
  
  return (
    <Button variant="contained" color="secondary" onClick={handleClick}>{text.signInBtn}</Button>
  )
}