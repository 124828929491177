import { getLanguage, RSText } from 'utils/RSUtils'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Grid, Card, CardContent, Typography, Divider } from '@material-ui/core'
import BuyBtns from 'contents/product/components/BuyBtns'
import Price from 'contents/product/components/Price'
import { PromotionTag, CategoryTags, FrozenTag, YouTubeTag, ChilledTag } from 'contents/product/components/Tags'
import { FavoriteBtnWithStr } from 'contents/product/components/FavoriteBtn'
import 'swiper/swiper.scss' 
import useStyles from 'utils/RSStyles'

SwiperCore.use([Pagination, Autoplay]);

export default function ProductDetails(props) {
  const { product } = props
  const classes = useStyles()
  const text = RSText()
  const language = getLanguage()
  
  return (
    <Grid container spacing={1}>
      { product && 
      <>
      <Grid item xs={12}>
        <ImgSwiper product={product} />
      </Grid>

      { product.video && 
      <Grid item xs={12}>
        <div className="video-wrapper">
          <iframe title="Product video player" src={product.video} frameBorder="0" allowFullScreen></iframe>
        </div>
      </Grid>
      }

      <Grid item xs={12}>
        <Card className={classes.mb2}>
          <CardContent>  
            
            <Typography variant="h5" gutterBottom>
              {product.name} <FrozenTag product={product} /> <ChilledTag product={product} /> <YouTubeTag product={product} />
              <div className={classes.fr}>  <PromotionTag product={product} /></div>
            </Typography>
           
            
            <Typography variant="h5" gutterBottom>
              <span className={classes.mr4}><Price product={product} /></span>
            </Typography>

            {
              product.note && language === 'cn' &&
              <Typography variant="body2" color="secondary" gutterBottom>
                {product.note}
              </Typography>
            }

            <FavoriteBtnWithStr product={product} />
            <div className={classes.fr}><BuyBtns product={product} /></div>
            <div style={{clear:'both'}} />

            <Divider className={`${classes.mt2} ${classes.mb2}`} />

            <div className={classes.mb2}><CategoryTags product={product} /></div>

            <Typography variant="body2" style={{whiteSpace: 'pre-line'}} >
              <span className={classes.mr2}>{"SKU: #"+product.id}</span>
              {text.weight+": "+product.weight}KG<br/><br/>
              {product.details}
            </Typography>
          </CardContent>

         
        </Card>
        {
          product.large_images && product.large_images.map((image)=>
            <img alt={product.name} src={image.url} width="100%" key={image.id} />
          )
        }
      </Grid>
      </>
      }
    </Grid>
  )
}



function ImgSwiper(props) {
  const { product } = props

  return (
    <Swiper
      slidesPerView={1}
      loop={true}
      pagination={{ clickable: true }}
      autoplay
    >
    {
      product.images.map((images) => 
        <SwiperSlide key={images.id}>
          <img alt={product.name} src={images.url+"?x-oss-process=style/555x555"} width="100%" />
        </SwiperSlide>
      )
    }
    </Swiper>
  )
}