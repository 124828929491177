// React
import { useEffect} from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

// GA UA
// import ReactGA from 'react-ga'

// Firebase
import firebase from "firebase/app"
import { firebaseConfig } from "./FirebaseCredentials"
import "firebase/auth"
import "firebase/analytics"

// utils
import { RSGet, RSLog, RSPost, Get, checkVersion, useQuery, setLanguage, getLanguage } from 'utils/RSUtils'
import { setFavorites } from 'contents/product/components/FavoriteBtn'

// Layout
import Header from 'header/Header'
import Footer from 'footer/Footer'
import Contents from 'contents/Contents'
import ScrollToTop from 'contents/common/ScrollToTop'

// Dialogs
import AuthDialog from 'contents/auth/AuthDialog'
import EmailForgetDialog from 'contents/auth/EmailForgetDialog'
import ProductDialog from 'contents/product/ProductDialog'
import ReminderDialog from 'contents/product/components/ReminderDialog'

import AlertDialog from 'contents/common/AlertDialog'
import CookieAlert from 'contents/common/CookieAlert'
import OrderDialog from 'dialogs/OrderDialog'
import TrackingDialog from 'dialogs/TrackingDialog'

import CheckoutDialog from 'dialogs/CheckoutDialog'
import SoldoutDialog from 'dialogs/SoldoutDialog'
import AddressListDialog from 'dialogs/checkout/step1/AddressListDialog'
import AddressFormDialog from 'dialogs/checkout/step1/AddressFormDialog'

// Drawers
import SearchDrawer from 'drawers/SearchDrawer'
import CartDrawer from 'drawers/CartDrawer'
import ProductCategoryDrawer from 'contents/product/MobileCategoryDrawer'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { signIn, signState } from 'redux/SignStateSlice'
import { setCategoryTags } from 'redux/CategoryDrawerSlice'
import { initCart, cartContent } from 'redux/CartSlice'
import LoadingDialog from 'dialogs/LoadingDialog'
import PaymentDialog from 'dialogs/payment/PaymentDialog'
import { setProfile } from 'redux/CustomerSlice'
import { setReminders } from 'redux/ReminderSlice'
import LiveChat, { hideLiveChat, showLiveChat } from 'components/livechat/LiveChat'

export default function App() {
  // Google Analytics Universal Analaytics 旧版GA
  //ReactGA.initialize('UA-116735751-1') 
  
  // Firebase
  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig)
    firebase.analytics() // 用于Firebase记录DAU
  }

  let query = useQuery()

  // Redux
  const dispatch = useDispatch()
  const signed = useSelector(signState) // 登录状态
  const cart = useSelector(cartContent)

  // 站点打开时初始化
  // Init
  useEffect(() => {

    // 有语言参数时 强制转换
    if(query.lang) {
      const lang = getLanguage()
      if(query.lang === 'cn' || query.lang === 'en') {
        if(query.lang !== lang) {
          setLanguage(query.lang)
          window.location.href = window.location.origin+window.location.pathname
        }
      }
    }

    // 登录状态检测
    RSGet('auth/init', data => {
      RSLog('登录状态检测 '+data.signed)
      if(data.signed) {
        dispatch(signIn())
      }

      RSLog('版本检测 '+data.version)
      if(data.version) {
        checkVersion(data.version)
      }
    })

    Get('homepage/menu', data => {
      RSLog('加载产品类别')
      dispatch(setCategoryTags({tags: data}))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 登录状态变化时的操作
  useEffect(() => {
    RSLog('登录状态变更 '+signed)
    if(signed){
      // 登录中
      RSLog('初始化 用户')
      RSGet('customer/my', data=>{
        dispatch(setProfile(data))
      })
      
      RSLog('初始化 收藏夹')
      RSGet('customer/allFavorites', data=>{
        setFavorites(data)
      })

      RSLog('初始化 到货提醒列表')
      RSGet('replenishmentReminder/all', data=>{
        RSLog(data)
        dispatch(setReminders(data))
      })

      RSLog('初始化 购物车')
      RSGet('cart', data=>{
        dispatch(initCart(data))
      })

      // 客服组件展示
      showLiveChat()
    } else {
      // 未登录
      RSLog('收藏夹清空')
      setFavorites([])

      RSLog('购物车清空')
      dispatch(initCart([]))

      // 客服组件展示
      hideLiveChat()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signed])
   
  // 购物车有变化时
  // 上传服务器端购物车数据
  useEffect(()=>{
    if (signed) {
      RSLog('同步购物车')
      RSLog(cart)
      RSPost('cart/update', {cart: cart}, data => {
        RSLog(data)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  return ( 
    <Router>
      {/* Auth 套件 */}
      <AuthDialog />
      <EmailForgetDialog />

      {/* Checkout 套件 */}
      <CartDrawer />
      <CheckoutDialog />
      <SoldoutDialog />
      <AddressListDialog />
      <AddressFormDialog />

      {/* My 套件*/}
      <OrderDialog />
      <TrackingDialog />

      {/* Layout */}
      <Header />
      <Contents />
      <Footer />

      {/* 全局组件 */}
      <ScrollToTop /> {/* URL变化时滚动到页面顶部 */}
      <CookieAlert /> {/* 首次登录 Cookie 提醒*/}
      <LoadingDialog />
      <ReminderDialog />

      <SearchDrawer />
      <ProductCategoryDrawer />
      <ProductDialog />
      <AlertDialog />
      <PaymentDialog />

      <LiveChat />
     
    </Router>
  )
}