import { createSlice } from '@reduxjs/toolkit'

// 登录状态
export const slice = createSlice({
  name: 'Address',
  initialState: {
    address: null
  },
  reducers: {
    openAddressDialog: (state, action) => {
      state.value.show = true
      state.value.msg = action.payload.msg
      state.value.emoji = action.payload.emoji
      state.value.severity = action.payload.severity
    },
    closeAddressDialog: (state) => {
      state.value.show = false
      state.value.msg = ''
      state.value.emoji = 'cart-right'
      state.value.severity = 'success'
    }
  },
})

export const { openAddressDialog, closeAddressDialog } = slice.actions
export const Address = state => state.Address
export default slice.reducer