import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// IE support

import React from 'react';
import ReactDOM from 'react-dom';
import 'index.css';
import 'fontsource-roboto';
import App from 'App';

// Redux
import store from 'redux/Store'
import { Provider } from 'react-redux'

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);