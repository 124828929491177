import { useState } from 'react'
import { makeStyles, Avatar, Menu, Typography, Button, Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  snsIcons: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(1),
    },
    justifyContent: 'center'
  },
  iconBtn: {
    margin: 0
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  }
}))

export default function SnsIcons() {
  const classes = useStyles()

  return (
    <>
    <div className={classes.snsIcons}>
      <WechatOABtn />
      <XiaohongshuBtn />
      <FacebookBtn />
    </div>
    </>
  )
}

function FacebookBtn() {
  const classes = useStyles()
  return (
    <Button 
      className={classes.iconBtn}
      component="a" 
      href="https://www.facebook.com/RedSquirrelShop/" 
      target="_blank" 
      rel="noreferrer"
    >
      <Avatar src="/images/auth/facebook.png" className={classes.small}/>
    </Button>
  )
}

// ************************************************************************
function WechatOABtn() {
  const classes = useStyles()
  const [open, setOpen] = useState(null)

  const handleClick = (event) => {
    setOpen(event.currentTarget)
  };

  const handleClose = () => {
    setOpen(null)
  };

  return (
    <>
      <Button 
        aria-controls="wechat-qrcode" 
        aria-haspopup="true" 
        onClick={handleClick}
        className={classes.iconBtn}
      >  
        <Avatar src="/images/auth/wechat.png" className={classes.small} />
      </Button>

      <Menu 
        id="wechat-qrcode"
        anchorEl={open}
        keepMounted
        open={Boolean(open)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Grid container>
          <Grid item>
          <div>
            <img src="/images/sns/wechat_p_qrcode.jpg" alt="Wechat QRCode" width="100" />
            <Typography variant="caption" component="p" align="center">微信客服号</Typography>
            <Typography variant="caption" component="p" align="center">英国红松鼠</Typography>
          </div>
          </Grid>
          <Grid item>
          <div>
            <img src="/images/sns/wechat_oa_qrcode.jpg" alt="Wechat OA QRCode" width="100" />
            <Typography variant="caption" component="p" align="center">微信公众号</Typography>
            <Typography variant="caption" component="p" align="center">红松鼠超市</Typography>
          </div>
          </Grid>
        </Grid>
      </Menu>
    </>
  )
}

// ************************************************************************
function XiaohongshuBtn() {
  const classes = useStyles()
  const [open, setOpen] = useState(null)

  const handleClick = (event) => {
    setOpen(event.currentTarget)
  };

  const handleClose = () => {
    setOpen(null)
  };

  return (
    <>
      <Button 
        aria-controls="xiaohongshu-qrcode" 
        aria-haspopup="true" 
        onClick={handleClick}
        className={classes.iconBtn}
      >  
         <Avatar src="/images/sns/xiaohongshu.png" className={classes.small} />
      </Button>

      <Menu 
        id="xiaohongshu-qrcode"
        anchorEl={open}
        keepMounted
        open={Boolean(open)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <img src="/images/sns/xiaohongshu_qrcode.png" alt="Xiaohongshu QRCode" width="100" />
        <div>
          <Typography variant="caption" component="p" align="center">小红书</Typography>
          <Typography variant="caption" component="p" align="center">英国红松鼠</Typography>
        </div>
      </Menu>
    </>
  )
}