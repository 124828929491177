import { Link } from "react-router-dom"
import {makeStyles, Button, Hidden} from '@material-ui/core'
import { RSText } from 'utils/RSUtils'
import { HomeOutlined } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  mr1: {
    marginRight: theme.spacing(1),
  }
}))

// Homepage Button
export default function HomepageBtn(props) {
  const text = RSText()
  const classes = useStyles()

  return (
    <Hidden mdDown>
      <Button 
        component={Link} 
        to={'/'}
        startIcon={<HomeOutlined />}
        className={classes.mr1}
      >
        {text.homepage}
      </Button>
    </Hidden>
  )
}