import { configureStore } from '@reduxjs/toolkit'
import SignStateReducer from 'redux/SignStateSlice'
import AlertDialogReducer from 'redux/AlertDialogSlice'
import AuthDialogReducer from 'redux/AuthDialogSlice'
import CartDrawerReducer from 'redux/CartDrawerSlice'
import ProductDialogReducer from 'redux/ProductDialogSlice'
import ChatDialogReducer from 'redux/ChatDialogSlice'
import CheckoutDialogSlice from 'redux/CheckoutDialogSlice'
import CategoryDrawerReducer from 'redux/CategoryDrawerSlice'
import SearchDrawerSlice from 'redux/SearchDrawerSlice'
import CartSlice from 'redux/CartSlice'
import SoldoutDialogSlice from './SoldoutDialogSlice'
import OrderDialogSlice from './OrderDialogSlice'
import StripeDialogSlice from './StripeDialogSlice'
import TrackingDialogSlice from './TrackingDialogSlice'
import EmailForgetDialogSlice from './EmailForgetDialogSlice'
import LoadingDialogSlice from './LoadingDialogSlice'
import PaymentDialogSlice from './PaymentDialogSlice'
import AddressSlice from './AddressSlice'
import CustomerSlice from './CustomerSlice'
import ReminderSlice from './ReminderSlice'
import LiveChatSlice from './LiveChatSlice'

export default configureStore({
  reducer: {
    sign: SignStateReducer,
    Cart: CartSlice,

    alertDialog: AlertDialogReducer,
    authDialog: AuthDialogReducer,
    productDialog: ProductDialogReducer,
    chatDialog: ChatDialogReducer,
    CheckoutDialog: CheckoutDialogSlice,
    SoldoutDialog: SoldoutDialogSlice,
    OrderDialog: OrderDialogSlice,
    StripeDialog: StripeDialogSlice,
    TrackingDialog: TrackingDialogSlice,
    EmailForgetDialog: EmailForgetDialogSlice,
    LoadingDialog: LoadingDialogSlice,
    PaymentDialog: PaymentDialogSlice,

    cartDrawer: CartDrawerReducer,
    categoryDrawer: CategoryDrawerReducer,
    SearchDrawer: SearchDrawerSlice,

    Customer: CustomerSlice,
    Address: AddressSlice,

    Reminder: ReminderSlice,

    Livechat: LiveChatSlice,
  },
})