import { createSlice } from '@reduxjs/toolkit'

// 已售完提醒窗口
export const slice = createSlice({
  name: 'SoldoutDialog',
  initialState: {
    show: false,
    products: [] // 超卖的产品
  },
  reducers: {
    openSoldoutDialog: (state, action) => {
      state.products = action.payload
      state.show = true
    },
    closeSoldoutDialog: (state) => {
      state.show = false
    }
  },
})

export const { openSoldoutDialog, closeSoldoutDialog } = slice.actions
export const SoldoutDialogContent = state => state.SoldoutDialog
export default slice.reducer