import { useState, useEffect } from 'react'
import { RSGet } from 'utils/RSUtils'
import Img from 'contents/common/Img'
import {makeStyles} from '@material-ui/core'
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/components/pagination/pagination.scss';
import 'swiper/swiper.scss'
SwiperCore.use([Pagination, Autoplay]);

const useStyles = makeStyles((theme)=>({
  mb: {
    marginBottom: theme.spacing(2)
  }
}))

export default function LargeBanners() {
  const [banners , setBanners] = useState([])
  const classes = useStyles()

  useEffect(()=>{
    RSGet('homepage/largeBanners', data => {
      setBanners(data)
    })  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.mb}>
      { banners.length>0 && 
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        pagination={{ clickable: true }}
        autoplay
      >
        {
          banners.map((banner) =>  
            <SwiperSlide key={banner.id} >
              <a href={banner.url}><Img src={banner.img} aspectRatio={1000/418} /></a>
            </SwiperSlide>
          )
        }
      </Swiper>
      }
    </div>  
  )
}

