import { getLanguage, RSText } from 'utils/RSUtils'
import { 
  makeStyles, Grid, SwipeableDrawer, List, ListItem, ListItemText, 
  ListItemAvatar, Typography, 
  IconButton, Button, Link, Divider } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import BuyBtns from 'contents/product/components/BuyBtns'
import Img from 'contents/common/Img'

import { useSelector, useDispatch } from 'react-redux'
import { openCartDrawer, closeCartDrawer, cartDrawerContent } from 'redux/CartDrawerSlice'
import { openProductDialog } from 'redux/ProductDialogSlice'
import { openCheckoutDialog } from 'redux/CheckoutDialogSlice'
import { cartAmount, cartContent, cartCount, initCart, removeOne } from 'redux/CartSlice'
import { ChilledTag, FrozenTag } from 'contents/product/components/Tags'

const useStyles = makeStyles((theme) => ({
  cartDrawer: {
    width: 300
  },
  mt2: {
    marginTop: theme.spacing(2)
  },
  mr1: {
    marginRight: theme.spacing(1)
  },
  mr2: {
    marginRight: theme.spacing(2)
  },
  mb2: {
    marginBottom: theme.spacing(2)
  },
  name: {
    textDecoration: 'none',
    color: 'inherit',
    fontSize: 16,
    textAlign: 'left'
  },
  removeBtn: {
    float: 'left',
    marginLeft: 15
  }
}))

// 购物车 Drawer
export default function CartDrawer() {
  const classes = useStyles()
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const text = RSText()

  const dispatch = useDispatch()
  const cartDrawer = useSelector(cartDrawerContent)
  const cart = useSelector(cartContent)

  return (
    <SwipeableDrawer 
      disableEnforceFocus
      anchor="right" 
      open={cartDrawer.show} 
      onClose={()=>dispatch(closeCartDrawer())} 
      onOpen={()=>dispatch(openCartDrawer())} 
      disableBackdropTransition={!iOS} 
      disableDiscovery={iOS}
    >
      
      <List className={`${classes.cartDrawer} ${classes.mb2}`}>

        {
          cart.length > 0 && 
          <>
          <CheckOutItem />
          
          <CheckoutListItem />
          
          {
            cart.map((item)=>
            <div key={item.id} >
            <Divider className={classes.mt2} />
            <CartItem product={item} />
            </div>
            )
          }
          <Divider className={classes.mt2} />
          <div className={classes.mt2}>
            {cart.length > 4 &&  <CheckoutListItem />}
          </div>
          </>
        }

        {
          // empty trolley
          cart.length === 0 && 
          <div align="center">
            <img className={classes.mt2} alt="empty cart" src="/images/rs-cart-right.png" width="200" />
            <Typography className={classes.mt2} variant="h5">{text.emptyCart}</Typography>
          </div>
        }

      </List>
    </SwipeableDrawer>
  )
}

function CartItem(props) {
  const { product } = props
  const total = (product.price*product.qty).toFixed(2)
  const priceQty = `£${product.price} x ${product.qty} = £${total}`
  const classes = useStyles()
  const dispatch = useDispatch()

  const openDialog = (productID) => {
    dispatch(openProductDialog(productID))
  }

  return (
    <>
    <ListItem>
      <ListItemAvatar className={classes.mr1}>
        <Img src={product.img} oss="50x50" onClick={()=>openDialog(product.id)} />
      </ListItemAvatar>
      <ListItemText 
        primary={
          <Link component="button" className={classes.name} onClick={()=>openDialog(product.id)}>
            {product.name}
          </Link>
        } 
        secondary={priceQty} 
      />
      <FrozenTag product={product} />
      <ChilledTag product={product} />
    </ListItem>
    <Typography align='right' className={classes.mr2}>
      <RemoveBtn product={product} />
      <BuyBtns product={product} />
    </Typography>  
    </>
  )
}


function CheckOutItem() {
  const classes = useStyles()
  const amount = useSelector(cartAmount)
  const items = useSelector(cartCount)

  const text = RSText()

  const SecondaryText = () => {
    return text.inCart1 + items + text.inCart2
  }

  return (
    <ListItem className={classes.mt2}>
      <ListItemText primary={<b>{text.total+' £'+amount}</b>} secondary={<SecondaryText />} />
    </ListItem>
  )
}

function CheckoutListItem () {
  return (
    <Grid container>
      <Grid item xs={6}>
        <span style={{marginLeft:15}}><ClearCartBtn /></span>
      </Grid>
      <Grid item xs={6} align="right">
        <span style={{marginRight:15}}><CheckoutBtn /></span>
      </Grid>
    </Grid>
  )
}

// ***************************************************************
// 按钮 移出购物车
function RemoveBtn(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {product} = props
  const language = getLanguage()
  const confirmText = (language === 'cn')?'确定移出购物车?':'Are you sure to remove from cart?'

  const handleRemove = () => {
    if(window.confirm(confirmText)){
      dispatch(removeOne(product))
    }
  }

  return (
    <IconButton 
      className={classes.removeBtn}
      size="small"
      onClick={handleRemove}
    >
      <CloseOutlined />
    </IconButton>
  )
}

// ***************************************************************
// 按钮 下单
function CheckoutBtn() {
  const language = getLanguage()
  const btnText = (language === 'cn')?'下单':'Check out'
  const dispatch = useDispatch()

  const handleClick = (e) => {
    dispatch(closeCartDrawer())
    dispatch(openCheckoutDialog())
  }

  return (
    <Button 
      variant="contained" 
      color="secondary" 
      onClick={handleClick}
      size="small"
    >
      {btnText}
    </Button>
  )
}

// ***************************************************************
// 按钮 清空购物车
function ClearCartBtn() {
  const dispatch = useDispatch()
  const language = getLanguage()

  const btnText = (language === 'cn')?'清空':'Clear all'
  const confirmText = (language === 'cn')?'确定清空购物车?':'Are you sure to clear cart?'

  const handleClear = () => {
    if(window.confirm(confirmText)){
      dispatch(initCart([]))
    }
  }

  return (
    <Button
      variant="contained"
      onClick={handleClear}
      size="small"
    >
      {btnText}
    </Button>
  )
}