import { Button, Container, Grid, Typography } from '@material-ui/core'
import { RSText } from 'utils/RSUtils'
import {Link} from 'react-router-dom'
import PageTitle from 'contents/common/PageTitle'
import useStyles from 'utils/RSStyles'

export default function Error404Page() {
  const text = RSText()
  const classes = useStyles()
  return (
    <Container maxWidth="md">
      <PageTitle title="Page 404" />
      <Grid container className={classes.content}>
        <Grid item xs={12}>
          <Typography align="center" className={classes.mb4}><img src="/images/rs-sorry.png" width="300" alt="404" /></Typography>
          <Typography align="center" variant="h5" className={classes.mb4}>{text.error404}</Typography>
          <Typography align="center" gutterBottom><Button variant="contained" color="secondary" component={Link} to="/">{text.backToHome}</Button></Typography>
        </Grid>
      </Grid>
      
    </Container>
  )
}