import { RSText } from 'utils/RSUtils'
import { makeStyles, Grid, Container, Hidden } from '@material-ui/core'
import LargeBanners from 'contents/homepage/LargeBanners'
import SmallBanners from 'contents/homepage/SmallBanners'
import Features from 'contents/homepage/Features'
import Gift from 'contents/homepage/Gift'
import Icons from 'contents/homepage/Icons'
import GridList from 'contents/product/components/GridList'
import PageTitle from 'contents/common/PageTitle'

const useStyles = makeStyles((theme)=>({
  mb: {
    marginBottom: theme.spacing(1)
  },
  mobileLargeBanner: {
    marginTop:-30
  }
}))

export default function Homepage() {
  const text = RSText()
  const classes = useStyles()

  return (
    <>
      <PageTitle title={text.slogan} />

      <Hidden mdUp>
        <div className={classes.mobileLargeBanner}>
        <LargeBanners />
        </div>
      </Hidden>

      <Container maxWidth="xl">
        <Grid container spacing={1}>

          <Hidden smDown>
          <Grid item lg={6} xs={12}>
            <LargeBanners />
          </Grid>
          </Hidden>

          
          <Grid item lg={4} md={8} xs={12}>
            <Icons />
          </Grid>

          <Grid item lg={2} md={4} xs={12}>
            {/* <Gift /> */}
            <Features />
          </Grid>

        </Grid>

      </Container>
     
      <SmallBanners />

      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <GridList tag={text.new} api={'product/news'} />
          </Grid>
        </Grid>
      </Container>
      

      <Hidden smUp>
        <Features />
      </Hidden>
    </>
  )
}