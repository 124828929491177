/* MenuBtn 网站主导航 */
/* 手机浏览时出现的按钮 */

import { useState } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, IconButton, List, ListItem, SwipeableDrawer, ListItemIcon, ListItemText, Divider } from '@material-ui/core'
import clsx from 'clsx'

import { RSText, setLanguage } from 'utils/RSUtils'

import { MenuOutlined, HomeOutlined, LiveHelpOutlined, LoyaltyOutlined, NewReleasesOutlined, StorefrontOutlined, GTranslateOutlined, PeopleAltOutlined, RecordVoiceOverOutlined, DescriptionOutlined } from '@material-ui/icons'
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
//import HeadsetMicOutlinedIcon from '@material-ui/icons/HeadsetMicOutlined';

import { useDispatch } from 'react-redux'
import { openCategoryDrawer } from 'redux/CategoryDrawerSlice'

const useStyles = makeStyles((theme) => ({
  drawer: {
    minWidth: 200
  },
  mdShow: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    }
  },
  mr2: {
    marginRight: theme.spacing(2)
  },
}))

export default function MenuBtn() {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  return (
    <>
    <MobileMenu open={open} setOpen={setOpen} />
    <IconButton className={clsx(classes.mdShow, classes.mr2)} onClick={()=>setOpen(true)}>
      <MenuOutlined />
    </IconButton>
    </>
  )
}

function MobileMenu(props) {
  const { open, setOpen } = props
  const classes = useStyles()
  const text = RSText()
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

  const dispatch = useDispatch()

  const MobileMenuItem = (props) => {
    const {uri, icon, text} = props

    return (
      <ListItem button component={Link} to={uri} onClick={()=>setOpen(false)}>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    )
  }

  /*
  const MobileLiveChat = (props) => {
    const {icon, text} = props
    const { openChatDialog } = useContext(RSContext)
    const handleClick = () => {
      setOpen(false)
      openChatDialog()
    }

    return (
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    )
  }
  */

  // 购物相关
  const ShoppingMenu = () => {

    const handleClick = () => {
      dispatch(openCategoryDrawer())
      setOpen(false)
    }

    return (
      <>
      <ListItem><small>Shopping</small></ListItem>

      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <StorefrontOutlined />
        </ListItemIcon>
        <ListItemText primary={text.shopping} />
      </ListItem>


      <MobileMenuItem 
        uri="/new-arrivals"
        icon={<NewReleasesOutlined />}
        text={text.new}
      />

      <MobileMenuItem 
        uri="/on-sale"
        icon={<LoyaltyOutlined />}
        text={text.sales}
      />
      
      </>
    )
  }

  // 帮助中心
  const HelpCenterMenu = () => {

    return (
      <>
      <ListItem><small>Help Center</small></ListItem>

      <MobileMenuItem 
        uri="/info/faq"
        icon={<LiveHelpOutlined />}
        text={text.faq}
      />

      <MobileMenuItem 
        uri="/info/privacy-policy"
        icon={<DescriptionOutlined/>}
        text={text.privacyPolicy}
      />

      <MobileMenuItem 
        uri="/info/terms-and-conditions"
        icon={<DescriptionOutlined />}
        text={text.termsAndConditions}
      />
      </>
    )
  }

  // 公司信息
  const CroporationMenu = () => {

    return (
      <>
      <ListItem><small>Corporation</small></ListItem>
      <MobileMenuItem 
        uri="/info/about-us"
        icon={<PeopleAltOutlined />}
        text={text.aboutUs}
      />

      <MobileMenuItem 
        uri="/info/contact-us"
        icon={<RecordVoiceOverOutlined />}
        text={text.contactUs}
      />

      <MobileMenuItem 
        uri="/info/join-us"
        icon={<GroupAddOutlinedIcon />}
        text={text.joinUs}
      />


      <MobileMenuItem 
        uri="/info/cooperation"
        icon={<ThumbUpAltOutlinedIcon />}
        text={text.cooperation}
      />
      </>
    )
  }

  // 语言选择
  const LanguageMenu = () => {
    const changeLanguage = (lang) => { 
      setLanguage(lang)
      window.location.reload()
    }
    
    return (
    <>
      <ListItem><small>Language</small></ListItem>
      <ListItem button onClick={()=>changeLanguage('en')}>
        <ListItemIcon>
          <GTranslateOutlined />
        </ListItemIcon>
        <ListItemText primary="English" />
      </ListItem>

      <ListItem button onClick={()=>changeLanguage('cn')}> 
        <ListItemIcon>
          <GTranslateOutlined />
        </ListItemIcon>
        <ListItemText primary="简体中文" />
      </ListItem>
    </>
    )
  }

  return (
    <SwipeableDrawer 
      anchor="left" 
      open={open} 
      onClose={()=>setOpen(false)} 
      onOpen={()=>setOpen(true)} 
      disableBackdropTransition={!iOS} 
      disableDiscovery={iOS}
    >
      
      <List className={`${classes.drawer} ${classes.mb2}`}>
        
        <MobileMenuItem 
          uri=""
          icon={<HomeOutlined />}
          text={text.homepage}
        />

        <Divider />
        <ShoppingMenu />
        <Divider />
        <LanguageMenu />
        <Divider />
        <HelpCenterMenu />
        <Divider />
        <CroporationMenu />
        

      </List>
    </SwipeableDrawer>
  )
}


