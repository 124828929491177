import { useState } from 'react'
import { Link } from "react-router-dom"
import { makeStyles, Hidden, Grid, Button, Menu, MenuItem } from '@material-ui/core'
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined'
import { RSText, RSURIEncode } from 'utils/RSUtils'

import { useSelector } from 'react-redux'
import { categoryDrawerContent } from 'redux/CategoryDrawerSlice'

const useStyles = makeStyles((theme) => ({
  mr1: {
    marginRight: theme.spacing(1),
  },
  shoppingMenu: {
    marginLeft: "-100px",
  },
}))

// Product Category Menu
export default function ShoppingBtn() {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const text = RSText()
  const categoryDrawer = useSelector(categoryDrawerContent)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Hidden mdDown>
      <Button 
        aria-controls="shopping-menu" 
        aria-haspopup="true" 
        onClick={handleClick}
        startIcon={<StorefrontOutlinedIcon />}
        className={classes.mr1}
      >
        {text.shopping}
      </Button>
      <Menu
        id="shopping-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        className={classes.shoppingMenu}
      >
        <Grid container>
          {
            categoryDrawer.tags && categoryDrawer.tags.map((m, index) => 
              <Grid item key={index}>
                <MenuItem onClick={handleClose} component={Link} to={`/shelf/${RSURIEncode(m.menu)}`}><b>{m.menu}</b></MenuItem>
                {
                  m.submenu.map((sm, index2) => 
                  <MenuItem onClick={handleClose} key={index2} component={Link} to={`/shelf/${RSURIEncode(sm)}`}>{sm}</MenuItem>
                  )
                }
              </Grid>
            )
          }
        </Grid>
      </Menu>
    </Hidden>
  )
}