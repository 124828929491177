import { TextField } from "@material-ui/core"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { RSLog, RSText } from "utils/RSUtils"


export function TextInput(props){
  const {value, required} = props
  const text = RSText()

  return (
    <TextField 
      {...props} 
      fullWidth 
      helperText={(value === '' && required)?text.formRequired:''}
      error={value === '' && required}
    />
  )
}

export function UKMobileInput(props) {
  const {value} = props
  return (
    <TextField 
      {...props}
      fullWidth 
      helperText={MobileValidation(value)}
      error={MobileValidation(value) !== ''}
      placeholder="07xxxxxxxxx"
    />
  )
}

export function MobileValidation(number) {
  const text = RSText()
  //const re = /^(?:(?:00)?44|0)7(?:[45789]\d{2}|624)\d{6}$/
  if(number === '') return text.formRequired
  //if(!re.test(String(number).trim())) return text.formFormat
  return ''
}




export function EmailInput(props) {
  const {value} = props

  return (
    <TextField 
      {...props}
      fullWidth 
      helperText={EmailValidation(value)}
      error={EmailValidation(value) !== ''}
    />
  )
}

export function EmailValidation(email) {
  const text = RSText()
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if(email === '') return ''
  if(!re.test(String(email).toLowerCase())) return text.formFormat
  return ''
}

//********************************************/
// Postcode
export function PostcodeInput(props) {
  const {label, value, onChange} = props

  const onChangePostcode = (e) => {
    e.target.value = PostcodeFormat(e.target.value)
    onChange(e)
  }

  return (
    <TextField 
      label={label}
      value={value}
      onChange={onChangePostcode}
      fullWidth 
      required
      helperText={PostcodeValidation(value)}
      error={PostcodeValidation(value) !== ''}
    />
  )
}

/*	formats a VALID postcode nicely: AB120XY -> AB1 0XY */
export function PostcodeValidation(postcode) {
  const text = RSText()
  if(postcode === '') return text.formRequired
  else return ''
	//if (IsValidPostcode(postcode)) {
    //return ''
	//} else {
    //return text.formFormat
	//}
}

function PostcodeFormat(postcode) {
  const re = /(^[A-Z]{1,2}[A-Z0-9]{1,2})([0-9][A-Z]{2}$)/i
	if (IsValidPostcode(postcode)) {
    return postcode.replace(re,"$1 $2").toUpperCase()
	} else {
    return postcode
	}
}

function IsValidPostcode(postcode) {
  const re = /[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}/i
  return re.test(postcode)
}




// Email Input
export const EmailTextField = forwardRef((props, ref) => {
  const text = RSText()
  const {value} = props
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState('')

  const validation = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
   
    if(value === '') {
      setError(true)
      setErrorText(text.formRequired)
      return false
    }
    
    if(!re.test(String(value).toLowerCase())) {
      setError(true)
      setErrorText(text.formFormat)
      return false
    }
    
    return true
  }

  useImperativeHandle(ref, ()=> ({
    validation: () => validation()
  }))

  useEffect(()=>{
    setError(false)
    setErrorText('')
  }, [value])

  return (
    <TextField 
      {...props}
      label={text.emailLabel}
      fullWidth 
      helperText={errorText}
      error={error}
    />
  )
})


// Password Input
export const PasswordTextField = forwardRef((props, ref) => {
  const text = RSText()
  const {value} = props
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState('')

  const validation = () => {
    if(value === '') {
      setError(true)
      setErrorText(text.formRequired)
      return false
    }
    return true
  }

  useImperativeHandle(ref, ()=> ({
    validation: () => validation()
  }))

  useEffect(()=>{
    setError(false)
    setErrorText('')
  }, [value])

  return (
    <TextField 
      {...props}
      label={text.passwordLabel}
      fullWidth 
      helperText={errorText}
      error={error}
      type="password"
    />
  )
})

// Password Input
export const ConfirmPasswordTextField = forwardRef((props, ref) => {
  const text = RSText()
  const {value} = props
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState('')

  const validation = () => {
    if(value === '') {
      setError(true)
      setErrorText(text.formRequired)
      return false
    }
    return true
  }

  const match = (confirmValue) => {
    RSLog(confirmValue)
    if(value !== confirmValue) {
      setError(true)
      setErrorText(text.formPasswordNotMatch)
      return false
    }
    return true
  }

  useImperativeHandle(ref, ()=> ({
    validation: () => validation(),
    match: (confirmValue) => match(confirmValue),
  }))

  useEffect(()=>{
    setError(false)
    setErrorText('')
  }, [value])

  return (
    <TextField 
      {...props}
      label={text.confirmPasswordLabel}
      fullWidth 
      helperText={errorText}
      error={error}
      type="password"
    />
  )
})