import { Avatar, IconButton } from "@material-ui/core"
import { Get, RSLog } from "utils/RSUtils"
import { isMobile } from "react-device-detect"
import useStyles from "utils/RSStyles"

// 支付宝 登录按钮
export default function AlipayLoginBtn() {
  const classes = useStyles()

  const handleClick = () => {
    if(isMobile) {
      RSLog('移动端 手机app端内登录')
      Get('auth/alipay_mobile', data => {
        if(data) {
          window.location.href = data
        }
      })
    } else {
      RSLog('桌面端 手机app扫码登录')
      Get('auth/alipay_desktop', data => {
        if(data) {
          window.location.href = data
        }
      })
    }
  }

  return (
    <IconButton size="small" className={classes.mtb} onClick={handleClick}>
      <Avatar alt="Alipay" src="/images/auth/alipay.png" className={classes.authIcon} />
    </IconButton>
  )
}