import { RSText } from 'utils/RSUtils'
import { FooterSection, FooterLink } from 'footer/Footer'
import { Link, Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { openChatDialog } from 'redux/ChatDialogSlice'

export default function HelpCenterSection() {
  const text = RSText()
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(openChatDialog())
  }
  return(
    <>
    <FooterSection text={text.help} />
    <FooterLink uri="my/orders" text={text.trackingOrder} />
    <Typography variant="body2" gutterBottom><Link href="#" onClick={handleClick}>{text.customerService}</Link></Typography>
    <FooterLink uri="info/privacy-policy" text={text.privacyPolicy} />
    <FooterLink uri="info/terms-and-conditions" text={text.termsAndConditions} /> 
    </>
  )
}