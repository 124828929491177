import { RSGet, RSText } from "utils/RSUtils"
import { ListItem, ListItemIcon, ListItemText, Divider} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlipay } from '@fortawesome/free-brands-svg-icons'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { isMobile } from 'react-device-detect'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { CheckoutDialogContent } from "redux/CheckoutDialogSlice";
import { openLoadingDialog } from 'redux/LoadingDialogSlice'

export default function AliPayListItem() {
  const text = RSText()
  const { order } = useSelector(CheckoutDialogContent)
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(openLoadingDialog())
    if (isMobile) {
      // 手机浏览器 wap 支付
      RSGet('payment/alipay/wap/'+order.order_id, data => {
        window.location.href = data
      })
    } else {
      // 桌面浏览器 web 支付
      RSGet('payment/alipay/web/'+order.order_id, data => {
        window.location.href = data
      })
    }
  }

  return (
    <>
    <ListItem button onClick={handleClick}>
      <ListItemIcon>
        <FontAwesomeIcon icon={faAlipay} size="2x" fixedWidth  style={{color: '#1677ff'}} />
      </ListItemIcon>
      <ListItemText primary={text.alipay} />
      <KeyboardArrowRightIcon />
    </ListItem>
    <Divider />
    </>
  )
}