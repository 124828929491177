import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { RSGet } from 'utils/RSUtils'
import { Container } from '@material-ui/core'

import PageTitle from 'contents/common/PageTitle'
import Details from 'contents/product/ProductDetails'

export default function ProductView() {
  const { id } = useParams()
  const [product, setProduct] = useState()
  const history = useHistory()

  useEffect(()=>{
    if(id) {
      RSGet('product/view/'+id, data => {
        if(!data) history.push('/404')
        else setProduct(data)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
    { product && 
    <Container maxWidth="sm">  
      <PageTitle title={product.name} />
      <Details product={product} />
    </Container>
    }
    </>
  )
}
