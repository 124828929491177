import { RSText } from 'utils/RSUtils'
import { FooterSection, FooterLink } from 'footer/Footer'

export default function CorporationSection() {
  const text = RSText()
  return(
    <>
    <FooterSection text={text.corporation} />
    <FooterLink uri="info/about-us" text={text.aboutUs} />
    <FooterLink uri="info/contact-us" text={text.contactUs} />
    <FooterLink uri="info/join-us" text={text.joinUs} />
    <FooterLink uri="info/cooperation" text={text.cooperation} />
    </>
  )
}