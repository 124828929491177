import { useState, useEffect } from "react"
import { RSGet, RSLog, RSText } from "utils/RSUtils"
import { Dialog, ListItem, ListItemIcon, ListItemText, Divider, DialogContent, CircularProgress} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWeixin } from '@fortawesome/free-brands-svg-icons'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import DialogTitleBar from 'contents/common/DialogTitleBar'
import Alert from '@material-ui/lab/Alert'
import { inWechat } from "utils/RSBrowser"
import { useHistory } from "react-router"
import useStyles from "utils/RSStyles"
import { isMobile } from "react-device-detect"

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { CheckoutDialogContent, closeCheckoutDialog } from "redux/CheckoutDialogSlice";
import { openAlertDialog } from "redux/AlertDialogSlice"
import { closeLoadingDialog, openLoadingDialog } from 'redux/LoadingDialogSlice'

const QrcodeDialog = (props) => {
  const { open, setOpen } = props
  const { order } = useSelector(CheckoutDialogContent)
  const text = RSText()
  const classes = useStyles()
  const [qrcode, setQrcode] = useState()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if(open) {
      RSLog('读取微信支付二维码')
      /* swiftpass */
      RSGet('payment/wechatPay/qrcode/'+order.order_id, data => {
        setQrcode(data)
      })

      const interval = setInterval(() => {
        RSLog('检查订单支付状态')
        RSGet('payment/check/'+order.order_id, data => {
          if(data === 'paid') {
            setOpen(false)
            dispatch(closeCheckoutDialog())
            history.push('/payment/success')
          }
        })
      }, 2000);
      return () => clearInterval(interval);
    } else {
      setQrcode(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Dialog open={open} onClose={()=>setOpen(false)} maxWidth="xs" fullWidth>
      <DialogTitleBar title={text.wechatPay} setClose={()=>setOpen(false)} />
      <DialogContent dividers={true}>
        <div align="center">
          {!qrcode  && <CircularProgress color="secondary" />}
          {qrcode && <img src={qrcode} alt="Wechat pay qrcode" />}
          <Alert severity="info" className={classes.mt}>支付成功后请稍等, 页面会自动跳转</Alert>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default function WechatPayListItem() {
  const text = RSText()
  const [open, setOpen] = useState(false)
  const { order } = useSelector(CheckoutDialogContent)
  const history = useHistory()
  const dispatch = useDispatch()

  const handleClick = () => {
    if(inWechat()) {
      dispatch(openLoadingDialog())
      // 微信内置浏览器
      RSGet('payment/wechatPay/oa/'+order.order_id, data => {
        window.WeixinJSBridge.invoke(
          'getBrandWCPayRequest',
          JSON.parse(data),
          function(res){
            if(res.err_msg === "get_brand_wcpay_request:ok" ){
              dispatch(closeCheckoutDialog())
              history.push('/payment/success')
            }else{
              dispatch(closeLoadingDialog())
              window.alert('支付失败')
              // history.push('/payment/fail')
            }
          }
        )
      })
    } else if( isMobile ) {
      // H5支付
      /*
      RSGet('payment/wechatPay/wap/'+order.order_id, data => {
        RSLog(data)
      })
      */
      dispatch(openAlertDialog({msg: text.wechatH5PayWarning, emoji: 'sorry', severity: 'warning'}))
    } else {
      // 桌面浏览器 二维码支付
      setOpen(true)
    }
  }

  return (
    <>
    <QrcodeDialog open={open} setOpen={setOpen} order={order} />
    <ListItem button onClick={handleClick}>
      <ListItemIcon>
        <FontAwesomeIcon icon={faWeixin} size="2x" fixedWidth style={{color: '#07C160'}} />
      </ListItemIcon>
      <ListItemText primary={text.wechatPay} />
      <KeyboardArrowRightIcon />
    </ListItem>
    <Divider />
    </>
  )
}