import { createSlice } from '@reduxjs/toolkit'
import { RSGet } from 'utils/RSUtils'
import { openAlertDialog } from './AlertDialogSlice'

// 订单窗口
export const slice = createSlice({
  name: 'TrackingDialog',
  initialState: {
    show: false,
    trackingNumber: null,
    trackings: null
  },
  reducers: {
    openDialog: (state) => {
      state.show = true
    },
    closeTrackingDialog: (state) => {
      state.show = false
      state.Tracking = null
    },
    setTrackingNumber: (state, action) => {
      state.trackingNumber = action.payload
    },
    setTrackings: (state, action) => {
      state.trackings = action.payload
    }
  },
})

export const { closeTrackingDialog, openDialog, setTrackingNumber, setTrackings } = slice.actions
export const TrackingDialogContent = state => state.TrackingDialog
export default slice.reducer

export const openTrackingDialog = trackingNumber => dispatch => {
  if(trackingNumber) {
    dispatch(openDialog())
    dispatch(setTrackingNumber(trackingNumber))
    RSGet('customer/tracking/'+trackingNumber, data=>{
      data = JSON.parse(data)
      if(data.trackingdetails.trackingdetail.error === undefined){
        var trackingsData = data.trackingdetails.trackingdetail.trackingevents
        dispatch(setTrackings(trackingsData))
      } else {
        dispatch(openAlertDialog({msg: data.trackingdetails.trackingdetail.error, emoji: 'search', severity: 'warning'}))
        dispatch(closeTrackingDialog())
        dispatch(setTrackings(null))
      }
    })
  }
}