export default function LangEN() {
  return {
    brand: 'Red Squirrel Shop',
    slogan: 'Asian food, sauces, and snacks',
    homepage: 'Home',
    shopping: 'Groceries',
    new: 'New',
    sales: 'On sale',
    language: 'English',
    search: 'Search',
    emptyCart: 'Your trolley is empty',
    popular: 'Popular',
    free: 'FREE',
    freeRule1: 'Get it for free when you spend over £',
    freeRule2: '',

    /*********************************************************/
    // Common Btns

    signInBtn: 'Sign in',
    signUpBtn: 'Sign up',
    forgetPasswordBtn: 'Forget password',
    customerServiceBtn: 'Support',
    backToHomeBtn: 'Homepage',
    recoveryBtn: 'Send password reset email',
    closeBtn: 'Close',
    resetPasswordBtn: 'Reset password',

    /*********************************************************/
    // Common Inputs Label

    signInLabel: 'Sign in',
    signUpLabel: 'Sign up',
    emailLabel: 'Email',
    passwordLabel: 'Password',
    confirmPasswordLabel: 'Confirm password',
    signInWithLabel: 'Sign in with',
    recoveryLabel: 'Account recovery',
    recoverySent: 'Email has been sent, please check your inbox.',
    signInSuccess: "Welcome! Let's start shopping.",


    /*********************************************************/
    // Homepage

    feature1: 'Next working day delivery in UK',
    feature2: 'Free delivery when you spend over £60',
    feature3: 'We accept Visa, Mastercard, AMEX',

    featureText1: '',
    featureText2: '',
    featureText3: '',


    /*********************************************************/
    // Auth

    loginFailed: 'Login failed, please try again.',
    loginSuccess: 'Login successful.',
    passwordResetSuccess: 'Password reset successful.',

    // Dialog
    close: 'Close',
    tracking: 'Tracking',
    noTracking: 'No tracking information, please try again later.',

    // Homepage Icons
    menu1: 'Rice Noodles',
    menu2: 'Cooking',
    menu3: 'Fresh Food',
    menu4: 'Snacks',
    menu5: 'Instant Food',
    menu6: 'Hotpot',
    menu7: 'Household',
    menu8: 'Beauty',
    menu8url: 'Beauty',
    menu9: 'New',
    menu10: 'On Sale',
    menu11: 'Popular',
    menu12: 'My Favourites',

    // Auth
    authTitle: 'Sign In',
    signInWithFacebook: 'Sign In With Facebook',
    signInWithGoogle: 'Sign In With Gmail',
    signInWithWechat: 'Sign In With Wechat',

    // Account Menu
    account: 'Account',
    myOrders: 'My orders',
    myFavorites: 'My favourites',
    membership: 'Membership',
    
    signIn: 'Sign in',
    signOut: 'Sign out',
    searchBarPlaceHolder: 'Find a product',

    // Footer
    paymentMethods: 'Payment Methods',
    downloadAPP: 'Download APP',

    // Footer FAQ
    faq: 'FAQ',
    deliveryArea: 'Delivery Area',
    deliveryFee: 'Delivery Fee',
    deliveryTime: 'Delivery Time',
    refundPolicy: 'Refund policy',

    // Footer Help Center
    help: 'Help',
    trackingOrder: 'Tracking Order',
    customerService: 'Live chat',
    privacyPolicy: 'Privacy policy',
    termsAndConditions: 'Terms and conditions',

    // Footer Corporation
    corporation: 'Company',
    aboutUs: 'About us',
    contactUs: 'Contact us',
    joinUs: 'Join us',
    cooperation: 'Cooperation',

    
    // ****************************************************
    // Product
    acUnit: 'Chilled/Frozen',
    madeIn: 'From ',
    category: 'Category',
    productBrand: 'Brand',
    series: 'Series',
    addFavorites: 'Add to favourites',
    productView: 'Product view',
    weight: 'Weight',
    video: 'Video',
    productNotFound: 'Product not found',

    // Order Status
    preparing: 'Packing',
    delivering: 'On the way',
    cancelled: 'Cancelled',
    delivered: 'Delivered',
    onPacking: 'Packing',

    // Order
    frozen: 'Frozen',
    chilled: "Chilled",
    shipping: 'Shipping',
    packing: 'Packing',
    vip_discount: 'VIP Discount',
    voucher_discount: 'Voucher',
    gift: 'Free',

    // Warning
    error404: "Sorry! We couldn't find that page.",
    backToHome: 'Back to home',

    // Cart drawer 
    total: 'Total',
    inCart1: '',
    inCart2: ' item(s) in trolley',
    checkout: 'Checkout',

    soldOutAutoRemove: 'Sold out',
    soldOutRemoveFromCart: 'Auto remove from cart',

    // Checkout
    nextStep: 'Next step',
    shippingAddress: 'Delivery address',
    addNewAddress: 'Add new',
    updateAddress: 'Update',
    deleteAddress: 'Delete',
    addressList: 'Change Address',
    addressFormTitleAdd: 'Add new',
    addressFormTitleUpdate: 'Update',
    addressSurname: 'Surname',
    addressForename: 'Forename',
    addressMobile: 'Mobile',
    addressFind: 'Search',

    // Form 
   
    delConfirm: 'Are you sure to delete?',
    formRequired: 'Required',
    formFormat: 'Invalid format',
    formPasswordNotMatch: 'Password and confirm password does not match',

    // Buttons
    saveBtn: 'Save',
    delBtn: 'Delete',
    backBtn: 'Back',

    // Step 2
    step2: 'Review order',
    cartFee: 'Products in trolley',
    shippingFee: 'Delivery fee',
    packingFee: 'Packing fee',
    vipDiscount: 'VIP discount',
    voucherDiscount: 'Voucher',
    walletSpend: 'Wallet',
    totalAmount: 'Total',
    redeemBtn: 'Redeem',
    freeGift: 'Free gift',
    orderNote: 'note',

    // Step 3
    step3: 'Payment method',
    cardPay: 'Pay by card',
    wechatPay: 'WeChat Pay',
    alipay: 'Alipay',

    // ****************************************************
    // Payment
    stripeTips: 'In the last section, please input the postcode where your bank statement was delivered most recently.',
    confirmingPayments: 'Please wait a minute, we are confirming your payment.',
    paymentSuccessed: 'Payment successed',
    paymentFailed: 'Payment failed',
    wechatH5PayWarning: 'WeChat payment cannot be used in the mobile browser~ Please use it on the desktop or WeChat APP.',

    // ****************************************************
    // Cookie Alert
    cookieBtn: 'Accept',
    cookieAlert: 'This website uses cookies to enhance the user experience. ',
    cookieMore: 'Learn more',

    // ****************************************************
    // My
    trackingBtn: 'Tracking',
    viewBtn: 'View',
    noOrders: 'No orders yet~',

    /*********************************************************/
    // Dialog 到货提醒
    reminderTitle: 'Reminder',
    reminderSave: 'Remind me',
  }
}