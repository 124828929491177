import { createSlice } from '@reduxjs/toolkit'

// 客户基本信息
export const slice = createSlice({
  name: 'Customer',
  initialState: {
    profile: {
      surname: '',
      forename: '',
      email: '',
      picture: 'https://img1.redsquirrel.shop/logo/150x150.png',
      vip: 0,
      vip_expiry_date: null
    },
    profileDialog: false
  },
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload
      state.profile.vip = Number(state.profile.vip)
    },
    openProfileDialog: (state) => {
      state.profileDialog = true
    },
    closeProfileDialog: (state) => {
      state.profileDialog = false
    },
    setCustomerEmail: (state, action) => {
      state.profile.email = action.payload
    },
  },
})

export const { setProfile, openProfileDialog, closeProfileDialog, setCustomerEmail } = slice.actions
export const Customer = state => state.Customer
export const Profile = state => state.Customer.profile
export default slice.reducer