import { Route, Switch } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'

import Homepage from 'contents/homepage/Homepage'
import {ProductPopularPage, ProductOnSalePage, ProductNewArrivalsPage, ProductShelfPage} from 'contents/product/ProductPages'
import ProductView from 'contents/product/ProductView'

import MyPages from 'contents/my/MyPages'
import VIPPage from 'contents/vip/VIPPage'
import InfoPages from 'contents/info/InfoPages'

import Error404Page from 'contents/Error404Page.js'
import WechatRedirectPage from 'contents/auth/WechatRedirectPage.js'
import AlipayRedirectPage from 'contents/auth/AlipayRedirectPage.js'
import EmailPasswordResetPage from 'contents/auth/EmailPasswordResetPage.js'

// Payment
import PaymentCallback from 'contents/checkout/Callback'
import PaymentSuccess from 'contents/checkout/Success'
import PaymentFail from 'contents/checkout/Fail'

// Test
import Test from 'Test'
import DeliveryTime from 'contents/test/DeliveryTime'

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marginTop: theme.spacing(12)
  }
}))

export default function Contents() {
  const classes = useStyles()
  return (
    <div className={classes.pageContent}>
      <Switch>
        <Route exact path="/"><Homepage /></Route>
        
        {/* Auth */}
        <Route exact path="/wechat-signin" children={<WechatRedirectPage />} />
        <Route exact path="/alipay-signin" children={<AlipayRedirectPage />} />
        <Route exact path="/auth/action" children={<EmailPasswordResetPage />} />

        {/* Products */}
        <Route exact path="/shelf/:tag" children={<ProductShelfPage />} />
        <Route exact path="/new-arrivals" children={<ProductNewArrivalsPage />} />
        <Route exact path="/on-sale" children={<ProductOnSalePage />} />
        <Route exact path="/popular" children={<ProductPopularPage />} />
        <Route exact path="/product/:id"><ProductView /></Route>

        {/* Payments */}
        <Route exact path="/payment/success" children={<PaymentSuccess />} />
        <Route exact path="/payment/fail" children={<PaymentFail />} />
        <Route exact path="/payment/callback/:orderID" children={<PaymentCallback />} />

        {/* My */}
        <Route exact path="/my/:path" children={<MyPages />} />
        <Route exact path="/membership"><VIPPage /></Route>

        {/* Info */}
        <Route exact path="/info/:path" children={<InfoPages />} />
        
        <Route exact path="/test"><Test /></Route>
        <Route exact path="/test/delivery-time"><DeliveryTime /></Route>
        <Route><Error404Page /></Route>
      </Switch>
    </div>
  )
}
