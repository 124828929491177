import { useState } from "react"
import { RSGet, RSLog, RSText } from "utils/RSUtils"
import {OrderListItem} from 'dialogs/checkout/step2/StepTwo'
import { makeStyles, ListItem, Typography, Divider, Button,InputBase } from '@material-ui/core'

import { useSelector, useDispatch } from 'react-redux'
import { CheckoutDialogContent, setOrder } from 'redux/CheckoutDialogSlice'

const useStyles = makeStyles((theme) => ({
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}))

export default function VoucherListItem() {
  const classes = useStyles()
  const text = RSText()
  const { order } = useSelector(CheckoutDialogContent)
  const [voucher, setVoucher] = useState('')
  const [tips, setTips] = useState('')
  const dispatch = useDispatch()

  const handleChange = (e) => {
    setVoucher(e.target.value.trim())
  }

  // 兑换优惠码
  const handleClick = () => {
    if(voucher) {
      RSLog('优惠码兑换')
      RSGet('voucher/redeem/'+order.order_id+'/'+voucher, data => {
        if(data) {
          RSLog(data)
          // 未满足条件提示
          if(data.tips) setTips(data.tips)
          // 满足条件 数据补到order里
          if(data.code) {
            dispatch(
              setOrder({
                ...order,
                code: data.code,
                voucher_discount: data.voucher_discount,
                total_amount: data.total_amount
              })
            )
            
          }
        } 
      })
    }
  }

  return (
    <>
  
      { order.code && 
      <OrderListItem primary={text.voucherDiscount} secondary={order.code} amount={order.voucher_discount} minus />
      }

      { !order.code && 
      <>
      <ListItem>
        <InputBase
          className={classes.input}
          placeholder={text.voucherDiscount}
          value={voucher}
          onChange={handleChange}
        />
        <Button onClick={handleClick} variant="contained" size="small" color="secondary">
          {text.redeemBtn}
        </Button>
      </ListItem>
        {tips !== '' &&
        <ListItem>
          <Typography variant="body2" color="secondary">
            {tips}
          </Typography>
        </ListItem>
        }
        <Divider />
        </>
      }

    </>
  )
}