import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLanguage, RSPost } from 'utils/RSUtils'

import { DialogContent, Dialog, Grid, Button, DialogActions } from '@material-ui/core'



import {TextInput, EmailInput, EmailValidation } from 'contents/common/FormInputs'
import useStyles from 'utils/RSStyles';
import DialogTitleBar from 'contents/common/DialogTitleBar'
import { closeProfileDialog, Customer, setProfile } from 'redux/CustomerSlice';

export default function CustomerProfileDialog(props) {
  const dispatch = useDispatch()
  const text = pageText()
  const classes = useStyles()
  const { profile, profileDialog } = useSelector(Customer)
  const { onSave } = props
  const [saveBtnDisable, setSaveBtnDisable] = useState(false)

  const handleClose = () => {
    dispatch(closeProfileDialog())
  }

  const handleInput = (e) => {
    dispatch(setProfile({
      ...profile,
      [`${e.target.name}`]: e.target.value
    }))
  }

  const handleSave = () => {
    RSPost('customer/updateProfile', {profile: profile}, data=>{
      if(onSave) onSave()
      dispatch(closeProfileDialog())
    })
    
  }

  // 表单验证
  useEffect(()=>{
    if(profile) {
      if(profile.surname === '' || profile.forename === '' || profile.email === '') {
        setSaveBtnDisable(true)
      } else {
        if(EmailValidation(profile.email) !== '') {
          setSaveBtnDisable(true)
        } else {
          setSaveBtnDisable(false)
        }
      }
    }
  },[profile])

  return (
    <>
    { profile && 
    <Dialog open={profileDialog} onClose={handleClose} maxWidth="xs">
      <DialogTitleBar title={text.customer_info} setClose={handleClose} />
      <DialogContent dividers>
        <Grid container spacing={3}>

          <Grid item xs={6}>
            <TextInput
              label={text.surname}
              name="surname" 
              value={profile.surname}
              onChange={handleInput}
            />
          </Grid>

          <Grid item xs={6}>
            <TextInput
              label={text.forename}
              name="forename" 
              value={profile.forename}
              onChange={handleInput}
            />
          </Grid>

          <Grid item xs={12}>
            <EmailInput 
              label="Email"
              name="email" 
              value={profile.email}
              onChange={handleInput}
              required
            />
          </Grid>

        </Grid>
      </DialogContent>

      <DialogActions>
          <Button 
            color="secondary" 
            className={classes.saveBtn} 
            size="small" 
            variant="contained" 
            onClick={handleSave} 
            disabled={saveBtnDisable}
          >
            {text.saveBtn}
          </Button>
     
        </DialogActions>
    </Dialog>
    }
    </>
  )
}

function pageText() {
  const lang = getLanguage()
  if(lang === 'cn') return cn
  else return en
}

const cn = {
  customer_info: '我的信息',
  surname:  '姓',
  forename: '名',
  saveBtn: '保存',
}

const en = {
  customer_info: 'Profile',
  surname:  'Surname',
  forename: 'Forename',
  saveBtn: 'Save',
}