export default function RSEmoji(props) {

  const {name, width, className} = props
  const w = width?width:"200"

  return (
    <div align="center" className={className}>
      <img src={`/images/rs-${name}.png`} width={w} alt={name} />
    </div>
  )
}