
import { makeStyles, Grid, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { RSText } from 'utils/RSUtils'
import { RSURIEncode } from 'utils/RSUtils'

const useStyles = makeStyles((theme)=>({
  icons:{
    marginTop: theme.spacing(1),
  },
  icon: {
    marginBottom: theme.spacing(1),
    '& > a': {
      textDecoration: 'none',
      color: 'inherit'
    }
  }
}))

export default function Icons() {
  const classes = useStyles()
  const text = RSText()
  return (
    <Grid container spacing={1} className={classes.icons}>
      <IconItem position={1} text={text.menu1} to={'shelf/'+RSURIEncode(text.menu1)} />
      <IconItem position={2} text={text.menu2} to={'shelf/'+RSURIEncode(text.menu2)} />
      <IconItem position={3} text={text.menu3} to={'shelf/'+RSURIEncode(text.menu3)} />
      <IconItem position={4} text={text.menu4} to={'shelf/'+RSURIEncode(text.menu4)} />
      <IconItem position={5} text={text.menu5} to={'shelf/'+RSURIEncode(text.menu5)} />
      <IconItem position={6} text={text.menu6} to={'shelf/'+RSURIEncode(text.menu6)} />
      <IconItem position={7} text={text.menu7} to={'shelf/'+RSURIEncode(text.menu7)} />
      <IconItem position={8} text={text.menu8} to={'shelf/'+RSURIEncode(text.menu8url)} />
      <IconItem position={9} text={text.menu9} to={'new-arrivals'} />
      <IconItem position={10} text={text.menu10} to={'on-sale'} />
      <IconItem position={11} text={text.menu11} to={'popular'} />
      <IconItem position={12} text={text.menu12} to={'my/favourites'} />
    </Grid>
  )
}

function IconItem(props) {
  const { position, text, to } = props
  const classes = useStyles()
  return (
    <Grid item xs={3} className={classes.icon}>
      <Link to={'/'+to}>
      <Typography align="center" variant="body2">
        <img src={`/images/menu-${position}.png`} alt="menu icon" width="50%" /><br/>
        {text}
      </Typography>
      </Link>
    </Grid>
  )
}