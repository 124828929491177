import { useEffect, useState } from 'react'
import { Dialog, DialogContent } from "@material-ui/core"
import Details from 'contents/product/ProductDetails'
import {makeStyles} from '@material-ui/core'
import { RSText, RSGet } from 'utils/RSUtils'
import DialogTitleBar from 'contents/common/DialogTitleBar'
// import ReactGA from 'react-ga'

import { useSelector, useDispatch } from 'react-redux'
import { closeProductDialog, productDialogContent } from 'redux/ProductDialogSlice'

const useStyles = makeStyles((theme) => ({
  contentBg: {
    background: '#f5f5f5 !important',
    padding: 16
  }
}))

export default function ProductDialog() {
  const [product, setProduct] = useState()
  const classes = useStyles()
  const text = RSText()
  const dispacth = useDispatch()
  const productDialog = useSelector(productDialogContent)

  useEffect(()=>{
    if(productDialog.show && productDialog.id) {
      RSGet('product/view/'+productDialog.id, data => {
        setProduct(data)
        // ReactGA.set({title: data.name})
        // ReactGA.modalview('/product/'+data.name)
      })
    } else {
      setProduct(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDialog])

  const closeDialog = () => {
    dispacth(closeProductDialog())
  }


  return (
    <>
    <Dialog 
      disableEnforceFocus
      fullWidth={true}
      maxWidth='xs'
      scroll='body'
      open={productDialog.show}
      onClose={closeDialog} 
      style={{overflowY:'none'}}
    >
      { product && 
      <>
      <DialogTitleBar title={text.productView} setClose={closeDialog} productID={productDialog.id} />

      <DialogContent dividers={true} className={classes.contentBg}>
        <Details product={product} />
      </DialogContent>
      </>
      }
    </Dialog>
    </>
  )
}