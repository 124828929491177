import { useState } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, Hidden, Button, Menu, MenuItem, Divider, IconButton, Avatar, Badge} from '@material-ui/core'
import { RSText, signout } from 'utils/RSUtils'
import AuthHeaderBtn from 'contents/auth/AuthHeaderBtn'
import { useSelector, useDispatch } from 'react-redux'
import { signState } from 'redux/SignStateSlice'
import { Profile } from 'redux/CustomerSlice'
import { openChatDialog } from 'redux/ChatDialogSlice'
import { openLiveChat } from 'components/livechat/LiveChat'

const useStyles = makeStyles((theme) => ({
  mr2: {
    marginRight: theme.spacing(2)
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  orange: {
    width: 5,
    height: 5,
    padding: 5,
    border: '1px solid currentColor',
    fontSize: 9,
    color: '#fff',
    backgroundColor: '#f50057',
  },
}))

// Auth Button
export default function AuthBtn() {
  const signed = useSelector(signState)
  return (
    <>
    { signed?<AccountMenu />:<AuthHeaderBtn /> }
    </>
  )
}

// Customer Menu
function AccountMenu() {
  const [anchorEl, setAnchorEl] = useState(null)
  const text = RSText()
  const dispatch = useDispatch()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleOpenChat = () => {
    openLiveChat()
    handleClose()
  }

  const logout = () => {
    signout()
  }

  return (
    <>
      <Hidden mdDown>
        <Button 
          aria-controls="customer-menu" 
          aria-haspopup="true" 
          onClick={handleClick}
          startIcon={<CustomerAvatar />}
        >
          {text.account}
        </Button>
      </Hidden>

      <Hidden lgUp>
        <IconButton onClick={handleClick}>
          <CustomerAvatar />
        </IconButton> 
      </Hidden>

      <Menu
        id="customer-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem component={Link} to='/my/orders' onClick={handleClose}>{text.myOrders}</MenuItem>
        <MenuItem component={Link} to='/my/favourites' onClick={handleClose}>{text.myFavorites}</MenuItem>
        <MenuItem component={Link} to='/membership' onClick={handleClose}>{text.membership}</MenuItem>
        <MenuItem onClick={handleOpenChat}>{text.customerServiceBtn}</MenuItem>
        <Divider />
        <MenuItem onClick={logout}>{text.signOut}</MenuItem>    
      </Menu>
    </>
  )
}


function CustomerAvatar() {
  const customer = useSelector(Profile)
  const classes = useStyles()

  return (
    <>
    {customer.vip > 0 && 
    <Badge badgeContent={<Avatar className={classes.orange}>V</Avatar>}>
      <Avatar src={customer.photo} className={classes.avatar} />
    </Badge>
    }
    {!customer.vip  &&
      <Avatar src={customer.photo} className={classes.avatar} />
    }
    </>
  )
}