import { Container, Grid, Hidden, IconButton } from '@material-ui/core'
import PageTitle from 'contents/common/PageTitle'
import Catetory from 'contents/product/components/Category'
import GridList from 'contents/product/components/GridList'
import ListIcon from '@material-ui/icons/List';

import { useDispatch,  } from 'react-redux'
import { openCategoryDrawer } from 'redux/CategoryDrawerSlice'

export default function ProductGridViewPage(props) {
  const {title, tag, api} = props
  return (
    <Container maxWidth="xl">

      <PageTitle title={title} />

      <Hidden mdUp>
        <MobileCategoryBtn />
      </Hidden>
      
      <Grid container spacing={1}>

        <Hidden smDown>
          <Grid item xl={1} md={2}>
            <Catetory tag={tag} />
          </Grid>
        </Hidden>

        <Grid item xl={11} md={10}>
          <GridList tag={tag} api={api} />
        </Grid>

      </Grid>
    </Container>
  )
}

function MobileCategoryBtn() {

  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(openCategoryDrawer())
  }

  return (
    <IconButton
      onClick={handleClick}
      style={{position:'fixed', top:'50%', zIndex:'999', left:'5px', background:'#fff', boxShadow: '0 3px 10px 0 rgba(0,0,0,0.15)'}}
    >
      <ListIcon />
    </IconButton>
  )
}