import { useEffect } from "react"
import { Hidden, Typography, Dialog, DialogContent,
  Table, TableHead, TableRow, TableCell, TableBody, Link } from "@material-ui/core"
import { AcUnitOutlined } from '@material-ui/icons'
import { RSGet, RSText } from 'utils/RSUtils'
import DialogTitleBar from "contents/common/DialogTitleBar"

import { useSelector, useDispatch } from "react-redux"
import { closeOrderDialog, OrderDialogContent, setOrder } from "redux/OrderDialogSlice"

export default function OrderDialog() {
  const text = RSText()
  const dispatch = useDispatch()

  const { show, order, orderID } = useSelector(OrderDialogContent)

  useEffect(()=>{
    if(orderID) {
      RSGet('customer/order/'+orderID, data => {
        dispatch(setOrder(data))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderID])

  const handleClose = () => {
    dispatch(closeOrderDialog())
  }

  return (
  <Dialog open={show} onClose={handleClose}>
    { order && 
    <>
    <DialogTitleBar title={order.order_id} setClose={handleClose} />
    <DialogContent dividers={true}>

      
      <Table size="small">
        <TableHead>
          <ItemHead />
        </TableHead>

        <TableBody>

          {order.items.map((item, index) => 
            item.frozen === '0' && <ItemRow item={item} key={index} />
          )}

          { order.frozen === "1" && 
          <TableRow>
            <TableCell></TableCell>
            <TableCell colSpan={2}><b>{text.frozen}</b></TableCell>
          </TableRow>
          }


          { order.frozen === "1" &&  order.items.map((item, index) => 
            item.frozen === '1' && <ItemRow item={item} key={index} />
          )}
        
          <Amount order={order} />

          <TableRow>
            <TableCell colSpan={2}>
            {order.name} <br/>
            {order.phone} <br/>
            {order.email} <br/>
            {order.address_1}
            {order.address_2 && 
              <>, {order.address_2}</>
            } 
            {order.address_3 && 
              <>, {order.address_3}</>
            } 
            <br/>
            {order.city}
            {order.county && 
              <>,{order.county}</>
            } 
            <br/>
            {order.postcode}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      

    </DialogContent>
    </>
    }
  </Dialog>
  )
}

//******************************************************//
// 订单 项目 Table Rows
function ItemHead() {
  return (
  <TableRow>
    <Hidden mdDown><TableCell></TableCell></Hidden>
    <TableCell>Item</TableCell>
    <TableCell>Total</TableCell>
  </TableRow>
  )
}

function ItemRow(props) {
  const { item } = props 
  const text = RSText()
  const dispatch = useDispatch()

  const openProductDialog = (productID) => {
    dispatch(openProductDialog(productID))
  }

  return (
  <TableRow>
    <Hidden mdDown><TableCell><img src={item.img} alt={item.name} width="40" /></TableCell></Hidden>
    <TableCell>
      <Link onClick={()=>openProductDialog(item.product_id)} href="#" color="inherit">
      {item.frozen === "1" && <AcUnitOutlined fontSize="small" color="primary" />}#{item.product_id} {item.name} x {item.qty}<br/>
      {item.name_en}
      </Link>
    </TableCell>
    <TableCell>
      {item.price !== "0.00" && '£'+(item.price * item.qty).toFixed(2)}
      {item.price === "0.00" && <Typography color="secondary" variant="body2">{text.gift}</Typography>}
    </TableCell>
  </TableRow>
  )
}


function Amount(props) {
  const {order} = props
  const text = RSText()
  return (
    <>
    { order.pack_fee !== "0.00" && 
      <FeeRow item={text.packing} amount={order.pack_fee} />
    }

    { order.shipping_fee !== "0.00" && 
      <FeeRow item={text.shipping} amount={order.shipping_fee} />
    }

    { order.vip_discount !== "0.00" && 
      <VoucherRow item={text.vip_discount} amount={order.vip_discount} />
    }

    { order.gift_fee !== "0.00" && 
      <VoucherRow item={text.voucher_discount} note={order.code} amount={order.gift_fee} />
    }

    <TotalRow item="Total(inc. VAT)" amount={order.amount} />

    { order.standard_rate !== "0.00" && 
      <TotalRow item={"VAT@20%"} amount={order.standard_rate} />
    }

    { order.reduced_rate !== "0.00" && 
      <TotalRow item={"VAT@5%"} amount={order.reduced_rate} />
    }

    { order.zero_rate !== "0.00" && 
      <TotalRow item={"VAT@0%"} amount={order.zero_rate} />
    }
  
  </>
  )
}


function FeeRow(props) {
  const { item, amount } = props 
  return (
  <TableRow>
    <Hidden mdDown><TableCell></TableCell></Hidden>
    <TableCell><b>{item}</b></TableCell>
    <TableCell>£{amount}</TableCell>
  </TableRow>
  )
}

function VoucherRow(props) {
  const { item, note, amount } = props 
  return (
  <TableRow>
    <Hidden mdDown><TableCell></TableCell></Hidden>
    <TableCell><b>{item}</b> {note}</TableCell>
    <TableCell><Typography color="secondary" variant="body2">-£{amount}</Typography></TableCell>
  </TableRow>
  )
}

function TotalRow(props) {
  const { item, amount } = props 
  return (
  <TableRow>
    <Hidden mdDown><TableCell></TableCell></Hidden>
    <TableCell><b>{item}</b></TableCell>
    <TableCell>£{amount}</TableCell>
  </TableRow>
  )
}