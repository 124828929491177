// livechat.com
// https://github.com/livechat/chat-widget-adapters
// 客服组件

import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'Livechat',
  initialState: {
    license: "15150534",
    visibility: 'hidden', // "maximized" | "minimized" | "hidden"
    customerName: '',
    customerEmail: '',
  },
  reducers: {
    showChat: (state) => {
      state.visibility = "minimized"
    },
    openChat: (state) => {
      state.visibility = "maximized"
    },
    hideChat: (state) => {
      state.visibility = "hidden"
    }
  },
})

export const { openChat, showChat, hideChat } = slice.actions
export const LivechatStates = state => state.Livechat
export default slice.reducer