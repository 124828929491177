import { Button} from "@material-ui/core"
import { useRef, useState } from "react"
import { RSLog, RSText, setToken, Post } from "utils/RSUtils"
import firebase from "firebase/app"
import { Alert } from "@material-ui/lab"
import { EmailTextField, PasswordTextField } from 'contents/common/FormInputs'
import useStyles from "utils/RSStyles"

import { useDispatch } from 'react-redux'
import { signIn } from 'redux/SignStateSlice'
import { openAlertDialog } from "redux/AlertDialogSlice"
import { closeAuthDialog } from "redux/AuthDialogSlice"
import { openEmailForgetDialog } from "redux/EmailForgetDialogSlice"

export default function SignInForm() {
  const classes = useStyles()
  const text = RSText()
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [err, setErr] = useState()

  const emailRef = useRef()
  const passwordRef = useRef()


  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
    setErr(null)
  }

  const handleChangePassword = (e) => {
    setPassword(e.target.value)
    setErr(null)
  }

  const handleSignIn = () => {
    if(!emailRef.current.validation() && !passwordRef.current.validation()) return false
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        
        
        firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
          Post('auth/exchangeToken', {token: idToken}, data => {
            if(data) {
              RSLog('Email 登录成功')
              // RSLog('交换RSToken: '+data)
              setToken(data)
              dispatch(signIn())
              dispatch(openAlertDialog({msg: text.signInSuccess, emoji: 'cart-right'}))
              dispatch(closeAuthDialog())
            }
          })
        }).catch(function(error) {
          RSLog(error.message)
        });

      })
      .catch((error) => {
        setErr(error.message)
      });
  }

  const handleRecovery = () => {
    dispatch(openEmailForgetDialog())
  }

  return (
    <div className={classes.authForm}>
    
      <div>
        <EmailTextField 
          value={email}
          onChange={handleChangeEmail}
          ref={emailRef}
        />
      </div>
    
      <div>
        <PasswordTextField 
          value={password} 
          onChange={handleChangePassword} 
          ref={passwordRef}
        />
      </div>

      {err && <div><Alert severity="error">{err}</Alert></div>}

      <div><Button variant="contained" color="secondary" fullWidth onClick={handleSignIn}>{text.signInBtn}</Button></div>

      <div align="center"><Button variant="text" onClick={handleRecovery}>{text.forgetPasswordBtn}</Button></div>
     
    </div>
  )
}
