import PageTitle from 'contents/common/PageTitle'
import { getLanguage, GetMD, RSLink, RSText } from 'utils/RSUtils'
import 'github-markdown-css'

import { Accordion, AccordionSummary, AccordionDetails, Typography, Paper } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Privacy() {
  const text = RSText()
  const lang = getLanguage()
  return (
    <>
    <PageTitle title={text.privacyPolicy} />
    { lang === 'cn' && 
      GetMD(`/mds${RSLink('privacy-policy.md')}`) 
    }

    { lang === 'en' && 
    <>
    <Paper>
      <div style={{padding:20, marginBottom:10}}>
      {GetMD('/mds/en/privacy-policy/Customer-Privacy-and-Cookie-Policy.md')}
      </div>
    </Paper>

    <div>
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Who we are and what we collect</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {GetMD('/mds/en/privacy-policy/who-we-are-and-what-we-collect.md')}
      </AccordionDetails>
    </Accordion>

    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">How and why we use your personal information</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {GetMD('/mds/en/privacy-policy/how-and-why-we-use-your-personal-information.md')}
      </AccordionDetails>
    </Accordion>

    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">When we share your personal information</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {GetMD('/mds/en/privacy-policy/when-we-share-your-personal-information.md')}
      </AccordionDetails>
    </Accordion>

    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Security, data retention and international data transfers</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {GetMD('/mds/en/privacy-policy/security-data-retention-and-international-data-transfers.md')}
      </AccordionDetails>
    </Accordion>

    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Your choices and rights</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {GetMD('/mds/en/privacy-policy/your-choices-and-rights.md')}
      </AccordionDetails>
    </Accordion>

    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Cookies and similar technologies</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {GetMD('/mds/en/privacy-policy/cookies-and-similar-technologies.md')}
      </AccordionDetails>
    </Accordion>

    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Updates and how to contact us</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {GetMD('/mds/en/privacy-policy/updates-and-how-to-contact-us.md')}
      </AccordionDetails>
    </Accordion>
    </div>
    </>
    }

    </>
  )
}