import { Typography } from '@material-ui/core'

export default function CopyRights() {
  var d = new Date()
  const year = d.getFullYear()
  return (
    <Typography variant="caption" align="center" component="p">© 2017-{year} Red Squirrel Shop Ltd. All rights reserved.</Typography>
  )
}

