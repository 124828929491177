import { makeStyles, Grid, Card, ListItem, ListItemText, Avatar, Hidden, Menu, Container,Typography } from '@material-ui/core'
import { getLanguage, RSText } from 'utils/RSUtils'
import {Link} from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import { useState } from 'react'
import { Stack } from '@mui/material'

const useStyles = makeStyles((theme)=>({
  avatar:{
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginRight: theme.spacing(2)
  },
  section:{
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}))

export default function Features() {
  const text = RSText()
  const lang = getLanguage()
  const classes = useStyles()
  return (
    <Container maxWidth="xl" className={classes.section}>
    <Hidden smDown>
      
        {/*
         <Grid item>
          <Card><IOSAppDownload /></Card>
        </Grid>
        */}
       <Stack spacing={1}>
   
          <Card><FeatureItem img="about-3.png" primary={text.feature1} secondary={text.featureText1} /></Card>
   
     
          <Card><FeatureItem img="about-2.png" primary={text.feature2} secondary={text.featureText2} /></Card>
       
       
          <Card><FeatureItem img="about-4.png" primary={text.feature3} secondary={text.featureText3} /></Card>
      

        { lang === 'cn' && 
   
          <Card><WechatItem /></Card>
   
        }
</Stack>
      
    </Hidden>

    <Hidden lgUp>
      <Swiper
        spaceBetween={0}
        slidesPerView={0}
        loop={true}
        autoplay
      >
        <SwiperSlide key={0} >
          <IOSAppDownload />
        </SwiperSlide>

        <SwiperSlide key={1} >
          <FeatureItem img="about-3.png" primary={text.feature1} secondary={text.featureText1} />
        </SwiperSlide>

        <SwiperSlide key={2} >
          <FeatureItem img="about-2.png" primary={text.feature2} secondary={text.featureText2} />
        </SwiperSlide>

        <SwiperSlide key={3} >
          <FeatureItem img="about-4.png" primary={text.feature3} secondary={text.featureText3} />
        </SwiperSlide>

        { lang === 'cn' && 
        <SwiperSlide key={4} >
          <WechatItem />
        </SwiperSlide>
        }
        
      </Swiper>
    </Hidden>
    </Container>
  )
}


function FeatureItem(props) {
  const classes = useStyles()
  const { img, primary, secondary } = props
  return (
    <ListItem button component={Link} to="/info/faq">
      <Avatar src={'/images/'+img} variant="square" className={classes.avatar} />
      <ListItemText primary={primary} secondary={secondary} />
    </ListItem>
  )
}

function IOSAppDownload() {
  const classes = useStyles()
  const url = 'https://apps.apple.com/app/apple-store/id1597489673?pt=123793013&ct=Red%20Squirrel%20Shop%20Website&mt=8'
  const handleClick = () => {
    window.open(url, "_blank")
  }
  return (
    <ListItem button 
      onClick={handleClick}
    >
      <Avatar src={'/images/auth/apple.png'} variant="square" className={classes.avatar} />
      <ListItemText primary="苹果手机APP下载" secondary="Download on the App Store" />
    </ListItem>
  )
}

function WechatItem() {
  const classes = useStyles()
  const [open, setOpen] = useState(null)

  const handleClick = (event) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }


  return (
    <>
    <ListItem button 
      onClick={handleClick}
      aria-controls="wc-qrcode" 
      aria-haspopup="true" 
    >
      <Avatar src={'/images/auth/wechat.png'} variant="square" className={classes.avatar} />
      <ListItemText primary="微信客服" secondary="红松鼠微信号,公众号" />
    </ListItem>

    <Menu 
      id="wc-qrcode"
      anchorEl={open}
      keepMounted
      open={Boolean(open)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Grid container>
        <Grid item>
        <div>
          <img src="/images/sns/wechat_p_qrcode.png" alt="Wechat QRCode" width="100" />
          <Typography variant="caption" component="p" align="center">微信客服号</Typography>
        </div>
        </Grid>
        <Grid item>
        <div>
          <img src="/images/sns/wechat_oa_qrcode.jpg" alt="Wechat OA QRCode" width="100" />
          <Typography variant="caption" component="p" align="center">微信公众号</Typography>
        </div>
        </Grid>
      </Grid>
    </Menu>
    </>
  )
}