import { createSlice } from '@reduxjs/toolkit'
import { RSGet, RSLog } from 'utils/RSUtils'

// Checkout 状态
export const slice = createSlice({
  name: 'CheckoutDialog',
  initialState: {
    show: false,
    step: 0,
    order: {},
    address: null,
    addresses: [], // step one
    addressFormShow: false,
    addressFormData: {},
    addressesShow: false
  },
  reducers: {
    openCheckoutDialog: (state) => {
      state.show = true
      state.step = 0
    },
    closeCheckoutDialog: (state) => {
      state.show = false
    },
    setCheckoutStep: (state, action) => {
      state.step = action.payload
    },
    showAddressForm: (state) => {
      state.addressFormShow = true
    },
    hideAddressForm: (state) => {
      state.addressFormShow = false
    },
    setAddressFormData: (state, action) => {
      const address = action.payload
      if(address) {
        state.addressFormData = address
      } else {
        state.addressFormData = {
          surname: '',
          forename: '',
          email: '',
          tel: '',
          postcode: '',
          address_1: '',
          address_2: '',
          address_3: '',
          city: '',
          county: '',
          instructions: ''
        }
      }
    },
    showAddresses: (state) => {
      state.addressesShow = true
    },
    hideAddresses: (state) => {
      state.addressesShow = false
    },
    setAddress: (state, action) => {
      state.address = action.payload
    },
    setAddresses: (state, action) => {
      state.addresses = action.payload
    },
    setOrder: (state, action) => {
      state.order = action.payload
    },
    nextStep: (state) => {
      state.step++
    },
    prevStep: (state) => {
      state.step--
    },
  },
})

export const { openCheckoutDialog, closeCheckoutDialog, 
  setAddress, 
  setAddresses,
  showAddressForm,
  hideAddressForm,
  setAddressFormData,
  showAddresses,
  hideAddresses,
  setOrder,
  nextStep,
  prevStep
} = slice.actions
export const CheckoutDialogContent = state => state.CheckoutDialog
export default slice.reducer


// 获得地址列表和默认地址
export const getAddresses = () => dispatch => {
  RSLog('刷新地址列表')
  RSGet('address/list', data => {  
    dispatch(setAddresses(data))

    RSLog('设置默认地址')
    if(data.length) {
      data.forEach(function(item) {
        if(item.active) {
          dispatch(setAddress(item))
        }
      })
    } else {
      RSLog('没有地址可选')
      dispatch(setAddress(null))
    }
  })
}
