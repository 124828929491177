import {makeStyles} from '@material-ui/core'
import { useEffect, useState } from "react"
import { IconButton, Button } from "@material-ui/core"
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined'
import ReminderBtn from './ReminderBtn'
import { useDispatch, useSelector } from 'react-redux'
import { signState } from 'redux/SignStateSlice'
import { openAuthDialog } from "redux/AuthDialogSlice"
import { addCart, cartContent, removeCart } from 'redux/CartSlice'
import { RSText } from 'utils/RSUtils'

const useStyles = makeStyles((theme) => ({
  minusBtn: {
    background: '#eee !important',
  },
  addBtn:{
    background: '#f50057 !important',
    color: '#fff'
  },
  disabledBtn:{
    color: '#000 !important'
  }
}))

export default function BuyBtns(props) {
  const {product} = props
  const signed = useSelector(signState)
  const classes = useStyles()
  const [cartQty, setCartQty] = useState(0)
  const dispatch = useDispatch()
  const cart = useSelector(cartContent)
  const text = RSText()

  useEffect(()=> {
    bought()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=> {
    bought()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  const bought = () => {
    let qty = 0
    cart.forEach((cartItem, index) => {
      if(cartItem.id === product.id) {
        qty = cartItem.qty
      }
    })
    setCartQty(qty)
  }

  const add = (product) => {
    if(signed) {
      dispatch(addCart(product))
    } else {
      dispatch(openAuthDialog())
    }
  }

  const remove = (product) => {
    if(signed) {
      dispatch(removeCart({product: product}))
    } else {
      dispatch(openAuthDialog())
    }
  }

  return (    
    <>
    
    {
      parseInt(product.stock) <= 0  &&
      <>
      <Button
        className={classes.disabledBtn}
        disabled
        variant="outlined"
        style={{marginRight:5}}
        size="small"
      >
        {text.soldOutAutoRemove}
      </Button>
      <ReminderBtn product={product} />
      </>
    }

    
    {
    // remove a item from cart
    }

    {
      parseInt(product.stock) > 0 &&
      <>
      <IconButton 
        className={classes.minusBtn}
        size="small"
        disabled={cartQty?false:true}
        onClick={()=>remove(product)}
      >
        <RemoveOutlinedIcon />
      </IconButton>

      {
      // Item qty in cart
      }
      <Button
        className={cartQty?classes.disabledBtn:''}
        disabled
        size="small"
        startIcon={<ShoppingCartOutlinedIcon />}
      >
        {cartQty?cartQty:''}
      </Button>

      {
      // Button add a item into cart
      }
      <IconButton 
        className={classes.addBtn}
        disabled={cartQty<product.stock?false:true}
        onClick={()=>add(product)}
        size="small"
      >
        <AddOutlinedIcon />
      </IconButton>
      </>
    }
    </>
  )
}