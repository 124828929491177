import firebase from "firebase/app"
import { Avatar, IconButton } from "@material-ui/core"
import { RSLog, RSText, setToken, Post } from "utils/RSUtils"
import useStyles from "utils/RSStyles"

import { useDispatch } from 'react-redux'
import { signIn } from 'redux/SignStateSlice'
import { openAlertDialog } from "redux/AlertDialogSlice"
import { closeAuthDialog } from "redux/AuthDialogSlice"

export default function GmailLoginBtn() {
  const classes = useStyles()
  const text = RSText()
  const dispatch = useDispatch()

  const handleClick = () => {
    const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
    firebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then((result)=> {

        firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
          Post('auth/exchangeToken', {token: idToken}, data => {
            if(data) {
              RSLog("Gmail 登录成功")
              // RSLog('交换RSToken: '+data)
              setToken(data)
              dispatch(signIn())
              dispatch(openAlertDialog({msg: text.signInSuccess, emoji: 'cart-right'}))
              dispatch(closeAuthDialog())
            }
          })
        }).catch(function(error) {
          RSLog(error.message)
        });
       
      })
      .catch((exception) => { RSLog(exception.message) })
  }

  return (
    <IconButton 
      size="small" 
      className={classes.mtb}
      onClick={handleClick}
    >
      <Avatar 
        alt="Gmail login button" 
        src="/images/auth/google.png" 
        className={classes.authIcon} 
      />
    </IconButton>
  )
}