import { createSlice } from '@reduxjs/toolkit'
import { RSGet } from 'utils/RSUtils'

// 订单窗口
export const slice = createSlice({
  name: 'OrderDialog',
  initialState: {
    show: false,
    order: null,
  },
  reducers: {
    openDialog: (state) => {
      state.show = true
    },
    closeOrderDialog: (state) => {
      state.show = false
      state.order = null
    },
    setOrder: (state, action) => {
      state.order = action.payload
    }
  },
})

export const { openDialog, closeOrderDialog, setOrder } = slice.actions
export const OrderDialogContent = state => state.OrderDialog
export default slice.reducer

export const openOrderDialog = orderID => dispatch => {
  if(orderID) {
    RSGet('customer/order/'+orderID, data => {
      dispatch(setOrder(data))
      dispatch(openDialog())
    })
  }
}