import { Container, Grid, Button } from "@material-ui/core"
import RSEmoji from "contents/common/RSEmoji"
import { RSText } from "utils/RSUtils"
import useStyles from "utils/RSStyles"
import { useDispatch } from "react-redux"
import { openAuthDialog } from "redux/AuthDialogSlice"


export default function AuthPage() {
  
  const classes = useStyles()
  const text = RSText()
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(openAuthDialog())
  }

  return(
    <Container maxWidth="xs" className={classes.content}>
      <Grid container spacing={3} justify="center">
        <Grid item xs={6}>
          <RSEmoji name='cart-right' className={classes.mb2} />
          <Button variant="contained" color="secondary" fullWidth onClick={handleClick}>{text.signInBtn}</Button>
        </Grid>
      </Grid>
    </Container>
  )
}