import Image from 'material-ui-image'
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Img(props) {
  const {src, oss, onClick, aspectRatio} = props

  return (
    <Image
      loading={<CircularProgress color="secondary" />}
      src={src+(oss?"?x-oss-process=style/"+oss:'')}
      onClick={onClick}
      aspectRatio={aspectRatio}
    />
  )
}