import { RSText } from 'utils/RSUtils'
import ProductGridViewPage from 'contents/product/ProductGridViewPage'

export default function MyFavoritesPage() {
  const text = RSText()
  return (
    <ProductGridViewPage
      title={text.myFavorites}
      tag={text.myFavorites}
      api={'customer/favorites'}
    />
  )
}