import { getLanguage, RSGet } from 'utils/RSUtils'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

// UI
import { Container, Grid, Avatar, Typography, FormControlLabel, Button, Card, CardContent, Checkbox, Badge, Divider } from '@material-ui/core'
import PageTitle, { MainTitle } from 'contents/common/PageTitle'
import useStyles from 'utils/RSStyles'

// Icons
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

// Dialogs
import CustomerProfileDialog from 'contents/customer/CustomerProfileDialog';

// Redux
import { openPaymentDialog, setPaymentDialogOrderID } from 'redux/PaymentDialogSlice';
import { signState } from 'redux/SignStateSlice'
import { openAuthDialog } from 'redux/AuthDialogSlice';
import { Customer, openProfileDialog } from 'redux/CustomerSlice'

import moment from "moment"
require('moment/locale/en-gb')

export default function VIPPage() {
  const text = pageText()
  const classes = useStyles()
  const [disabled, setDisabled] = useState(false)
  const dispatch = useDispatch()
  const { profile } = useSelector(Customer)
  const customer = profile
  const signed = useSelector(signState) // 登录状态


  const handleChange = () => {
    setDisabled(!disabled)
  }

  const handleClick = () => {
    if(signed) {
      dispatch(openProfileDialog())
    } else {
      dispatch(openAuthDialog())
    }
  }

  const handleSave = () => {
    RSGet('vip/order', data => {
      dispatch(setPaymentDialogOrderID(data))
      dispatch(openPaymentDialog())
    })
  }

  return (
    <Container maxWidth="sm">
      <PageTitle title={text.pageTitle} />
      <div align="center" className={classes.mb2}>
        <MainTitle title={<>{text.pageTitle}~~</>} />
        <Typography className={classes.mb2}>{text.slogan}</Typography>
        <img alt="" src="/images/VIP.png" /><br/>
      </div>

      <Grid container className={classes.mb2}>
        <Grid item xs={5} align="right">
          <Avatar src={customer.picture} className={classes.authIcon} />
        </Grid>
        <Grid item xs={2} align="center">
          <DoubleArrowIcon color="secondary" />
        </Grid>
        <Grid item xs={5} align="left">
          <Badge badgeContent={<Avatar className={classes.authBadge}>V</Avatar>}>
            <Avatar src={customer.picture} className={classes.authIcon} />
          </Badge>
        </Grid>
      </Grid>

      { customer.vip > 0 && 
        <div align="center">
        <Typography gutterBottom variant="h6" component="h2" align="center">
          {text.member_text_1}<br/>
        </Typography>

        <Typography gutterBottom variant="body1" align="center">
          {text.member_text_2}{moment(customer.vip_expiry_date).format('L')}
        </Typography>

        <Divider className={`${classes.mb2} ${classes.mt2}`} />
        </div>
      }

      { !customer.vip && 
        <div align="center">
          <Button variant="contained" color="secondary" disabled={disabled} onClick={handleClick}>{text.buy_btn}</Button>
          <br/>
          <FormControlLabel
            control={
              <Checkbox 
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                checked={!disabled} onChange={handleChange}
              />}
            label={<small>{text.agree_1}<Link to="/info/terms-and-conditions">{text.agree_2}</Link></small>}
          />

          <Divider className={`${classes.mb2} ${classes.mt2}`} />
        </div>
      }
      

      <Card className={classes.mb2}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <Typography gutterBottom variant="h6" component="h2">
                {text.rights}
              </Typography>

              <Typography variant="body2" component="p">
                {text.rights_1}<br/>
                {text.rights_2}<br/>
                {text.rights_3}<br/>
              </Typography>
            </Grid>
            <Grid item xs={12} lg={4}>
              <img alt="" src="/images/mug.jpg" width="100%" />
            </Grid>
          </Grid>

        </CardContent>
      </Card>

      <Card className={classes.mb2}>
        <CardContent>
          
          <Typography gutterBottom variant="h6" component="h2">
            {text.tips}
          </Typography>

          <Typography variant="body2" component="p">
            {text.tips_1}<br/>
            {text.tips_2}<br/>
            {text.tips_3}<br/>
          </Typography>

        </CardContent>
      </Card>

      <Card className={classes.mb2}>
        <CardContent>
          
          <Typography gutterBottom variant="h6" component="h2">
            {text.must_know}
          </Typography>

          <Typography variant="body2" component="p">
            {text.must_know_1}<br/>
            {text.must_know_2}<br/>
          </Typography>

        </CardContent>
      </Card>

      <CustomerProfileDialog onSave={handleSave} />

    </Container>
  )
}

function pageText() {
  const lang = getLanguage()
  if(lang === 'cn') return cn
  else return en
}

const cn = {
  pageTitle: '红松鼠会员',
  slogan: '立即加入红松鼠会员, 再省一笔巨划算！',

  rights: '会员权益',
  rights_1: '1. 会员下单时可享有 5% OFF VIP优惠',
  rights_2: '2. VIP优惠可以与其它优惠码同时使用',
  rights_3: '3. 加入会员首单获赠限量马克杯一只',

  tips: '购买建议',
  tips_1: '1. 如果您未来会在英国居住1年或更长时间',
  tips_2: '2. 如果您每月在红松鼠消费不低于80镑',
  tips_3: '以上情况加入会员可省更多~',

  must_know: '购买须知',
  must_know_1: '1. 会员资格不可以转让',
  must_know_2: '2. 会员资格生效后不可中途取消',

  agree_1: '同意并已阅读',
  agree_2: '服务条款',
  buy_btn: '加入会员 £48/年',

  payment_method: '支付方式',
  member_text_1: '恭喜! 您已是红松鼠会员',
  member_text_2: '会员有效期至: '
}

const en = {
  pageTitle: 'Red Squirrel Shop VIP Membership Scheme',
  slogan: 'Join our VIP Membership and save more!',

  rights: 'Member Rights',
  rights_1: '1. A valid Membership entitles you 5% OFF VIP discount on your orders',
  rights_2: '2. VIP discount can be used in conjunction with discount codes',
  rights_3: '3. VIP member will receive a Red Squirrel Mug on the first order',

  tips: 'Tips before joining VIP membership',
  tips_1: '1. If you are staying in the UK for future 12 months',
  tips_2: '2. If you are spending over £80 each month with us',
  tips_3: 'You will save more by joining Red Squirrel Shop VIP Scheme',

  must_know: 'VIP Membership Terms',
  must_know_1: '1. Your VIP Membership is not transferrable',
  must_know_2: '2. Your VIP Membership cannot be cancelled until it expires',

  agree_1: 'Read and agreed',
  agree_2: 'Terms and Conditions',
  buy_btn: 'Join VIP Scheme £48/year',

  payment_method: 'Payment types',
  member_text_1: 'Congratulations! Your VIP membership is active now.',
  member_text_2: 'Your VIP membership expires on: '
}