import { RSLog, RSPost, RSText } from "utils/RSUtils"
import { Card, CardHeader, CardActions, List, ListItem, ListItemText, ListItemAvatar, Typography, Divider } from '@material-ui/core'
import { BackStepBtn } from 'dialogs/CheckoutDialog'
import { NextStepBtn } from 'dialogs/CheckoutDialog'
import VoucherListItem from 'dialogs/checkout/step2/VoucherListItem'
import NoteListItem from 'dialogs/checkout/step2/NoteListItem'
import Img from 'contents/common/Img'
import Notice from 'dialogs/checkout/step2/Notice'
import DeliveryTime from 'dialogs/checkout/step2/DeliveryTime'
import useStyles from "utils/RSStyles"
import dateFormat from "dateformat"
import ReactGA from 'react-ga'
import { useEffect } from "react"

import { useSelector } from 'react-redux'
import { CheckoutDialogContent } from 'redux/CheckoutDialogSlice'

// Step 2 确认订单
export default function StepTwo() {
  const text = RSText()
  const { order, address } = useSelector(CheckoutDialogContent)

  const nextStep = () => {
    if(order.note) {
      RSPost('checkout/addNote/'+order.order_id, {note: order.note}, data => {
      })
    }
  }

  const now = () => {
    const now = new Date()
    const datetime = dateFormat(now, "yyyy-mm-dd HH:MM:ss")
    RSLog(datetime)
    return datetime
  }

  useEffect(()=>{
    console.log(order);
    ReactGA.set({title: text.step2})
    ReactGA.modalview('/checkout/step-2')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card>
      <CardHeader 
        title={text.step2}
      />

      {
        order && 
        <List>
          <Notice />
          <DeliveryTime postcode={address.postcode} datetime={now()} />
          <OrderListItem primary={text.cartFee} amount={order.cart_amount} />
          <OrderListItem primary={text.shippingFee} secondary={order.shipping_tips} amount={order.shipping_fee} />
          <OrderListItem primary={text.packingFee} secondary={order.packing_tips} amount={order.packing_fee} />
          <OrderListItem primary={text.vipDiscount} amount={order.vip_discount} minus />
          {/* <GiftListItem gift={order.gift} /> */}
          <VoucherListItem />
          <OrderListItem primary={text.totalAmount} amount={order.total_amount} />
          <NoteListItem />
        </List>
      }
      

      <CardActions>
        <BackStepBtn />
        <NextStepBtn onClick={nextStep} />
      </CardActions>
      
    </Card>
  )
}

export function OrderListItem(props) {
  const {primary, secondary, amount, minus} = props

  return (
    <>
    { amount !== '0.00' &&
    <>
    <ListItem>
      <ListItemText primary={primary} secondary={<Typography color="secondary" variant="body2">{secondary}</Typography>} />
      <Typography color={minus && 'secondary'} variant="body2">{minus && '-'}£{amount}</Typography>
    </ListItem>
    <Divider />
    </>
    }
    </>
  )
}

function GiftListItem(props) {
  const classes = useStyles()
  const text = RSText()
  const {gift} = props

  return(
    <>
    {gift && 
    <>
    <ListItem>
      <ListItemAvatar className={classes.mr}>
        <Img src={gift.img} oss="50x50" />
      </ListItemAvatar>
      <ListItemText primary={gift.name} />
      <Typography color='secondary' variant="body2">{text.freeGift}</Typography>
    </ListItem>
    <Divider />
    </>
    }
    </>
  )
}