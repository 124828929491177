import { useState, useEffect } from 'react'
import { RSGet } from 'utils/RSUtils'

import { IconButton } from '@mui/material'
import { NotificationsNone, NotificationsActive } from '@mui/icons-material'

import { useDispatch, useSelector } from 'react-redux'
import { reminderStore, setDialog, setProduct, setReminders } from 'redux/ReminderSlice'
import { signState } from 'redux/SignStateSlice'
import { openAuthDialog } from "redux/AuthDialogSlice"

// 补货提醒按钮
export default function ReminderBtn(props) {

  const dispatch = useDispatch()
  const signed = useSelector(signState)
  const { product } = props
  const { reminders } = useSelector(reminderStore)
  const [highlight, setHighlight] = useState(false)


  useEffect(()=> {
    if(reminders) {
      for(var i=0; i < reminders.length; i++) {
        if(reminders[i].product_id === product.id) {
          setHighlight(true)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reminders])

  const handleToggle = () => {
    if(signed) {
      if(!highlight) {
        dispatch(setProduct(product))
        dispatch(setDialog(true))
      } else {
        setHighlight(!highlight)
        RSGet('ReplenishmentReminder/remove/'+product.id, data=>{
          dispatch(setReminders(data))
        })
      }
    } else {
      dispatch(openAuthDialog())
    }
  }

  return (
    <>
    <IconButton color="secondary" onClick={handleToggle} size="small">
      {highlight?<NotificationsActive color="warning" />:<NotificationsNone color="warning" />}
    </IconButton>
    </>
  )
   
}