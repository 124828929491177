import { RSGet, RSText } from "utils/RSUtils"
import { Button} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlipay } from '@fortawesome/free-brands-svg-icons'
import { isMobile } from 'react-device-detect'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { PaymentDialogContent } from "redux/PaymentDialogSlice";
import { openLoadingDialog } from 'redux/LoadingDialogSlice'

export default function AliPayBtn() {
  const text = RSText()
  const { orderID } = useSelector(PaymentDialogContent)
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(openLoadingDialog())
    if (isMobile) {
      // 手机浏览器 wap 支付
      RSGet('payment/alipay/wap/'+orderID, data => {
        window.location.href = data
      })
    } else {
      // 桌面浏览器 web 支付
      RSGet('payment/alipay/web/'+orderID, data => {
        window.location.href = data
      })
    }
  }

  return (
    <Button fullWidth onClick={handleClick}>
      <FontAwesomeIcon icon={faAlipay} size="2x" fixedWidth style={{color: '#1677ff', marginRight: 5}} />
      {text.alipay}
    </Button>
  )
}