import { createSlice } from '@reduxjs/toolkit'

// 登录状态
export const slice = createSlice({
  name: 'EmailForgetDialog',
  initialState: {
    show: false
  },
  reducers: {
    openEmailForgetDialog: (state) => {
      state.show = true
    },
    closeEmailForgetDialog: (state) => {
      state.show = false
    }
  },
})

export const { openEmailForgetDialog, closeEmailForgetDialog } = slice.actions
export const EmailForgetDialogContent = state => state.EmailForgetDialog
export default slice.reducer