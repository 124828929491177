
import { Link } from 'react-router-dom'
import { Container, Grid, Typography, Hidden } from '@material-ui/core'
import FAQSection from 'footer/components/FAQSection'
import HelpCenterSection from 'footer/components/HelpCenterSection'
import CorporationSection from 'footer/components/CorporationSection'
//import APPDownloadImgs from 'layout/footer/APPDownloadImgs'
import PaymentMethods from 'footer/components/PaymentMethods'
import SNSIcons from 'footer/components/SNSIcons'
import CopyRights from 'footer/components/CopyRights'
import useStyles from 'utils/RSStyles'

export default function Footer() {
  const classes = useStyles()

  return (
    <div className={classes.pageFooter} id="footer">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Hidden mdDown>
          <Grid item md={2}>
            <img alt="Red Squirrel Shop" src="/images/rs-show.png" width="135" />
          </Grid>

          <Grid item md={2}>
            <HelpCenterSection />
          </Grid>

          <Grid item md={2}>
            <FAQSection />
          </Grid>

          <Grid item md={2}>
            <CorporationSection />
          </Grid>

          <Grid item md={4}>
            <PaymentMethods />
            {/*<APPDownloadImgs />*/}
          </Grid>
          </Hidden>
          <Grid item xs={12}>
            <SNSIcons />
            <CopyRights />
          </Grid>

        </Grid>
      </Container>  
    </div>
  )
}

export function FooterSection(props) {
  const {text} = props
  return (
    <Typography variant="h6" gutterBottom>{text}</Typography>
  )
}

export function FooterLink(props) {
  const {uri, text} = props
  const url = `/${uri}`
  return (
    <Typography variant="body2" gutterBottom>
      <Link to={url}>{text}</Link>
    </Typography>
  )
}