import { Link } from "react-router-dom"
import { RSText } from 'utils/RSUtils'

import {makeStyles, Hidden, Button} from '@material-ui/core'
import {NewReleasesOutlined, LoyaltyOutlined, LiveHelpOutlined } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  mr1: {
    marginRight: theme.spacing(1),
  }
}))

// 新品推荐
export function NewBtn() {
  const classes = useStyles()
  const text = RSText()
  return (
    <Hidden mdDown>
      <Button 
        component={Link} 
        to='/new-arrivals'
        startIcon={<NewReleasesOutlined />}
        className={classes.mr1}
      >
        {text.new}
      </Button>
    </Hidden>
  )
}

// 特价专区
export function SalesBtn() {
  const classes = useStyles()
  const text = RSText()
  return (
    <Hidden mdDown>
      <Button 
        component={Link} 
        to='/on-sale'
        startIcon={<LoyaltyOutlined />}
        className={classes.mr1}
      >
        {text.sales}
      </Button>
    </Hidden>
  )
}

// 常见问题
export function FaqBtn() {
  const classes = useStyles()
  const text = RSText()
  return (
    <Hidden mdDown>
      <Button 
        component={Link} 
        to={'/info/faq'}
        startIcon={<LiveHelpOutlined />}
        className={classes.mr1}
      >
        {text.faq}
      </Button>
    </Hidden>
  )
}