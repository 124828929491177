import {AppBar, Toolbar} from '@material-ui/core'
import Logo from 'header/components/Logo'
import HomepageBtn from 'header/components/HomepageBtn'
import ShoppingBtn from 'header/components/ShoppingBtn'
import MenuBtn from 'drawers/MobileMenuDrawer'
import { SearchBtn } from 'drawers/SearchDrawer'
import LanguageBtn from 'header/components/LanguageBtn'
import CartBtn from 'header/components/CartBtn'
import AuthBtn from 'header/components/AuthBtn'
import { NewBtn, SalesBtn, FaqBtn } from 'header/components/MenuBtns'

import {makeStyles} from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
  fg1: {
    flexGrow: 1
  }
}))

export default function Header() {
  const classes = useStyles()
  return (
    <AppBar position="fixed" color="default">
      <Toolbar>
        <Logo />
        <MenuBtn />
        <HomepageBtn />
        <ShoppingBtn />
        <NewBtn />
        <SalesBtn  />
        <FaqBtn />
        <div className={`${classes.fg1}`}></div>
        <LanguageBtn />
        <CartBtn />
        <SearchBtn />
        <AuthBtn />
      </Toolbar>
    </AppBar>
  )
}