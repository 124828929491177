import { makeStyles, Typography, Card, CardContent, Link } from "@material-ui/core"
import BuyBtns from 'contents/product/components/BuyBtns'
import Price from 'contents/product/components/Price'
import {PromotionTag, FrozenTag, ChilledTag} from 'contents/product/components/Tags'
import FavoriteBtn from 'contents/product/components/FavoriteBtn'
import Img from 'contents/common/Img'
import { getLanguage } from 'utils/RSUtils'

import { useDispatch } from 'react-redux'
import { openProductDialog } from 'redux/ProductDialogSlice'
import { Stack } from "@mui/material"

const useStyles = makeStyles((theme) => ({
  promotionTag: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    zIndex: 100
  },
  content:{
    textAlign: 'center'
  },
  name: {
    textDecoration: 'none',
    color: 'inherit',
  },
  favoriteBtn: {
    float: 'right',
    position: 'relative',
    right:0,
    zIndex: 100,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  }
}))

export default function GridItem(props) {
  const {product} = props
  const classes = useStyles()
  const lang = getLanguage()
  const dispatch = useDispatch()

  const openDialog = (productID) => {
    dispatch(openProductDialog(productID))
  }

  return (
      <Card>

        <div className={classes.favoriteBtn} >
          <FavoriteBtn product={product} />
        </div>

        <div className={classes.promotionTag}>
          <Stack direction="row" spacing={1}>
            <PromotionTag product={product} />
            <FrozenTag product={product} /> 
            <ChilledTag product={product} />
          </Stack>
        </div>

        <Img src={product.img} oss="263x263" onClick={()=>openDialog(product.id)} />
      
        
        <CardContent className={classes.content}>
          <Link component="button" className={classes.name} onClick={()=>openDialog(product.id)}>
            <Typography gutterBottom>{product.name}</Typography>
          </Link>

          <Typography align='center' gutterBottom>
            <Price product={product} />
          </Typography>

          {
            product.note && lang === 'cn' &&
            <Typography align='center' variant="body2" color="secondary" gutterBottom>
              {product.note}
            </Typography>
          }
          
          
          <Typography align='center' gutterBottom>
            <BuyBtns product={product} />
          </Typography>
        
        </CardContent>
      </Card>
  )
}