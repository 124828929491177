import { Button} from "@material-ui/core"
import { useDispatch } from "react-redux"
import { openChatDialog } from "redux/ChatDialogSlice"
import { RSText } from "utils/RSUtils"


export default function CustomerServiceBtn(props) {
  const text = RSText()
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(openChatDialog())
  }
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={handleClick}
      {...props}
    >
      {text.customerServiceBtn}
    </Button>
  )
}