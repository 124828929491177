import { makeStyles, Typography } from '@material-ui/core'
import { RSText } from 'utils/RSUtils'

const useStyles = makeStyles((theme) => ({
  paymentIcons: {
    '& > *': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  }
}))

export default function PaymentMethods() {
  const text = RSText()
  const classes = useStyles()
  return (
    <>
    <Typography variant="body2" gutterBottom>{text.paymentMethods}</Typography>
    <div className={classes.paymentIcons}>
      <img alt="Wechat Pay" src="/images/wechatpay.png" />
      <img alt="Alipay" src="/images/alipay.png" />
      <img alt="VISA" src="/images/visa.png" />
      <img alt="MASTERCARD" src="/images/mastercard.png" />
      <img alt="American Express" src="/images/amx.png" />
    </div>
    </>
  )
}