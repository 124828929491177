import { createSlice } from '@reduxjs/toolkit'

// 购物车
// Array [{id,name,img,price,promotion_tag,qty,stock,vat,weight,frozen},...]
export const slice = createSlice({
  name: 'Cart',
  initialState: {
    cart: [],
  },
  reducers: {
    initCart: (state, action) => {
      state.cart = action.payload
    },
    countCart: (state) => {
      let items = 0
      const cart = state.cart
      if(cart.length) {
        cart.forEach((cartItem) => {
          items += cartItem.qty
        })
      }
      return items
    },
    addCart: (state, action) => {
      let newItem = true
      const cart = state.cart
      const product = action.payload
      cart.forEach((cartItem, index) => {
        if(cartItem.id === product.id) {
          cart[index].qty ++
          newItem = false
        }
      })
      if(newItem) {
        cart.push({...product, qty: 1})
      }
      state.cart = cart
    },
    removeCart(state, action) {
      const cart = state.cart
      const product = action.payload.product
      cart.forEach((cartItem, index) => {
        if(cartItem.id === product.id) {
          cart[index].qty --
          if(cart[index].qty === 0) {
            cart.splice(index, 1)
          }
        }
      })
      state.cart = cart
    },
    removeOne(state, action) {
      const cart = state.cart
      const product = action.payload
      cart.forEach((cartItem, index) => {
        if(cartItem.id === product.id) {
          cart.splice(index, 1)
        }
      })
      state.cart = cart
    }
  },
})

export const { addCart, removeCart, removeOne, initCart } = slice.actions
export const cartContent = state => state.Cart.cart

export const cartAmount = state => {
  let amount = 0
  const cart = state.Cart.cart
  if(cart.length) {
    cart.forEach((cartItem, index) => {
      amount = amount + (Number(cartItem.qty) * Number(cartItem.price))
    })
  }
  return amount.toFixed(2)
}

export const cartCount = state => {
  let items = 0
  const cart = state.Cart.cart
  if(cart.length) {
    cart.forEach((cartItem) => {
      items += cartItem.qty
    })
  }
  return items
}

export default slice.reducer