import { useEffect, useState } from "react";
import {Link} from 'react-router-dom'

import { Grid, CircularProgress, Button } from "@material-ui/core"
import { Alert } from '@material-ui/lab'
import { Post, RSLog, RSText, useQuery } from "utils/RSUtils"
import RSEmoji from "contents/common/RSEmoji"
import AuthBtn from 'contents/auth/AuthBtn'
import useStyles from "utils/RSStyles"

import { useDispatch } from 'react-redux'
import { signIn } from 'redux/SignStateSlice'

export default function AlipayRedirectPage() {
  const { auth_code } = useQuery()
  const classes = useStyles()
  const [error, setError] = useState(null)
  const text = RSText()
  const dispatch = useDispatch()
  
  useEffect(() => {
    if(auth_code) {
      RSLog("支付宝登录开始")
      Post('auth/alipay_auth/'+auth_code, {}, data => {
        RSLog(data);
        if(data) {
          dispatch(signIn())
          setError(false)
        } else {
          setError(true)
        }
      
      })
    } else {
      setError(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <Grid container justify='center'>
      <Grid>
        <div align="center">
          {error === null && 
            <>
            <CircularProgress color="secondary" className={classes.mb2} />
            <Alert severity="info" className={classes.mb2}>Loading...</Alert>
            </>
          }

          {/* Login failed */}
          {error !== null && error &&
            <>
            <RSEmoji className={classes.mb2} name="sorry" />
            <Alert severity="error" className={classes.mb2}>{text.loginFailed}</Alert>
            <AuthBtn />
            </>
          }

          {/* Login success */}
          {error !== null && !error &&
            <>
            <RSEmoji className={classes.mb2} name="happy" />
            <Alert severity="success" className={classes.mb2}>{text.loginSuccess}</Alert>
            <Button variant="contained" color="secondary" component={Link} to="/">{text.backToHomeBtn}</Button>
            </>
          }
          
        </div>
      </Grid>
    </Grid>
  )
}

