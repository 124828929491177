import { createSlice } from '@reduxjs/toolkit'

// 登录状态
export const slice = createSlice({
  name: 'chatDialog',
  initialState: {
    value: {
      show: false
    },
  },
  reducers: {
    openChatDialog: (state) => {
      state.value.show = true
    },
    closeChatDialog: (state) => {
      state.value.show = false
    }
  },
})

export const { openChatDialog, closeChatDialog } = slice.actions
export const chatDialogContent = state => state.chatDialog.value
export default slice.reducer