import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'Reminder',
  initialState: {
    dialog: false,
    product: null,
    reminders: [],
  },
  reducers: {
    setDialog: (state, action) => {
      state.dialog = action.payload
    },
    setProduct: (state, action) => {
      state.product = action.payload
    },
    setReminders: (state, action) => {
      state.reminders = action.payload
    },
  },
})

export const { setDialog, setProduct, setReminders } = slice.actions
export const reminderStore = state => state.Reminder
export default slice.reducer