import { useEffect, useState } from "react";
import {Link} from 'react-router-dom'
import { Grid, CircularProgress, Button } from "@material-ui/core";
import { Alert } from '@material-ui/lab'
import { Post, RSLog, RSText, useQuery } from "utils/RSUtils";
import RSEmoji from "contents/common/RSEmoji";
import AuthBtn from 'contents/auth/AuthBtn'
import useStyles from "utils/RSStyles"
import ReactGA from 'react-ga'

import { useDispatch } from 'react-redux'
import { signIn } from 'redux/SignStateSlice'

export default function WechatRedirectPage() {
  const { code, state } = useQuery()
  const classes = useStyles()
  const [error, setError] = useState(null)
  const text = RSText()

  const dispatch = useDispatch()

  useEffect(() => {
    if(code && state) {
      RSLog("微信登录开始")
      Post('auth/wechat_auth', {code:code, state:state}, data => {
        if(data) {
          dispatch(signIn())
          setError(false)
        } else {
          setError(true)
        }
       

      })
    } else {
      ReactGA.event({
        category: 'Error',
        action: 'Wechat sign in fail'
      })
      setError(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container justify='center'>
      <Grid>
        <div align="center">
          {/* Loading */}
          {error === null && 
            <>
            <CircularProgress color="secondary" className={classes.mb2} />
            <Alert severity="info" className={classes.mb2}>Loading...</Alert>
            </>
          }

          {/* Login failed */}
          {error !== null && error &&
            <>
            <RSEmoji className={classes.mb2} name="sorry" />
            <Alert severity="error" className={classes.mb2}>{text.loginFailed}</Alert>
            <AuthBtn />
            </>
          }

          {/* Login success */}
          {error !== null && !error &&
            <>
            <RSEmoji className={classes.mb2} name="happy" />
            <Alert severity="success" className={classes.mb2}>{text.loginSuccess}</Alert>
            <Button variant="contained" color="secondary" component={Link} to="/">{text.backToHomeBtn}</Button>
            </>
          }
        </div>
      </Grid>
    </Grid>
  )
}

