import { Link } from "react-router-dom"
import { Typography, Hidden } from '@material-ui/core'
import { RSText } from 'utils/RSUtils'

import {makeStyles} from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
  logo: {
    width: 65,
    marginBottom: -4,
    marginRight: theme.spacing(2),
  },
  name: {
    textDecoration: 'none',
    marginRight: theme.spacing(4),
  },
}))

export default function Logo() {
  const classes = useStyles()
  const text = RSText()

  return (
    <>
    <Link to="/">
      <img alt="logo" src="/images/logo.png" className={classes.logo} />
    </Link>

    <Hidden mdDown>
      <Link to={"/"} className={classes.name}>
        <Typography variant="h6" >{text.brand}</Typography>
      </Link>
    </Hidden>
    </>
  )
}