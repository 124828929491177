import { loadStripe } from "@stripe/stripe-js"
import { RSGet, RSText } from "utils/RSUtils"
import { ListItem, ListItemIcon, ListItemText, Divider} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlipay } from '@fortawesome/free-brands-svg-icons'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { openLoadingDialog } from 'redux/LoadingDialogSlice'
import { CheckoutDialogContent } from 'redux/CheckoutDialogSlice'




export default function StripeAliPay() {
  const text = RSText()
  const dispatch = useDispatch()
  const { order } = useSelector(CheckoutDialogContent)

  const handleClick = async () => {
    dispatch(openLoadingDialog())
    const stripe = await loadStripe("pk_live_4Aczi8UXkt564FZ90EYntjtW");
    const api = 'payment/stripeAlipay/'+order.order_id
    RSGet(api, data => {
      if(data) {
        stripe.confirmAlipayPayment(data, {
          // Return URL where the customer should be redirected to after payment
          return_url: 'https://redsquirrel.shop/payment/callback/'+order.order_id,
        }).then((result) => {
          if (result.error) {
            // Inform the customer that there was an error.
            //var errorElement = document.getElementById('error-message');
            //errorElement.textContent = result.error.message;
            console.log(result.error.message)
          }
        });
      }
    })
  }

  return (
    <>
    <ListItem button onClick={handleClick}>
      <ListItemIcon>
        <FontAwesomeIcon icon={faAlipay} size="2x" fixedWidth  style={{color: '#1677ff'}} />
      </ListItemIcon>
      <ListItemText primary={text.alipay} />
      <KeyboardArrowRightIcon />
    </ListItem>
    <Divider />
    </>
  )
}