import Alert from '@material-ui/lab/Alert';
import { useEffect, useState } from 'react';
import { RSGet } from 'utils/RSUtils';

export default function DeliverTime(props) {
  const {postcode, datetime} = props
  const [notice, setNotice] = useState()
  useEffect(()=>{
    RSGet('checkout/deliveryTime/'+encodeURIComponent(postcode)+'/'+encodeURIComponent(datetime), (data) => {
      if(data) setNotice(data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
    { notice && 
      <Alert severity="warning">{notice}</Alert>
    }
    </>
  )
}