import { useState, useEffect } from 'react'
import { RSGet, RSText } from 'utils/RSUtils'
import Img from 'contents/common/Img'
import { makeStyles, Card, ListItem, ListItemText, Chip, Avatar, ListItemAvatar, Hidden } from '@material-ui/core'
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import { useDispatch } from 'react-redux';
import { openProductDialog } from 'redux/ProductDialogSlice';

const useStyles = makeStyles((theme) => ({
  promotionTag: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    zIndex: 100
  },
  promotionTagMobile: {
    position: 'absolute',
    marginTop: 5,
    marginLeft: 5,
    zIndex: 100
  },
  avatar:{
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginRight: theme.spacing(2)
  },
}))

export default function Gift() {
  const [gift, setGift] = useState()
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(()=>{
    RSGet('homepage/gift', data => {
      setGift(data)
    })  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openDialog = (productID) => {
    dispatch(openProductDialog(productID))
  }

  return (
    <>
    { gift &&
    <>
    <Hidden mdUp>
      <div className={classes.promotionTagMobile}>
        <GiftTag />
      </div>
      <Card>
        <ListItem button onClick={()=>openDialog(gift.id)}>
          <Avatar src={gift.img} variant="square" className={classes.avatar} />
          <GiftRuleText gift={gift} />
          
        </ListItem>
      </Card>
    </Hidden>

    <Hidden smDown>
      <Card>
        <div className={classes.promotionTag}>
          <GiftTag />
        </div>
        <Img src={gift.img} oss="555x555" onClick={()=>openDialog(gift.id)} aspectRatio={555/555}  />
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <CardGiftcardIcon />
            </Avatar>
          </ListItemAvatar>
          <GiftRuleText gift={gift} />
        </ListItem>
      </Card> 
    </Hidden>
    </>
    }
    </>  
  )
}

function GiftTag() {
  const text = RSText()
  return (
    <Chip
      size="small"
      label={text.free}
      color="secondary"
    />
  )
}

function GiftRuleText(props) {
  const text = RSText()
  const {gift} = props
  return (
    <ListItemText 
      primary={gift.name} 
      secondary={text.freeRule1 + gift.minSpend + text.freeRule2} 
    />
  )
}
