import { DialogContent, Dialog, Divider } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux';
import { closePaymentDialog, PaymentDialogContent } from 'redux/PaymentDialogSlice';
import { getLanguage } from 'utils/RSUtils';

import { inWechat, inAlipay } from "utils/RSBrowser"
import StripeBtn from './StripeBtn'
import WechatBtn from './WechatBtn'
import AlipayBtn from './AlipayBtn'
import useStyles from 'utils/RSStyles';
import DialogTitleBar from 'contents/common/DialogTitleBar'
import StripeAliPay from 'components/payments/StripeAlipay';

export default function PaymentDialog() {
  const { orderID, open } = useSelector(PaymentDialogContent)
  const dispatch = useDispatch()
  const text = pageText()
  const classes = useStyles()

  const handleClose = () => {
    dispatch(closePaymentDialog())
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitleBar title={text.choose_payment_method} setClose={handleClose} />
      { orderID  && 
      <DialogContent dividers>
        <StripeBtn />
        <Divider className={`${classes.mb} ${classes.mt}`} />
        { !inAlipay() && <WechatBtn /> }
        <Divider className={`${classes.mb} ${classes.mt}`} />
        { !inWechat() && <AlipayBtn /> }
        <StripeAliPay />
      </DialogContent>
      }
    </Dialog>
  );
}

function pageText() {
  const lang = getLanguage()
  if(lang === 'cn') return cn
  else return en
}

const cn = {
  choose_payment_method: '选择支付方式'
}

const en = {
  choose_payment_method: 'Choose payment method'
}