import { useEffect, useState } from "react"
import { Dialog, Avatar, IconButton, DialogContent, CircularProgress } from "@material-ui/core"
import { Get, RSLog } from "utils/RSUtils"
import useStyles from "utils/RSStyles"
import { isMobile } from "react-device-detect"
import DialogTitleBar from "contents/common/DialogTitleBar"

import { useDispatch } from 'react-redux'
import { openAlertDialog } from "redux/AlertDialogSlice"

// 微信登录按钮
export default function WechatLoginBtn() {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleClick = () => {
    if(isMobile) {
      RSLog('移动端 无法登录')
      dispatch(openAlertDialog({
        msg: '移动端浏览器无法使用微信登录，请关注我们的微信公众号 红松鼠超市',
        emoji: 'sorry', 
        severity: 'warning'
      }))
    } else {
      RSLog('桌面端 二维码登录')
      setOpen(true) // 二维码
    }
  }

  return (
    <>
    <IconButton size="small" className={classes.mtb} onClick={handleClick}>
      <Avatar alt="Wechat" src="/images/auth/wechat.png" className={classes.authIcon} />
    </IconButton>

    <QRcodeDialog open={open} setOpen={setOpen} />
    </>
  )
}

function QRcodeDialog(props) {
  const {open, setOpen} = props
  const [url, setUrl] = useState()
  const classes = useStyles()

  useEffect(()=>{
    if(open) {
      RSLog("读取微信二维码")
      Get('auth/wechat_qrcode', data => {
        RSLog(data)
        setUrl(data)
      })
    }
  }, [open])

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitleBar setClose={handleClose} />
      <DialogContent>
      { !url && <CircularProgress color="secondary" />}  
      { url && 
        <iframe className={classes.qrFrame}
        title="Wechat QRCode"
        frameBorder="0"
        sandbox="allow-scripts allow-same-origin allow-top-navigation"
        scrolling="no"
        src={url} />
      }
      </DialogContent>
    </Dialog>
  )
}