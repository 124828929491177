import { useState } from 'react'
import { Container, Grid, TextField, Alert, Button } from '@mui/material'
import { RSGet } from 'utils/RSUtils'
import PageTitle from 'contents/common/PageTitle'

export default function DeliveryTime() {

  const [postcode, setPostcode] = useState(null)
  const [datetime, setDatetime] = useState(null)
  const [notice, setNotice] = useState(null)

  const handleChangePostcode = (e) => {
    setPostcode(e.target.value)
  }

  const handleChangeTime = (e) => {
    setDatetime(e.target.value)
  }

  const getNotice = () => {
    RSGet('checkout/deliveryTime/'+encodeURIComponent(postcode)+'/'+encodeURIComponent(datetime), (data) => {
      if(data) setNotice(data)
    })
  }

  return (
    <Container maxWidth="xs">
      <PageTitle title="配送时间测试" />
      <Grid container spacing={3}>
        <Grid item md={12}>
          <h2>配送时间测试</h2>
        </Grid>
        <Grid item md={12}>
          <TextField label="Postcode" variant="outlined" placeholder='DA1 4BZ' onChange={handleChangePostcode} />
        </Grid>
        <Grid item md={12}>
          <TextField label="Datetime" variant="outlined" placeholder='2022-08-17 12:00:00' onChange={handleChangeTime} />
        </Grid>
        <Grid item md={12}>
          <Button type="button" onClick={getNotice} variant="outlined">Submit</Button>
        </Grid>
        <Grid item md={12}>
          { notice && 
            <Alert severity="warning">{notice}</Alert>
          }
        </Grid>
      </Grid>
    </Container> 
  )
}