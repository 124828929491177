import { useState } from "react"
import { Dialog, Tab, Tabs, Divider, Grid, DialogContent, Typography, Paper } from "@material-ui/core"
import GmailLoginBtn from 'contents/auth/GmailLoginBtn'
import FacebookLoginBtn from 'contents/auth/FacebookLoginBtn'
import WechatLoginBtn from 'contents/auth/WechatLoginBtn'
import AlipayLoginBtn from 'contents/auth/AlipayLoginBtn'
import SignInForm from 'contents/auth/EmailSignInForm'
import SignUpForm from 'contents/auth/EmailSignUpForm'
import { RSText } from "utils/RSUtils"
import useStyles from "utils/RSStyles"

import { useSelector, useDispatch } from 'react-redux'
import { closeAuthDialog, authDialogContent} from 'redux/AuthDialogSlice'

export default function AuthDialog() {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const text = RSText()

  const authDialog = useSelector(authDialogContent)
  const dispatch = useDispatch()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }


  const handleClose = () => {
    dispatch(closeAuthDialog())
  }

  return (
    <Dialog 
      open={authDialog.show} 
      onClose={handleClose}
      maxWidth="xs"
    >
      <Paper>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="secondary"
        variant="fullWidth"
      >
        <Tab label={text.signInLabel} />
        <Tab label={text.signUpLabel} />
      </Tabs>
      </Paper>

      <DialogContent>
        {
          value === 0 && 
          <>
          <SignInForm />
          <Divider className={classes.mb} />
          <SignInBtns />
          </>
        }
        { value === 1 && <SignUpForm /> }
      </DialogContent>
    </Dialog>
  )
}

function SignInBtns() {
  const classes = useStyles()
  const text = RSText()

  return (
    <Grid container justify="center" >

      <Grid item xs={12}>
        <Typography variant="caption" component="p" align="center">{text.signInWithLabel}</Typography>
      </Grid>

      <Grid item className={classes.authIconBtn} xs={3}>
        <WechatLoginBtn />
      </Grid>

      <Grid item className={classes.authIconBtn} xs={3}>
        <GmailLoginBtn />
      </Grid>

      <Grid item className={classes.authIconBtn} xs={3}>
        <FacebookLoginBtn />
      </Grid>

      <Grid item className={classes.authIconBtn} xs={3}>
        <AlipayLoginBtn />
      </Grid>
      
    </Grid>
  )
}