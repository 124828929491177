import firebase from "firebase/app"
import { useEffect, useState, useRef } from "react"
import { RSLog, RSText, useQuery } from "utils/RSUtils"
import { Alert } from "@material-ui/lab"

import { Button, Container } from "@material-ui/core"
import { PasswordTextField, ConfirmPasswordTextField } from 'contents/common/FormInputs'
import { useHistory } from "react-router-dom"
import useStyles from "utils/RSStyles"
import { useDispatch } from "react-redux"
import { openAlertDialog } from "redux/AlertDialogSlice"

export default function EmailPasswordResetPage() {
  const classes = useStyles()
  const text = RSText()

  const query = useQuery()
  const code = query.oobCode
  const history = useHistory()
  
  const [error, setError]  = useState()
  const dispatch = useDispatch()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const passwordRef = useRef()
  const confirmPasswordRef = useRef()

  useEffect(()=>{
    RSLog(code)
    firebase.auth().checkActionCode(code)
      .then(function(resp) {
        //success();
        RSLog(resp)
      })
      .catch(function(error) {
        setError(error.message)
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePassword = (e) => {
    setPassword(e.target.value)
    setError(null)
  }

  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value)
    setError(null)
  }
  
  // 密码重置
  const handleReset = () => {
    if(!passwordRef.current.validation()) return false
    if(!confirmPasswordRef.current.validation()) return false
    if(!confirmPasswordRef.current.match(password)) return false

    firebase.auth().confirmPasswordReset(code, password)
      .then(function() { 
        dispatch(openAlertDialog({msg: text.passwordResetSuccess, emoji: 'happy'}))
        history.push('/')
      }).catch(function(error) {
        setError(error.message)
      })
  }

  return (
    <Container maxWidth="xs">
      <div className={classes.authForm}>
      
        {!error && 
        <>
        <div>
          <PasswordTextField 
            value={password} 
            onChange={handleChangePassword} 
            ref={passwordRef}
          />
        </div>

        <div>
          <ConfirmPasswordTextField
            value={confirmPassword} 
            onChange={handleChangeConfirmPassword} 
            ref={confirmPasswordRef}
          />
        </div>
        </>
        }

        {error && <div><Alert severity="error">{error}</Alert></div>}

        {!error && 
        <div>
          <Button 
          variant="contained" 
          color="secondary" 
          fullWidth 
          onClick={handleReset}
          >
            {text.resetPasswordBtn}
          </Button>
        </div>
        }
      </div>
    </Container>
  )
}