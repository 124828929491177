import { BackStepBtn } from 'dialogs/CheckoutDialog'
import { Card, CardHeader, CardActions, List } from '@material-ui/core'
import Stripe from 'dialogs/checkout/step3/Stripe'
import WechatPay from 'dialogs/checkout/step3/WechatPay'
import Alipay from 'dialogs/checkout/step3/Alipay'
import { RSText } from 'utils/RSUtils'
import { inWechat, inAlipay } from "utils/RSBrowser"
import useStyles from 'utils/RSStyles'
import CustomerServiceBtn from 'contents/common/CustomerServiceBtn'
import ReactGA from 'react-ga'
import { useEffect } from "react"
import { useSelector } from 'react-redux'
import { CheckoutDialogContent } from 'redux/CheckoutDialogSlice'
import StripeAliPay from 'components/payments/StripeAlipay'

// Step 3 确认订单
export default function StepThree() {
  const classes = useStyles()
  const text = RSText()
  const { order } = useSelector(CheckoutDialogContent)
  
  useEffect(()=>{
    ReactGA.set({title: text.step3})
    ReactGA.modalview('/checkout/step-3')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card>
      <CardHeader title={text.step3+' £'+order.total_amount} />

      <List className={classes.mb2}>
        <Stripe />
        { !inAlipay() && <WechatPay /> }
        { !inWechat() && <Alipay /> }
        { /* <StripeAliPay /> */}
      </List>

      <CardActions>
        <BackStepBtn />
        <CustomerServiceBtn className={classes.checkOutNextStepBtn} size="small" />
      </CardActions>
    </Card>
  )
}