import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { reminderStore, setDialog, setReminders } from 'redux/ReminderSlice'
import { Profile, setCustomerEmail } from 'redux/CustomerSlice'
import { RSText, RSGet, RSPost } from 'utils/RSUtils'
import { useEffect, useState } from 'react'
import { EmailInput, EmailValidation } from 'contents/common/FormInputs'

export default function ReminderDialog() {
  const text = RSText()
  const { dialog, product } = useSelector(reminderStore)
  const customer = useSelector(Profile)
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')

  useEffect(()=>{
    if(dialog) setEmail(customer.email)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog, customer.email])


  const handleClose = () => {
    dispatch(setDialog(false))
  }

  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSave = () => {
    dispatch(setDialog(false))
    RSGet('ReplenishmentReminder/add/'+product.id, data=>{
      dispatch(setReminders(data))
    })

    if(customer.email !== email) {
      RSPost('ReplenishmentReminder/updateEmail', {email: email}, data => {
        dispatch(setCustomerEmail(email))
      })
    }
  }

  return (
    <Dialog open={dialog} onClose={handleClose}>
      <DialogTitle>{text.reminderTitle} - {product && product.name}</DialogTitle>
      <DialogContent>
        <EmailInput 
          name="email"
          style={{marginTop:10}} 
          fullWidth 
          label="Reminder email send to" 
          value={email}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{text.close}</Button>
        <Button 
          onClick={handleSave} 
          variant="contained"
          disabled={email === '' || EmailValidation(email) !== ''}
        >
          {text.reminderSave}
        </Button>
      </DialogActions>
    </Dialog>
  )
}