import { createSlice } from '@reduxjs/toolkit'

// 登录状态
export const slice = createSlice({
  name: 'LoadingDialog',
  initialState: {
    show: false
  },
  reducers: {
    openLoadingDialog: (state) => {
      state.show = true
    },
    closeLoadingDialog: (state) => {
      state.show = false
    }
  },
})

export const { openLoadingDialog, closeLoadingDialog } = slice.actions
export const LoadingDialogShow = state => state.LoadingDialog.show
export default slice.reducer