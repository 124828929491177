import { useEffect, useState } from "react"
import { Dialog, DialogContent, DialogActions, Grid, Button, makeStyles, Select, Typography } from "@material-ui/core";
import { RSGet, RSLog, RSPost, RSText } from "utils/RSUtils"
import DialogTitleBar from 'contents/common/DialogTitleBar'
import {TextInput, EmailInput, UKMobileInput, PostcodeInput, PostcodeValidation, MobileValidation, EmailValidation } from 'contents/common/FormInputs'

import { useSelector, useDispatch } from 'react-redux'
import { 
  CheckoutDialogContent, 
  setAddressFormData,
  hideAddressForm,
  getAddresses, 
} from 'redux/CheckoutDialogSlice'

const useStyles = makeStyles((theme) => ({
  searchBtn: { marginTop: 16},
  saveBtn: {marginLeft:'auto !important'},
}))

export default function AddressFormDialog(props) {

  const [addresses, setAddresses] = useState([]) // 搜索地址结果
  const [searched, setSearched] = useState('')
  const [saveBtnDisable, setSaveBtnDisable] = useState(false)

  const text = RSText()
  const classes = useStyles()

  const dispatch = useDispatch()
  const { addressFormData, addressFormShow } = useSelector(CheckoutDialogContent)
  const formData = addressFormData
  const show = addressFormShow

  const handleClose = () => {
    dispatch(hideAddressForm())
  }

  useEffect(()=>{
    if(show) {
      setAddresses([])
    }
  },[show])

  // 表单验证
  useEffect(()=>{
    if(formData) {
      if(formData.surname === '' || formData.forename === '' || formData.email === '' || formData.tel === '' || formData.postcode === '' || formData.address_1 === '' || formData.city === '') {
        setSaveBtnDisable(true)
        RSLog('缺少必填项')
      } else {
        if(PostcodeValidation(formData.postcode) !== '' || MobileValidation(formData.tel) !== '' || EmailValidation(formData.email) !== '') {
          setSaveBtnDisable(true)
          RSLog('格式有问题')
        } else {
          setSaveBtnDisable(false)
        }
      }
    }
  },[formData])

  const handleInput = (e) => {
    dispatch(setAddressFormData({
      ...formData,
      [`${e.target.name}`]: e.target.value
    }))
  }

  const handlePostcode = (e) => {
    dispatch(setAddressFormData({
      ...formData,
      postcode: e.target.value.trim()
    }))
    setSearched('')
  }

  const searchAddress = () => {
    if(formData.postcode === '') return false
    RSGet('address/search/'+formData.postcode, data => {
      if(data.length) {
        setSearched('')
      }else{  
        setSearched('未找到地址, 请手动填写')
      }
      setAddresses(data)
    })
  }

  const handleSave = () => {
    setSaveBtnDisable(true)
    RSPost('address/update', {data: formData}, data => {
      RSLog('保存地址')
      dispatch(getAddresses())
      dispatch(hideAddressForm())
      setSaveBtnDisable(false)
    })
  }

  // 删除信息
  const handleDel = () => {
    if(window.confirm(text.delConfirm)) {
      if(formData.id) {
        dispatch(hideAddressForm())
        RSLog('删除地址')
        RSPost('address/delete', {data: formData}, data=>{
          dispatch(getAddresses())
        })
      }
    }
  }

  return (
    <>
    <Dialog open={show} onClose={handleClose} maxWidth="xs">
      { formData && 
      <>
        <DialogTitleBar title={formData.id?text.addressFormTitleUpdate:text.addressFormTitleAdd} setClose={handleClose} />
        <DialogContent dividers={true}>
          <Grid container spacing={3}>

            <Grid item xs={6}>
              <TextInput
                label={text.addressSurname}
                name="surname" 
                value={formData.surname}
                onChange={handleInput}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <TextInput
                label={text.addressForename}
                name="forename" 
                value={formData.forename}
                onChange={handleInput}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <EmailInput 
                label="Email *"
                name="email" 
                value={formData.email}
                onChange={handleInput}
              />
            </Grid>

            <Grid item xs={12}>
              <UKMobileInput
                label={text.addressMobile}
                name="tel" 
                value={formData.tel}
                onChange={handleInput}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <PostcodeInput 
                label='Postcode'
                value={formData.postcode}
                onChange={handlePostcode}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <Button 
                variant="outlined" 
                className={classes.searchBtn}
                size="small"
                onClick={searchAddress}
              >
                {text.addressFind}
              </Button>
            </Grid>

            { addresses.length > 0 &&
            <Grid item xs={12}>
              <AddressesSelector addresses={addresses} />
            </Grid>
            }

            { searched && 
            <Grid item xs={12}>
              <Typography color="secondary" variant="body2">{searched}</Typography>
            </Grid>
            }

            <Grid item xs={12}>
              <TextInput
                label={"Address Line 1"}
                name="address_1" 
                value={formData.address_1}
                onChange={handleInput}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextInput
                label={"Address Line 2"}
                name="address_2" 
                value={formData.address_2}
                onChange={handleInput}
              />
            </Grid>

            <Grid item xs={12}>
              <TextInput
                label={"Address Line 3"}
                name="address_3" 
                value={formData.address_3}
                onChange={handleInput}
              />
            </Grid>

            <Grid item xs={6}>
              <TextInput
                label={"City/Town"}
                name="city" 
                value={formData.city}
                onChange={handleInput}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <TextInput
                label={"County"}
                name="county" 
                value={formData.county}
                onChange={handleInput}
              />
            </Grid>
            

            <Grid item xs={12}>
              <TextInput
                label={"Driver Instructions"}
                name="instructions" 
                value={formData.instructions}
                onChange={handleInput}
              />
            </Grid>

          </Grid>
        </DialogContent>

        <DialogActions>
          {formData.id && 
          <Button  color="default" variant="outlined" size="small" onClick={handleDel}>
            {text.delBtn}
          </Button>
          }
        
          <Button 
            color="secondary" 
            className={classes.saveBtn} 
            size="small" 
            variant="contained" 
            onClick={handleSave} 
            disabled={saveBtnDisable}
          >
            {text.saveBtn}
          </Button>
     
        </DialogActions>
      </>
      }
    </Dialog>
    </>
  )
}

function AddressesSelector(props) {
  const dispatch = useDispatch()
  const { addressFormData } = useSelector(CheckoutDialogContent)
  const formData = addressFormData

  const { addresses } = props
  const handle = (e) =>{
    var arr = e.target.value.split(', ')

    var address_3 = arr[2]
    address_3 += arr[3]===''?'':(', '+arr[3])
    address_3 += arr[4]===''?'':(', '+arr[4])

    dispatch(setAddressFormData({
      ...formData,
      address_1: arr[0],
      address_2: arr[1],
      address_3: address_3,
      city: arr[5],
      county: arr[6]
    }))
    
    RSLog('选择地址')
  }

  return (
    <Select
      native
      onChange={handle}
    >
      {addresses.map((address, index) => (
        <option key={index} value={address}>
          {address.replaceAll(' ,','')}
        </option>
      ))}
    </Select>
  )
}
