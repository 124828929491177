import { makeStyles, IconButton, DialogTitle } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  fullScreenBtn:{
    position: 'absolute',
    right: theme.spacing(6),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
}))

export default function DialogTitleBar(props) {
  const {setClose, title, productID} = props
  const classes = useStyles()
  const history = useHistory()

  const closeDialog = () => {
    setClose()
  }

  const openWindow = () => {
    closeDialog()
    history.push('/product/'+productID)
  }

  return (
    <DialogTitle>
      {title}

      {productID && 
      <IconButton className={classes.fullScreenBtn} onClick={openWindow}>
        <OpenInNewIcon />
      </IconButton>
      }
 
      <IconButton aria-label="close" className={classes.closeButton} onClick={closeDialog}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  )
}